import { formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { NgxSpinnerService } from 'ngx-spinner';
import * as differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import { AreaRecetaService } from 'src/app/shared/services/area-receta.service';
import { InsumoService } from 'src/app/shared/services/insumo.service';
import { ProveedorService } from 'src/app/shared/services/proveedor.service';
import { UnidadMedidaService } from 'src/app/shared/services/unidad-medida.service';
import { AlmacenService } from 'src/app/shared/services/almacen.service';
import { ComprasService } from 'src/app/shared/services/compras.service';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service';
import { Util } from 'src/app/shared/util/util';
import { ProveedorComponent } from '../proveedor/proveedor.component';
import { InsumoComponent } from '../insumo/insumo.component';
import { AgregarMedidaComponent } from '../agregar-medida/agregar-medida.component';
import { FormDestinoComponent } from '../destino/destino.component';

@Component({
  selector: 'app-modal-compra',
  templateUrl: './modal-compra.component.html',
  styleUrls: ['./modal-compra.component.css']
})
export class ModalCompraComponent implements OnInit {
  compraForm: FormGroup;
  insumolist: any[];
  proveedoreslist: any[];
  almaceneslist: any[];
  unidadeslist: any[];
  auxUnidadOpc: any;
  insumoMovi: any;
  date = null; // new Date();
  error = false;
  isNew: boolean = true; // True si es un nuevo registro, false si se está editando
  mensaje = "";
  @Input() objetoEditar: any = null;  // Aquí se guarda el objeto a editar (cuando corresponde)
  @Input() tipo: any = null;  // Aquí se guarda el objeto a editar (cuando corresponde)
  list: any;
  listCompras = [];
  listComprasAux = [];
  listUndMed = [];
  moneda: String = "";
  banView = true;
  success=false;

  proveedorInsumos:any;
  auxProveedorInsumos=[];
  auxInsumosAsociados=[];

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private modal: NzModalRef,
    private spinner: NgxSpinnerService,
    private insumos: InsumoService,
    private proveedores: ProveedorService,
    private unidades: UnidadMedidaService,
    private almancenes: AlmacenService,
    private compras: ComprasService,
    private restauranteService:RestaurtantService,
    private util: Util,
  ) {

  }

  ngOnInit() {

    if (this.objetoEditar) {
      this.insumos.insumosWhitProveedor(this.objetoEditar.proveedor_id).subscribe(res => {
        this.proveedorInsumos = res;
        console.log(this.proveedorInsumos, "llegaron los insumos del proveedor que se edita")
      })
      this.banView = false;
      this.compras.indexWithUnit().subscribe(res => {
        this.insumolist = res
        this.compraForm = this.fb.group({
          id: [this.objetoEditar.id&&this.tipo=='ver'?this.objetoEditar.id:''],
          fecha: [this.objetoEditar.fecha?this.objetoEditar.fecha:new Date(), [Validators.required]],
          referencia: [this.objetoEditar.fecha_vencimiento_factura&&this.objetoEditar.referencia?this.objetoEditar.referencia:'', [Validators.required]],
          proveedor_id: [this.objetoEditar.proveedor_id, [Validators.required]],
          restaurante_id: [Number(localStorage.getItem('restaurante_seleccionado_id')), [Validators.required]],
          insumos_movidos: this.fb.array([]),
          monto: [ this.redondea(this.objetoEditar.monto)],
          fecha_vencimiento_factura: [this.objetoEditar.fecha_vencimiento_factura?this.objetoEditar.fecha_vencimiento_factura:'', [Validators.required]],
          transaccion_origen_id: [this.objetoEditar.transaccion_origen_id?this.objetoEditar.transaccion_origen_id:this.objetoEditar.id],
        });
        this.compras.getCompra(this.objetoEditar.id).subscribe(res => {
          console.log(res, "objeto con id");
          res.insumos_movidos.forEach(element => {
            this.agregarItem(element);
          });
          this.banView=true;
          this.actualizarTotalInsumoWithEquivalencia();
        });
      });




    }


    this.proveedores.getProveedor().subscribe(res => {
      console.log(res, "proveedores");
      this.proveedoreslist = res;
    });

    this.almancenes.index().subscribe(res => {
      console.log(res, "almacenes");
      this.almaceneslist = res;
    });

    // Obtener la moneda del restaurante
    this.restauranteService.getMoneda(localStorage.getItem('restaurante_seleccionado_id')).subscribe(res => {
      this.moneda = res.moneda;
    })
  }






  /**
   * Redondear un numero a 2 decimales
   * @param num numero a redondear
   * @retorn string numero redondeado
   */
  redondea(num){
    return ""+parseFloat(""+num).toFixed(2);
  }

  agregarItem(compra=null) {
    console.log(compra, "agregar item")

    let control = <FormArray>this.compraForm.controls['insumos_movidos'];

    control.push(this.crearInsumosMovidos(compra))

    console.log(this.compraForm.get("insumos_movidos").value);

    console.log(this.getInsumosMovidos().controls);
  }


  crearInsumosMovidos(compra): FormGroup {
    this.borrarCompraObj(compra);
    if (this.objetoEditar) {
      console.log(compra,"compra")
      console.log(this.objetoEditar,"lo que se va a editar")
      return this.fb.group({
        id: [compra==null?null:compra.id],
        cantidad: [compra==null?null:compra.cantidad, [Validators.required]],
        fecha_vencimiento: [compra==null?null:compra.fecha_vencimiento, [Validators.required]],
        precio_unitario: [compra==null?null:compra.precio_unitario, [Validators.required]],
        precio: [compra==null?null:compra.precio_unitario],
        insumo_id: [compra==null?null:compra.insumo_id, [Validators.required]],
        unidad_medida_id: [compra==null?null:compra.unidad_medida_id, [Validators.required]],
        unidades_medida: [compra==null?null:this.getUnidadesMedidasInsumo(compra.insumo_id)],
        almacen_destino_id: [compra==null?null:compra.almacen_destino_id, [Validators.required]],
        total: [null],
      });

    } else {
      return this.fb.group({
        // id: [compra.id],
        cantidad: [null, [Validators.required]],
        fecha_vencimiento: [null, [Validators.required]],
        precio_unitario: [null, [Validators.required]],
        precio: [null],
        insumo_id: [null, [Validators.required]],
        unidad_medida_id: [null, [Validators.required]],
        unidades_medida: [[]],
        almacen_destino_id: [null, [Validators.required]],
        total: [null],
      });

    }



  }


  getUnidadesMedidasInsumo(idInsumo){
    let insumo = this.insumolist.find(element => element.id == idInsumo);
    return insumo.unidades_medidas_opciones;
  }


  getInsumosMovidos(): FormArray {
    return this.compraForm.get("insumos_movidos") as FormArray;
  }

  borrarItem(i: number, compra) {
    console.log(compra)
    if (!this.objetoEditar) {
      this.listCompras.push(this.listComprasAux.find(element => element.id == compra.value.id));
    }
    (<FormArray>this.compraForm.controls['insumos_movidos']).removeAt(i);
  }

  /**
  * ELIMINA DE LA LISTA DE COMPRAS DE INSUMOS O MOVIMIENTOS DE TIPO COMPRA EL OBJETO QUE RECIBE
  * @param compra OBJETO COMPRA
  */

  borrarCompraObj(compra) {
    let aux = this.util.filtrarArray(this.listCompras, compra)
    this.listCompras = aux;
  }


  /**
   *@description METODO QUE ENVIA EL REGISTRO
  */
  submitForm(): void {

    for (const i in this.compraForm.controls) {
      this.compraForm.controls[i].markAsDirty();
      this.compraForm.controls[i].updateValueAndValidity();

    }

    for (let i of this.getInsumosMovidos().controls) {

      for (let a in i['controls']) {
        console.log(a);
        i['controls'][a].markAsDirty();
        i['controls'][a].updateValueAndValidity();
      }
    }
      if (this.getInsumosMovidos().length>=1) {
        // AQUI ES DND INTENO HCER LO DE LA FECHA
        let control = <FormArray>this.compraForm.controls['insumos_movidos'];
        control.controls.forEach(elemento => {
            elemento.get('fecha_vencimiento').setValue(formatDate(elemento.value.fecha_vencimiento, 'yyyy-MM-dd ', 'en'));
        });
        this.compraForm.get('fecha_vencimiento_factura').setValue(formatDate(this.compraForm.value.fecha_vencimiento_factura, 'yyyy-MM-dd ', 'en'));
        this.compraForm.get('fecha').setValue(formatDate(this.compraForm.get('fecha').value, 'yyyy-MM-dd HH:mm:ss', 'en'));

        console.log("AQUIII", this.compraForm.value);
        if (this.compraForm.valid) {
          this.spinner.show();
          let servicio = this.objetoEditar&&this.tipo=="ver" ? this.compras.editarCompra(this.compraForm.value) : this.compras.crearCompra(this.compraForm.value)
          // let servicio = this.compras.crearCompra(this.compraForm.value);
          servicio.subscribe(res => {
            console.log(res);
            if (res) {
              this.resetForms();
              this.modal.destroy({ data: true });
              this.spinner.hide();
            }
          }, err => {
            this.spinner.hide();
            this.mensaje = err.error.errors
            this.error = true;
            console.log(err);
          });
        }
      } else {
        this.error = true;
        this.mensaje = "Debe agregar un insumo a la lista de Compras";
      }

    }

  resetForms() {
    this.compraForm.reset();
    (<FormArray>this.compraForm.controls['insumos_movidos']).reset();
    (<FormArray>this.compraForm.controls['insumos_movidos']).removeAt(0);

    this.listCompras = [];
    this.listComprasAux = [];
  }

  getUndMed(compra) {
    return this.listUndMed.find(element => element.id == compra.unidad_medida_id).nombre
  }



   /**
   * @description METODO QUE PERMITE ASIGNAR EL EL [precio_estimado] AL INPUT DE COSTO CUANDO HAY UN CAMBIO EN EL SELECT.
   * @param ev ID DEL INSUMO.
  */
 selectInsumo(ev){
   console.log(this.compraForm.value.insumos_movidos,"aqui insumos")

   this.compraForm.value.insumos_movidos.forEach(element => {
     if (element.insumo_id=="") {
       const modal = this.modalService.create({
       nzContent: InsumoComponent,
       nzFooter: null,
       nzWidth: 1000,
     });
     modal.afterClose.subscribe((result) => {

       //console.log('[afterClose] The result is:', result)
       if (result) {
        this.compras.indexWithUnit().subscribe(res => {
          this.insumolist = res

        });

       }
     });
     }
   });
  console.log(ev,"id insumo??");

  console.log

  let control_ingredientes = <FormArray>this.compraForm.controls['insumos_movidos'];
  this.insumolist.forEach( element => {
      if(element.id == ev){
        control_ingredientes.controls.forEach(elemento => {
          if(elemento.value.insumo_id == ev){
            elemento.get('fecha_vencimiento').setValue(element.fecha_vencimiento);
            elemento.get('unidades_medida').setValue(element.unidades_medidas_opciones);
            elemento.get('unidad_medida_id').setValue(element.unidad_medida_id);
            // elemento.get('precio_unitario').setValue(element.precio_estimado);
            elemento.get('precio').setValue(element.precio_estimado);
          }
        });
      }

    });



   console.log(control_ingredientes,"holaaaaa");



  }

  calculos(){
    // console.log("calculando...");

    // if(this.compraForm.value.insumos_movidos && this.compraForm.value.insumos_movidos.length){

    //   let suma_costo = 0;

    //   this.compraForm.value.insumos_movidos.forEach((insumo_movido, index) => {

    //     if(insumo_movido.cantidad && insumo_movido.precio_unitario){
    //       let cantidad = parseFloat(insumo_movido.cantidad);
    //       let precio   = parseFloat(insumo_movido.precio_unitario);

    //       suma_costo += (cantidad * precio);
    //     }
    //   })

    //   this.compraForm.get('monto').setValue( this.redondea(suma_costo) );




    this.compraForm.get('monto').setValue(0);
    let controls;
    let total=0;

    controls = <FormArray>this.compraForm.controls['insumos_movidos'];
    controls.controls.forEach(elemento => {
      total += Number(elemento.get('total').value);
    });
        this.compraForm.get('monto').setValue(total);





  }

  nuevoProveedor(ev): void {

    console.log(ev,"id del proveedor seleccionado")

    this.insumos.insumosWhitProveedor(ev).subscribe(res=>{
      this.proveedorInsumos=res;
      console.log(this.proveedorInsumos,"aquiii")
    })



    //// ESTO SE USA PARA FILTRAR LOS INSUMOS DEL PROVEEDOR



    if (this.compraForm.value.proveedor_id=='') {
     const modal = this.modalService.create({
      nzContent: ProveedorComponent,
      nzFooter: null,
      nzWidth: 800

    });
    modal.afterClose.subscribe((result) => {

      console.log('[afterClose] The result is:', result)
      if (result) {

        this.proveedores.getProveedor().subscribe(res => {
          console.log(res, "proveedores");
          this.proveedoreslist = res;
        });
      }
    });
    }

  }

  nuevoUnidad(event,items): void {
    console.log(this.compraForm.value.insumos_movidos, "aqui unidades")

    this.compraForm.value.insumos_movidos.forEach(element => {
      if (element.unidad_medida_id == 0) {

        const modal = this.modalService.create({
        nzContent: AgregarMedidaComponent,
        nzFooter: null,

      });
      modal.afterClose.subscribe((result) => {

        console.log('[afterClose] The result is:', result)
        if (result) {
          this.mensaje = "Registro exitoso.";
          this.success = true;

        }
      });
      }
    });



    this.actualizarTotalInsumoWithEquivalencia(event,items)

  }

  nuevoAlmacen(){

    console.log(this.compraForm.value.insumos_movidos, "aqui almacenes")

    this.compraForm.value.insumos_movidos.forEach(element => {
      if (element.almacen_destino_id == "") {

         const modal = this.modalService.create({
        nzContent: FormDestinoComponent,
        nzFooter: null,

      });
      modal.afterClose.subscribe((result) => {

        console.log('[afterClose] The result is:', result)
        if (result) {

          this.almancenes.index().subscribe(res => {
            console.log(res, "almacenes");
            this.almaceneslist = res;
          });

          // this.mensaje = "Registro exitoso.";
          // this.success = true;
        }
      });
      }
    });



  }



  actualizarTotalInsumoWithEquivalencia(event=null,items=null){
    // console.log(items,event,"que es esto?");
    if (event != null && items != null) {
      console.log(items, event, "son distintos de null");
      //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL SELECCIONAR UNA UND DE MEDIDA DISTINTA A LA PRINCIPAL MENDIANTE SELECT UNIDAD
    if(items&&event){
      console.log("entre 1")
      let objeto = this.insumolist.find(element => element.id == items.value.insumo_id);
      objeto.unidades_medidas_opciones.forEach(element => {
        if(element.id==event){
          element.equivalencia_unidad_principal==1?items.get('precio_unitario').setValue(objeto.precio_estimado):items.get('precio_unitario').setValue(objeto.precio_estimado/element.equivalencia_unidad_principal);
          items.get('total').setValue(items.value.cantidad*items.value.precio_unitario);
        }
      });
    }

    //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL CAMBIAR LA CANTIDAD DEL INSUMO
    if(!items&&!event){
      console.log("entre 2")
      let  controls = <FormArray>this.compraForm.controls['insumos_movidos'];
      controls.controls.forEach(elemento => {
        let objeto = this.insumolist.find(element => element.id == elemento.value.insumo_id);
        objeto.unidades_medidas_opciones.forEach(element => {
          if(element.id==elemento.value.unidad_medida_id){
            element.equivalencia_unidad_principal==1?elemento.get('precio_unitario').setValue(objeto.precio_estimado):elemento.get('precio_unitario').setValue(objeto.precio_estimado/element.equivalencia_unidad_principal);
            elemento.get('total').setValue(elemento.value.cantidad*elemento.value.precio_unitario);
          }
        });

      });
    }


    }


    let controls = <FormArray>this.compraForm.controls['insumos_movidos'];
    controls.controls.forEach(elemento => {

      elemento.get('total').setValue(elemento.value.cantidad * elemento.value.precio_unitario);


    });

    this.calculos();

  }


}
