import { TokenStorage } from 'src/app/shared/util/token.storage';
import { UploadFile } from 'ng-zorro-antd/upload';
import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';
import { ProveedorComponent } from 'src/app/forms/proveedor/proveedor.component';
import { ProveedorService } from 'src/app/shared/services/proveedor.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { rutaApi } from 'src/app/shared/util/constants';

@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.css']
})
export class ProveedoresComponent implements OnInit {
  success=false;
  mensaje:any="";
  error=false;
  proveedoreslist:any[];
  banView:boolean=false;
  constructor(
    private modalService:NzModalService,
    private proveedor:ProveedorService,
    private spinner: NgxSpinnerService,
    private mensajeService:MensajeComponentService,
    private tokenStorage:TokenStorage
  ) {
    this.mensajeService.currentMessage.subscribe(message => {
      switch (message) {


        case 'deleteProveedor':          
          this.listarProveedores();
          break;
          case 'updateProveedor':          
          this.listarProveedores();
          break;
        default:
          break;
      }
    });
   }

  ngOnInit() {
    // this.spinner.show(); 
    this.listarProveedores();
    
  }


  createComponentModal(): void {
    const modal = this.modalService.create({
        nzContent: ProveedorComponent,
        nzFooter:null,
        nzWidth:800
        
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){

        this.listarProveedores();


        this.mensaje="Registro exitoso.";
        this.success=true;
      }
    });
  }


   /** Manejar los mensajes cuando se està cargando un archivo **/
   handleChange({ file, fileList }): void {

    console.log("change", file);

    const status = file.status;

    switch (status){
      case "uploading":
        this.success = false;
        this.error = false;
        this.spinner.show();
      break;
      
      case "done":
        this.mensaje="Importación exitosa."
        this.listarProveedores();
              
        this.success = true;        
        this.spinner.hide();
        break;
      case "error":
        console.log(file);
        let error_file = file.error.error.errors.file;
        let errores_validacion = [];

        if(error_file){
          this.mensaje = "Ocurrio un problema: " + error_file;
        }
        else{
          this.mensaje = "Ocurrio un problema";
          
          let errors = file.error.error.errors;
     
          if(errors.length){

            errores_validacion.push( "El archivo cargado contiene errores: \n\n" );

            errors.forEach(element => {              
              errores_validacion.push( "Columna " + element.row + ": " + element.errors.shift() );
            });
            this.mensaje =  errores_validacion   
          }         
        }
        
        this.error = true;
        this.spinner.hide();

        // if(errores_validacion.length)
        //   setTimeout(()=>{
        //     alert( errores_validacion.join("\n") );
        //   }, 500)
          
      break;
    }

    
    

  }

  setMediaUploadHeaders = (file: UploadFile) => {
    return {      
      "Accept": "application/json",
      "Authorization": "Bearer "+this.tokenStorage.getToken(),
    }
  };
  rutaSubirExcel(){
    return rutaApi('/proveedor/cargar_excel?restaurante_id=' + localStorage.getItem("restaurante_seleccionado_id"));
  }


  listarProveedores(){
    this.proveedor.getProveedor().subscribe(res=>{
      
      console.log(res,"proveedores");

      this.proveedoreslist=res;
      this.banView=true;
      // this.spinner.hide(); 
      
    },err=>{
      this.banView=true;
      this.mensaje="Ocurrio un problema."      
      // this.spinner.hide();
      console.log(err);
    });

  }

}
