import { AreaRecetaService } from 'src/app/shared/services/area-receta.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-agregar-area-receta',
  templateUrl: './agregar-area-receta.component.html',
  styleUrls: ['./agregar-area-receta.component.css']
})
export class AgregarAreaRecetaComponent implements OnInit {

  @Input() areaEditar: any = null;  // Si se recibe un restaurante está en modo edición, sino modo crear


  success = false;
  mensaje = "";
  areaForm: FormGroup;
  error = false;
  constructor(
    private modalService: NzModalService,
    private fb: FormBuilder,
    private modal: NzModalRef,
    private spinner: NgxSpinnerService,
    private area: AreaRecetaService,
  ) {
    this.areaForm = this.fb.group({
      nombre: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    if (this.areaEditar) {
      console.log("editando", this.areaEditar)
      this.areaForm.get('nombre').setValue(this.areaEditar.nombre);
    }
    else
      console.log("nueva und de medida");
  }

  /**
   *@description METODO QUE ENVIA EL REGISTRO 
  */
  submitForm(): void {
    console.log('enviar');
    for (const i in this.areaForm.controls) {
      this.areaForm.controls[i].markAsDirty();
      this.areaForm.controls[i].updateValueAndValidity();
    }
    let payload = {};
    if (this.areaForm.valid) {
      this.spinner.show();

      payload['nombre'] = this.areaForm.value.nombre;
      payload['restaurante_id'] = localStorage.getItem("restaurante_seleccionado_id");


      console.log(payload);
      // Editar unidad de medida
      if (this.areaEditar) {

        payload['id'] = this.areaEditar.id;

        this.area.update(payload).subscribe(res => {
          console.log(res);
          this.modal.destroy({ data: true });
          this.spinner.hide();
        }, err => {
          // this.mensaje = "Ocurrio un problema."
          this.mensaje = err.error.errors
          this.error = true;
          this.spinner.hide();
          console.log(err);
        });
      } else {
        // Añadir unidad de medida
        this.area.registro(payload).subscribe(res => {
          console.log(res);
          this.modal.destroy({ data: true });
          this.spinner.hide();
        }, err => {
          // this.mensaje = "Ocurrio un problema."
          this.mensaje = err.error.errors
          this.error = true;
          this.spinner.hide();
          console.log(err);
        });
      }
    }
  }
}

