import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rutaApi } from '../util/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor( private http: HttpClient) { }


   /**
   * 
   * @description listado de menus
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public index(): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/menu?restaurante_id='+ rest));
  }

   /**
   * 
   * @description registrar el menu
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public post(object): Observable<any>{
    return this.http.post<any>(rutaApi('/menu'),object);
  }

  /**
   * 
   * @description actualizar el menu
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public put(object): Observable<any>{        
    return this.http.put<any>(rutaApi('/menu/' + object.id ), object);
  }

  
  /**   
   * @description Eliminar objeto
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public delete(object): Observable<Array<any>>{
    return this.http.delete<any>(rutaApi('/menu/' + object.id ));
  }

}
