import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-super-admin',
  templateUrl: './menu-super-admin.component.html',
  styleUrls: ['./menu-super-admin.component.css']
})
export class MenuSuperAdminComponent implements OnInit {

  opcionMenu: any[] = [];

  constructor(private router: Router) { }

  ngOnInit() {

    this.opcionMenu = [
      {
        titulo: 'Dashboard',
        icon: 'assets/img/menu_cake.svg',
        path: '/dashboard/dashboard-super',
      },
      {
        titulo: 'Usuarios',
        icon: 'assets/img/menu_user.svg',
        path: '/gestion-usuario/gestion-super-admin',
      },
      {
        titulo: 'Sugerencias',
        icon: 'assets/img/dudas.svg',
        path: '/pages/sugerencias',
      },
    ];
  }

  /**
   * @description Metodo que permite redireccionar de acuerdo al parametro que recibe.
   * @param ev [Opcion del menu]
   */
  redireccion(ev) {
    this.router.navigate([ev]);
  }
}
