import { SubrecetasComponent } from './../../pages/subrecetas/subrecetas.component';
import { InsumoComponent } from './../insumo/insumo.component';
import { Ingrediente } from './../../shared/interfaces/ingredientes.type';
import { UploadFile } from 'ng-zorro-antd/upload';
import { AreaReceta } from './../../shared/interfaces/area-receta.type';
import { IngredientesModel } from '../../shared/interfaces/ingredientes.type';
import { RecetasModel } from '../../shared/interfaces/recetas.type';
import { Component, OnInit, Input } from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnidadMedidaService } from 'src/app/shared/services/unidad-medida.service';
import { InsumoService } from 'src/app/shared/services/insumo.service';
import { RecetaService } from 'src/app/shared/services/receta.service';
import { AreaRecetaService } from 'src/app/shared/services/area-receta.service';
import { SubrecetaService } from 'src/app/shared/services/subreceta.service';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service';
import { AgregarAreaRecetaComponent } from '../agregar-area-receta/agregar-area-receta.component';
import { AgregarMedidaComponent } from '../agregar-medida/agregar-medida.component';
import { FormSubrecetaComponent } from '../subreceta/subreceta.component';

@Component({
  selector: 'app-form-receta',
  templateUrl: './receta.component.html',
  styleUrls: ['./receta.component.css']
})
export class FormRecetaComponent implements OnInit {

  @Input() objetoEditar: any = null;  // Aquí se guarda el objeto a editar (cuando corresponde)
  loading = false;
  avatarUrl: string;

  isNew: boolean = true; // True si es un nuevo registro, false si se está editando
  loadingReceta:boolean=false;
  form: FormGroup;
  error = false;
  mensaje = "";
  moneda: String = "";


  areaslist: any[];
  unidadesMedidasList: any[];
  subrecetaslist: any[];
  insumolist: any[];
  success: Boolean = false;
  addAreaReceta;
  addUnidadMed;
  addSubReceta;
  mostrarPlus = false;
  mostrarPlusIngredientes = false;
  nombresAlergenos=[];

  // VARABLES PARA LA CALCULAR LA MATERIA PRIMA REAL
  valorCambiado;//
  valorNuevo;//


  porcMateriaReal = 0;//
  ////////////////////
  constructor(
    private modalService: NzModalService,
    private fb: FormBuilder,
    private modal: NzModalRef,
    private spinner: NgxSpinnerService,
    private medida: UnidadMedidaService,
    private insumos: InsumoService,
    private recetaService: RecetaService,
    private areaRecetaService: AreaRecetaService,
    private subRecetaService: SubrecetaService,
    private restauranteService:RestaurtantService,
    private rs:RecetaService
  ) {

    this.form = this.fb.group({
      nombre: [null, [Validators.required]],
      porciones: [null, [Validators.required, Validators.min(1)]],
      area_recetas_id: [null, [Validators.required]],
      procedimiento: [null],
      foto: [null],
      porc_error: [0, [Validators.required]],
      porc_materia_prima: [99, [Validators.required, Validators.min(1), Validators.max(99)]],
      porc_impuestos: [0],
      porc_servicios: [0],
      precio_venta: [0],
      ingredientes: this.fb.array([this.crearIngrediente()]),
      subrecetas: this.fb.array([]),

    });


  }

  ngOnInit() {





    console.log(this.objetoEditar, "esto se va a editar")



    if (this.objetoEditar) {


      this.isNew = false;
      this.loadingReceta=true;
      this.rs.mostrar(this.objetoEditar.id).subscribe(res=>{
        console.log("AQIIOOOOOO",res);
        this.objetoEditar = res;
        if (this.objetoEditar.foto != null) {
          this.avatarUrl = this.objetoEditar.foto.url;
        }
        // Se llenan campos del formulario
        this.form = this.fb.group({
          id: [this.objetoEditar.id, [Validators.required]],
          nombre: [this.objetoEditar.nombre, [Validators.required]],
          porciones: [this.objetoEditar.porciones, [Validators.required, Validators.min(1)]],
          // unidad_medida_id:  [this.objetoEditar.unidad_medida_id,  [Validators.required]],
          // cantidad_preparar: [this.objetoEditar.cantidad_preparar, [Validators.required, Validators.min(1)]],
          area_recetas_id: [this.objetoEditar.area_recetas_id, [Validators.required]],
          procedimiento: [this.objetoEditar.procedimiento],
          foto: [null],
          porc_error: [this.objetoEditar.porc_error, [Validators.required]],
          porc_materia_prima: [this.objetoEditar.porc_materia_prima, [Validators.required, Validators.min(1), Validators.max(99)]],
          porc_impuestos: [this.objetoEditar.porc_impuestos],
          porc_servicios: [this.objetoEditar.porc_servicios],
          precio_venta: [this.objetoEditar.precio_venta],
          ingredientes: this.fb.array([]),
          subrecetas: this.fb.array([]),

        });

        // Insertar subrecetas
        let control_subrecetas = <FormArray>this.form.controls['subrecetas'];
        this.insumos.indexWithUnit().subscribe(res => {
          console.log(res, "insumos");
          this.insumolist = res;


          // Insertar ingredientes
          let control_ingredientes = <FormArray>this.form.controls['ingredientes'];
          console.log("ABBBAA",this.objetoEditar);
          this.objetoEditar.ingredientes.forEach((ingrediente,index) => {
            //setear alergenos al ingrediente
            let objeto = this.insumolist.find(sub => sub.id == ingrediente.insumo_id);
            //fin setear alergenos al ingrediente

            control_ingredientes.push(this.fb.group({
              insumo_id: [ingrediente.insumo_id, [Validators.required]],
              unidad_medida_id: [ingrediente.unidad_medida_id, [Validators.required]],
              cantidad: [ingrediente.cantidad, [Validators.required, Validators.min(0.1)]],
              porc_merma: [ingrediente.porc_merma, [Validators.required]],
              porc_merma_coccion: [ingrediente.porc_merma_coccion, [Validators.required]],
              costo_insumo: [ingrediente.costo_insumo, [Validators.required]],
              total: [null],
              alergenos: [this.getMensajeAlergenos(objeto.alergenos)],

            }));

            // this.selectInsumo(ingrediente.insumo_id);
          });


          this.objetoEditar.subrecetas.forEach(subreceta => {
            let subrecetaob = this.subrecetaslist.find(element => element.id == subreceta.id);
            control_subrecetas.push(this.fb.group({
              alergenos: [this.getMensajeAlergenos(subrecetaob.alergenos)],
              id: [subreceta.id, [Validators.required]],
              cantidad: [subreceta.pivot.cantidad, [Validators.required, Validators.min(0.1)]],
              total: [subrecetaob.costo_total_porcion * subreceta.pivot.cantidad, [Validators.required]],
            }));
            console.log(control_subrecetas);
          });
          this.actualizarTotalInsumoWithEquivalencia();
          this.getTotalInsumo();
          this.loadingReceta=false;
        });

      })//CIERRE SUBSCRIBE GET RECETA BY ID



      console.log(this.objetoEditar);
    } else { // Crear nueva subreceta

      this.form = this.fb.group({
        nombre: [null, [Validators.required]],
        porciones: [null, [Validators.required, Validators.min(1)]],
        // unidad_medida_id: [null, [Validators.required]],
        // cantidad_preparar: [null, [Validators.required, Validators.min(1)]],
        area_recetas_id: [null, [Validators.required]],
        procedimiento: [null],
        foto: [null],
        porc_error: [0, [Validators.required]],
        porc_materia_prima: [99, [Validators.required, Validators.min(1), Validators.max(99)]],
        porc_impuestos: [0],
        porc_servicios: [0],
        precio_venta: [0],
        ingredientes: this.fb.array([this.crearIngrediente()]),
        subrecetas: this.fb.array([]),

      });

      this.insumos.indexWithUnit().subscribe(res => {
        console.log(res, "insumos");
        this.insumolist = res;
        this.calculos();
      });
    }


    // Cargar data de los selects
    this.medida.getMedida().subscribe(res => {
      console.log(res, "medidas");
      this.unidadesMedidasList = res;
    });



    this.areaRecetaService.getArea().subscribe(res => {
      console.log(res, "areas");
      this.areaslist = res;
    });

    this.subRecetaService.indexWithPrice().subscribe(res => {
      console.log(res, "subrecetas");
      this.subrecetaslist = res;
    });

    // Obtener la moneda del restaurante
    this.restauranteService.getMoneda(localStorage.getItem('restaurante_seleccionado_id')).subscribe(res => {
      this.moneda = res.moneda;
    })
  }

  agregarItem() {


    let control = <FormArray>this.form.controls['ingredientes'];

    control.push(this.crearIngrediente())

    console.log(this.form.get("ingredientes").value);

    console.log(this.getIngredientes().controls);
  }

  agregarItemSubreceta() {
    this.addSubReceta = ""

    let control = <FormArray>this.form.controls['subrecetas'];

    control.push(this.crearSubreceta())

    console.log(this.form.get("subrecetas").value);

    console.log(this.getSubRecetas().controls);
  }

  getIngredientes(): FormArray {
    return this.form.get("ingredientes") as FormArray;
  }

  getSubRecetas(): FormArray {
    return this.form.get("subrecetas") as FormArray;
  }

  recetaaux(){
    console.log(this.getSubRecetas().controls[0].value);
  }
  crearIngrediente(): FormGroup {

    return this.fb.group({
      insumo_id: [null, [Validators.required]],
      unidad_medida_id: [null, [Validators.required]],
      cantidad: [null, [Validators.required, Validators.min(0.1)]],
      porc_merma: [0],
      porc_merma_coccion: [0],
      costo_insumo: [null, [Validators.required]],
      costo_total: [null],
      total: [null],
      alergenos: [null],
    })

  }

  crearSubreceta(): FormGroup {
    return this.fb.group({
      id: [null, [Validators.required]],
      alergenos: [null],
      cantidad: [null, [Validators.required, Validators.min(0.1)]],
      total: [null],
    })


  }


  filtrarArray(Arreglo, valor) {

    return Arreglo.filter(item => item !== valor);
  }

  borrarItem(i: number) {
    (<FormArray>this.form.controls['ingredientes']).removeAt(i);
    this.actualizarTotalInsumoWithEquivalencia();
  }

  borrarSubreceta(i: number) {
    (<FormArray>this.form.controls['subrecetas']).removeAt(i);
    this.actualizarTotalInsumoWithEquivalencia();
  }

  /**
   * Obtener las opciones de unidades de medidas de un insumo
   * @param insumo_id
   * @return Array de unidades de medidas
   */
  getUnidadesMedidasInsumo(insumo_id: number) {
    return (insumo_id && this.insumolist && this.insumolist.length) ? this.insumolist.find(element => element.id == insumo_id).unidades_medidas_opciones : [];
  }


  /**
   * @description ASIGNAR [precio_estimado] y [unidad_medida_id] DEL INGREDIENTE CUANDO HAY UN CAMBIO EN EL SELECT DE INSUMO.
   * @param ev ID DEL INSUMO.
  */
  selectInsumo(ev) {

    console.log(this.form.value.ingredientes, "hay ingredientes aquii")


    this.form.value.ingredientes.forEach((element,index) => {
      //SETEAR LOS ALERGERNOS
      let objeto = this.insumolist.find(sub => sub.id == element.insumo_id);
      let control = <FormArray>this.form.controls['ingredientes'];
      if(objeto)
        control.controls[index].get('alergenos').setValue(this.getMensajeAlergenos(objeto.alergenos));









      if (element.insumo_id == "") {
        const modal = this.modalService.create({
          nzContent: InsumoComponent,
          nzWidth: 1000,
          nzFooter: null,

        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
            this.insumos.indexWithUnit().subscribe(res => {
              console.log(res, "insumos");
              this.insumolist = res;
            });
          }
        });

      }

    });

    console.log("cambió insumo " + ev);
    let control_ingredientes = <FormArray>this.form.controls['ingredientes'];
    this.insumolist.forEach(element => {
      if (element.id == ev) {
        control_ingredientes.controls.forEach(elemento => {
          if (elemento.value.insumo_id == ev) {
            // if(this.objetoEditar){
            //   elemento.get('costo_insumo').setValue(this.objetoEditar.precio_estimado);
            //   elemento.get('unidad_medida_id').setValue(this.objetoEditar.unidad_medida_id);
            // }else{
            elemento.get('costo_insumo').setValue(element.precio_estimado);
            elemento.get('unidad_medida_id').setValue(element.unidad_medida_id);
            // }


          }
        });
      }

    });

    this.calculos();

  }

  nuevoArea(): void {


    if (this.form.value.area_recetas_id == 0) {
      const modal = this.modalService.create({
        nzContent: AgregarAreaRecetaComponent,
        nzFooter: null,

      });
      modal.afterClose.subscribe((result) => {

        console.log('[afterClose] The result is:', result)
        if (result) {
          this.areaRecetaService.getArea().subscribe(res => {
            console.log(res, "areas");
            this.areaslist = res;
          });
        }
      });
    }


  }


  submitForm(): void {
    console.log("AQUIII", this.form.value);
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }

    for (let i of this.getIngredientes().controls) {

      for (let a in i['controls']) {
        console.log(a);
        i['controls'][a].markAsDirty();
        i['controls'][a].updateValueAndValidity();
      }
    }

    for (let i of this.getSubRecetas().controls) {

      for (let a in i['controls']) {
        console.log(a);
        i['controls'][a].markAsDirty();
        i['controls'][a].updateValueAndValidity();
      }
    }

    const payload = new FormData();
    if (this.form.valid) {
      this.spinner.show();

      if (!this.isNew) {
        payload.append('id', this.form.value.id);
      }
      payload.append('nombre', this.form.value.nombre);
      payload.append('porciones', this.form.value.porciones);
      payload.append('area_recetas_id', this.form.value.area_recetas_id);
      if (this.form.value.foto != null) {
        payload.append('foto', this.form.value.foto);
      }
      payload.append('unidad_medida_id', this.form.value.unidad_medida_id);
      payload.append('cantidad_preparar', this.form.value.cantidad_preparar);
      payload.append('porc_error', this.form.value.porc_error);
      payload.append('porc_materia_prima', this.form.value.porc_materia_prima);
      payload.append('porc_impuestos', this.form.value.porc_impuestos);
      payload.append('porc_servicios', this.form.value.porc_servicios);
      payload.append('procedimiento', this.form.value.procedimiento);
      payload.append('precio_venta', this.form.value.precio_venta);
      payload.append('restaurante_id', localStorage.getItem("restaurante_seleccionado_id"));
      this.form.value.ingredientes.forEach((object, index) => {
        payload.append('ingredientes[' + index + '][insumo_id]', object.insumo_id);
        payload.append('ingredientes[' + index + '][unidad_medida_id]', object.unidad_medida_id);
        payload.append('ingredientes[' + index + '][cantidad]', object.cantidad);
        payload.append('ingredientes[' + index + '][porc_merma]', object.porc_merma);
        payload.append('ingredientes[' + index + '][porc_merma_coccion]', object.porc_merma_coccion);
        payload.append('ingredientes[' + index + '][costo_insumo]', object.costo_insumo);
      });
      this.form.value.subrecetas.forEach((object, index) => {
        payload.append('subrecetas[' + index + '][cantidad]', object.cantidad);
        payload.append('subrecetas[' + index + '][id]', object.id);
      });
      // payload.append('ingredientes', JSON.stringify(this.form.value.ingredientes));
      // payload.append('subrecetas',  JSON.stringify(this.form.value.subrecetas));

      let servicio = this.isNew ? this.recetaService.post(payload) : this.recetaService.put(payload);

      servicio.subscribe(res => {
        this.modal.destroy({ data: true });
        this.spinner.hide();

      }, err => {
        this.mensaje = err.error.errors;
        this.error = true;
        this.spinner.hide();
      });

    }


  }
  /**
   * calcula el costo total del ingrediente en funcion de la cantidad, el costro, % de merma y % de coccion
   */
  // Alertas de cálculo de precio
  faltaPorciones: boolean = false;
  faltaDatosIngrediente: boolean = false;
  suma_costo_total: number = 0;
  suma_costo_porcion: number = 0;
  suma_costo_porcion_error: number = 0;
  costo_porcion_final: number = 0;
  costo_valor_venta: number = 0;
  costo_valor_venta_sugerido: number = 0;
  factorMultiplicador: number = 0;
  calculos() {

    console.log(this.form.value.porc_materia_prima);
    console.log("calculando...");

    if (this.form.value.porciones) {
      this.faltaPorciones = false;
    } else {
      this.faltaPorciones = true;
    }

    if (this.form.value.ingredientes) {
      this.form.value.ingredientes.forEach((ingrediente, index) => {
        if (ingrediente.cantidad === null || ingrediente.costo_insumo === null) {
          this.faltaDatosIngrediente = true;
        }
        else {
          this.faltaDatosIngrediente = false;
        }
      });
    }


    if (!this.faltaPorciones) {
      this.suma_costo_total = this.suma_costo_porcion = 0;
      let porciones = this.form.value.porciones;
      console.log("calculando..", this.suma_costo_total);
      //AGREGAMOS EL VALOR DE LAS SUBRECETAS AL TOTAL
      this.form.value.subrecetas.forEach((obj, index) => {
        if (this.subrecetaslist) {
          let subreceta = this.subrecetaslist.find(element => element.id == obj.id);
          if (obj.cantidad)
            this.suma_costo_total += subreceta.costo_total_porcion * obj.cantidad;
          console.log(subreceta, "aaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        }
      });

      let sum = 0;
      let suma = 0;
      this.form.value.ingredientes.forEach((ingrediente, index) => {
        sum = 0;
        // let porc_usado = 0;

        // porc_usado = 100 - ingrediente.porc_merma;   // Ej 100 - 5
        sum += ingrediente.cantidad * ingrediente.costo_insumo;
        suma = ingrediente.cantidad*ingrediente.costo_insumo;
        // sum += ingrediente.total;

        if (ingrediente.porc_merma && ingrediente.porc_merma != 0) {
          let sumaMerma = 0;

          // sumaMerma = sum * ingrediente.porc_merma / porc_usado;
          sumaMerma = ingrediente.porc_merma * suma / 100;

          sum += sumaMerma;
        }
        if (ingrediente.porc_merma_coccion && ingrediente.porc_merma_coccion != 0) {
          let sumaMerma;

          // sumaMerma = sum * ingrediente.porc_merma_coccion / (100 - ingrediente.porc_merma_coccion);

          // sum = sum + sumaMerma;
          // sumaMerma = ingrediente.porc_merma_coccion * ingrediente.cantidad / 100;
          sumaMerma = ingrediente.porc_merma_coccion * suma / 100;

          sum += sumaMerma;
        }
        //SETEAR VALOR AL TOTAL
        // let controls = <FormArray>this.form.controls['ingredientes'];
        // console.log(controls.controls[0]);
        // controls.controls[index].get('total').setValue(0);
        // controls.controls[index].get('total').setValue(Math.round(sum * 100) / 100);

        console.log(sum);
        this.suma_costo_total += sum;
      });
      this.suma_costo_total = Math.round(this.suma_costo_total * 100) / 100; // redondear suma_costo_total
      this.suma_costo_porcion = this.suma_costo_total / porciones;
      this.suma_costo_porcion = Math.round(this.suma_costo_porcion * 100) / 100; // // redondear suma_costo_porcion
      console.log("aqui hay un peo", this.suma_costo_porcion);
      //calculo porcentaje de error
      if (this.form.value.porc_error) {
        this.suma_costo_porcion_error = Math.round(this.suma_costo_porcion * this.form.value.porc_error) / 100;
        this.costo_porcion_final = this.suma_costo_porcion + this.suma_costo_porcion_error;
        this.costo_porcion_final = Number(Number.parseFloat("" + this.costo_porcion_final).toFixed(2));
      }

      if (this.form.value.porc_materia_prima) {
        this.factorMultiplicador = Math.round((100 / this.form.get('porc_materia_prima').value) * 100) / 100;  // redondear suma_costo_total
      }


      //valor de venta
      // this.costo_valor_venta = Number(Number.parseFloat(""+(this.suma_costo_porcion+this.suma_costo_porcion_error)*(100/this.form.get('porc_materia_prima').value)).toFixed(2));
      console.log(this.costo_valor_venta, "FACTORRRRRRR");
      this.costo_valor_venta = Number.parseFloat("" + (this.suma_costo_porcion + this.suma_costo_porcion_error));
      this.costo_valor_venta = this.costo_valor_venta * Number(this.factorMultiplicador);
      this.costo_valor_venta_sugerido = this.costo_valor_venta;
      this.costo_valor_venta_sugerido += this.costo_valor_venta * this.form.get('porc_impuestos').value / 100;
      this.costo_valor_venta_sugerido += this.costo_valor_venta * this.form.get('porc_servicios').value / 100;
      //this.costo_valor_venta_sugerido = this.costo_valor_venta + Math.floor(this.costo_valor_venta*this.form.get('porc_impuestos').value)/100+Math.floor(this.costo_valor_venta*this.form.get('porc_impuestos').value)/100;
      if (!this.objetoEditar) {
        this.form.get('precio_venta').setValue(this.costo_valor_venta_sugerido)
      }

      this.valorCambiado = this.form.get('porc_materia_prima').value
    } else {
      console.log("no se calcula, faltan datos");
    }
    this.detectarCambio();
    this.getTotalInsumo();

  }



  nuevaSubReceta(ev) {
    this.form.value.subrecetas.forEach((element,index) => {
      //SETEAR LOS ALERGERNOS
      let objeto = this.subrecetaslist.find(sub => sub.id == element.id);
      let control = <FormArray>this.form.controls['subrecetas'];
      if(objeto)
        control.controls[index].get('alergenos').setValue(this.getMensajeAlergenos(objeto.alergenos));


      if (element.id == "") {

        const modal = this.modalService.create({
          nzContent: FormSubrecetaComponent,
          nzFooter: null,
          nzClassName: 'modalsubreceta'


        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
            // this.mensaje = "Registro exitoso.";
            // this.success = true;
            this.subRecetaService.indexWithPrice().subscribe(res => {
              console.log(res, "subrecetas");
              this.subrecetaslist = res;
            });
          }
        });

      }
    });

    this.calculos();
  }



  nuevaMedida(event, items) {
    this.actualizarTotalInsumoWithEquivalencia(event, items);
    // items.get('total').setValue();
  }



  actualizarTotalInsumoWithEquivalencia(event = null, items = null) {
    console.log(items, event, "que es esto?");

    if (event != null && items != null) {
      //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL SELECCIONAR UNA UND DE MEDIDA DISTINTA A LA PRINCIPAL MENDIANTE SELECT UNIDAD
      if (items && event) {
        let objeto = this.insumolist.find(element => element.id == items.value.insumo_id);
        objeto.unidades_medidas_opciones.forEach(element => {
          if (element.id == event) {
            element.equivalencia_unidad_principal == 1 ? items.get('costo_insumo').setValue(objeto.precio_estimado) : items.get('costo_insumo').setValue(objeto.precio_estimado / element.equivalencia_unidad_principal);
            items.get('total').setValue(items.value.cantidad * items.value.costo_insumo);
          }
        });
      }

      //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL CAMBIAR LA CANTIDAD DEL INSUMO
      if (!items && !event) {
        let controls = <FormArray>this.form.controls['ingredientes'];
        controls.controls.forEach(elemento => {
          let objeto = this.insumolist.find(element => element.id == elemento.value.insumo_id);
          objeto.unidades_medidas_opciones.forEach(element => {
            if (element.id == elemento.value.unidad_medida_id) {
              element.equivalencia_unidad_principal == 1 ? elemento.get('costo_insumo').setValue(objeto.precio_estimado) : elemento.get('costo_insumo').setValue(objeto.precio_estimado / element.equivalencia_unidad_principal);
              elemento.get('total').setValue(elemento.value.cantidad * elemento.value.costo_insumo);
            }
          });

          // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
        });
      }
    }


    let controls = <FormArray>this.form.controls['ingredientes'];
    controls.controls.forEach(elemento => {

      elemento.get('total').setValue(elemento.value.cantidad * elemento.value.costo_insumo);


    });

    this.calculos();
    this.detectarCambio();

  }



  // METODO PARA CALCULAR LAS MATERIAS  PRIMAS REALES
  porc_impuest_real=0;
  porc_servicio_real=0;
  detectarCambio() {
    let porcImpuestos = this.form.get('porc_impuestos').value / 100;
    let porcServicios = this.form.get('porc_servicios').value / 100;

    this.valorNuevo = this.form.get('precio_venta').value / (1 + porcImpuestos + porcServicios)
    console.log("QQQQQQQQQQQQQQQQQQAA",this.valorNuevo);
    this.porc_impuest_real = this.valorNuevo * porcImpuestos / 100;
    this.porc_servicio_real= this.valorNuevo * porcServicios / 100;
    this.porc_impuest_real *=100
    this.porc_servicio_real *=100
    console.log(this.valorNuevo, "valor de venta real")

    if (this.valorNuevo) {
      console.log(this.suma_costo_porcion, "cuanto es esto")

      this.porcMateriaReal = Number(Number.parseFloat("" + ((this.costo_valor_venta / this.valorNuevo) * 100) / this.factorMultiplicador).toFixed(2));
      console.log(this.porcMateriaReal, "porcentaje de materia prima real")
    }
  }





  getTotalInsumo(){
    let sum = 0;
    let suma = 0;
    this.form.value.ingredientes.forEach((ingrediente, index) => {
      console.log(ingrediente);





      sum = 0;
      suma = 0;
      // let porc_usado = 0;

      // porc_usado = 100 - ingrediente.porc_merma;   // Ej 100 - 5
      // sum += ingrediente.cantidad * ingrediente.costo_insumo;
      sum += ingrediente.cantidad*ingrediente.costo_insumo;
      suma = ingrediente.cantidad*ingrediente.costo_insumo;

      if (ingrediente.porc_merma && ingrediente.porc_merma != 0) {
        let sumaMerma = 0;

        // sumaMerma = sum * ingrediente.porc_merma / porc_usado;
        // sumaMerma = ingrediente.porc_merma * ingrediente.cantidad / 100;
        sumaMerma = ingrediente.porc_merma * suma / 100;

        sum += sumaMerma;
      }
      if (ingrediente.porc_merma_coccion && ingrediente.porc_merma_coccion != 0) {
        let sumaMerma;

        // sumaMerma = sum * ingrediente.porc_merma_coccion / (100 - ingrediente.porc_merma_coccion);
        // sumaMerma = ingrediente.porc_merma_coccion * ingrediente.cantidad / 100;

        sumaMerma = ingrediente.porc_merma_coccion * suma / 100;

        sum += sumaMerma;
      }
      //SETEAR VALOR AL TOTAL
      let controls = <FormArray>this.form.controls['ingredientes'];
      console.log(controls.controls[0]);
      controls.controls[index].get('total').setValue(Math.round(sum * 100) / 100);

    });
    this.getTotalSubRecetas();
  }




  getTotalSubRecetas(){
    this.form.value.subrecetas.forEach((obj, index) => {
      if (this.subrecetaslist) {
        let subreceta = this.subrecetaslist.find(element => element.id == obj.id);
        if (obj.cantidad){
          let controls = <FormArray>this.form.controls['subrecetas'];
          controls.controls[index].get('total').setValue(Math.round(subreceta.costo_total_porcion * obj.cantidad * 100) / 100);
        }
      }
    });



  }


  getMensajeAlergenos(alergenos){
    console.log(alergenos,"aqui")



    let nombreAlergenos=[];

    alergenos.forEach(element => {
      nombreAlergenos.push(element.nombre)
    });
    if(nombreAlergenos.length){

      return "Contiene alérgenos: "+nombreAlergenos;

    }else{

      return "";
    }







  }






  handleChange(info: { file: UploadFile }): void {
    console.log(info);

    this.loading = true;
    if (info.file.status === 'error') {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, (img: string) => {
        this.loading = false;
        this.avatarUrl = img;
        this.form.get('foto').setValue(info.file.originFileObj);


      });
    }
  }

  private getBase64(img: File, callback: (img: {}) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

}


