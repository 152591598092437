import { Observable } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.type';
import { Injectable } from '@angular/core';
import { rutaApi } from '../util/constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AlmacenService {

  constructor(
   private http: HttpClient
    ) { }


  /**
   * 
   * @description Obtener listado
   * @author Javier Evans
   * @returns Observable<any> 
   */ 
  public index(): Observable<Array<User>> {
    return this.http.get<any>(rutaApi('/almacen'), {
      params: {
        restaurante_id: localStorage.getItem('restaurante_seleccionado_id')
      }
    });
  }

  /**
   * 
   * @description Guardar nuevo objeto
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public post(object): Observable<any> {
    return this.http.post<any>(rutaApi('/almacen'), object);
  }

  /**
   * 
   * @description Actualizar Unidad de Medida
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public put(object): Observable<any> {
    return this.http.put<any>(rutaApi('/almacen/' + object.id), object);
  }

  /**   
   * @description Eliminar objeto
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public delete(object): Observable<Array<any>> {
    return this.http.delete<any>(rutaApi('/almacen/' + object.id));
  }
}
