import { AuthenticationModule } from './authentication/authentication.module';
import { JwtInterceptor } from './shared/interceptor/token.interceptor';
import { TokenStorage } from 'src/app/shared/util/token.storage';
import { PagesModule } from './pages/pages.module';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { registerLocaleData, LocationStrategy, PathLocationStrategy } from '@angular/common';
import en from '@angular/common/locales/en';

import { AppRoutingModule } from './app-routing.module';
import { TemplateModule } from './shared/template/template.module';
import { SharedModule } from './shared/shared.module';
import { MenuModule } from './menu/menu.module';

import { AppComponent } from './app.component';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';

import { NgChartjsModule } from 'ng-chartjs';
import { ThemeConstantService } from './shared/services/theme-constant.service';
import { ReactiveFormsModule } from '@angular/forms';
import { GestionUsuarioModule } from './gestion-usuario/gestion-usuario.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ThemeService } from 'ng2-charts';





registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    FullLayoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgZorroAntdModule,
    AppRoutingModule,
    TemplateModule,
    SharedModule,
    MenuModule,
    ReactiveFormsModule,
    NgChartjsModule,
    GestionUsuarioModule,
    PagesModule,
    AuthenticationModule,
    ReactiveFormsModule

  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    ThemeConstantService,
    TokenStorage,
    ThemeService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
