import { Component, OnInit,Input } from '@angular/core';
import { Categorias } from 'src/app/shared/interfaces/categorias.type';
import { NzModalService } from 'ng-zorro-antd';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategoriaInsumoService } from 'src/app/shared/services/categoria-insumo.service';
import { AgregarCategoriaInsumosComponent } from 'src/app/forms/agregar-categoria-insumos/agregar-categoria-insumos.component';

@Component({
  selector: 'app-card-categoria-insumos',
  templateUrl: './card-categoria-insumos.component.html',
  styleUrls: ['./card-categoria-insumos.component.css']
})
export class CardCategoriaInsumosComponent implements OnInit {
  @Input() categorias:Categorias
  constructor(
    private spinner:NgxSpinnerService,
    private router:Router,
    private mensajeComponentService:MensajeComponentService,
    private modalService:NzModalService,
    private categoriaService:CategoriaInsumoService
  ) { }

  ngOnInit() {
  }

   /**
   * @description borrar la categoria de insumos
   * @author Gabriel Baldez
   */
  confirm(): void {
    this.spinner.show();
    this.categoriaService.deleteCategoria(this.categorias).subscribe(res=>{
      console.log(res);
      this.mensajeComponentService.changeMessage("deleteCategorias");             
      this.spinner.hide();
    },err=>{
      this.spinner.hide();      
    });
  }



    /**
   * @description Actualizar una categoria
   * @author Gabriel Baldez
   */  
  editar(): void {

    console.log("editando");
    console.log(this.categorias);

    const modal = this.modalService.create({
        nzContent: AgregarCategoriaInsumosComponent,
        nzFooter:null,        
        nzComponentParams: {
          editarCategoria : this.categorias,          
        }
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){        
        this.mensajeComponentService.changeMessage("updateCategorias");
      }
    });
  }

}
