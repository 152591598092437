import { InsumoComponent } from './../insumo/insumo.component';
import { ComprasService } from 'src/app/shared/services/compras.service';
import { CanActivate } from '@angular/router';
import { Ingrediente } from './../../shared/interfaces/ingredientes.type';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnidadMedidaService } from 'src/app/shared/services/unidad-medida.service';
import { InsumoService } from 'src/app/shared/services/insumo.service';
import { RecetaService } from 'src/app/shared/services/receta.service';
import { SubrecetaService } from 'src/app/shared/services/subreceta.service';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service';
import { AgregarMedidaComponent } from '../agregar-medida/agregar-medida.component';


@Component({
  selector: 'app-form-subreceta',
  templateUrl: './subreceta.component.html',
  styleUrls: ['./subreceta.component.css']
})
export class FormSubrecetaComponent implements OnInit {

  @Input() objetoEditar: any = null;  // Aquí se guarda el objeto a editar (cuando corresponde)

  isNew = true; // True si es un nuevo registro, false si se está editando
  form: FormGroup;
  error = false;
  mensaje = "";
  addUnidadMed;
  success = false;
  medidalist: any[];
  insumolist: any[];
  areaslist: any[];
  subrecetaslist: any[];
  moneda = '';
  listInsumoWithUndMed = [];
  mostrarPlusIngredientes = false;
  // Alertas de cálculo de precio
  faltaPorciones= false;
  faltaDatosIngrediente = false;

  suma_costo_total = 0;
  suma_costo_porcion = 0;


  constructor(
    private modalService: NzModalService,
    private fb: FormBuilder,
    private modal: NzModalRef,
    private spinner: NgxSpinnerService,
    private medida: UnidadMedidaService,
    private insumos: InsumoService,
    private subrecetas: SubrecetaService,
    private compraService: ComprasService,
    private restauranteService: RestaurtantService
  ) { }

  ngOnInit() {


    if (this.objetoEditar) {
      console.log(this.objetoEditar);
      this.isNew = false;

      // Se llenan campos del formulario
      this.form = this.fb.group({
        id: [this.objetoEditar.id, [Validators.required]],
        nombre: [this.objetoEditar.nombre, [Validators.required]],
        porciones: [this.objetoEditar.porciones, [Validators.required]],
        // unidad_medida_id:  [this.objetoEditar.unidad_medida_id,  [Validators.required]],
        // cantidad_preparar: [this.objetoEditar.cantidad_preparar, [Validators.required]],
        procedimiento: [this.objetoEditar.procedimiento, []],
        ingredientes: this.fb.array([]),
      });

      // Insertar ingredientes
      const control = <FormArray>this.form.controls['ingredientes'];
      console.log('this.objetoEditar', this.objetoEditar);
      this.compraService.indexWithUnit().subscribe(res => {
        this.listInsumoWithUndMed = res;
        this.insumolist = res;
        this.objetoEditar.ingredientes.forEach(ingrediente => {
          const objeto = this.insumolist.find(sub => sub.id == ingrediente.insumo_id);

          control.push(this.fb.group({
            cantidad: [ingrediente.cantidad, [Validators.required]],
            porc_merma: [ingrediente.porc_merma],
            unidad_medida_id: [ingrediente.unidad_medida_id, [Validators.required]],
            unidades_medida: [this.getUnidadesMedidasInsumo(ingrediente.insumo_id)],
            // porc_merma_coccion: [0, [Validators.required]],
            costo_insumo: [ingrediente.costo_insumo, [Validators.required]],
            insumo_id: [ingrediente.insumo_id, [Validators.required]],
            total: [null],
            alergenos: [this.getMensajeAlergenos(objeto.alergenos)],
          }));
          // this.selectInsumo(ingrediente.insumo_id);
        });
        this.actualizarTotalInsumoWithEquivalencia();
        // this.getTotalInsumo();
      }, err => {
        console.log(err);
      });




    } else { // Crear nueva subreceta
      this.compraService.indexWithUnit().subscribe(res => {
        this.listInsumoWithUndMed = res;
        this.insumolist = res;
      });
      this.form = this.fb.group({
        nombre: [null, [Validators.required]],
        porciones: [null, [Validators.required]],
        // unidad_medida_id: [null, [Validators.required]],
        // cantidad_preparar: [null, [Validators.required]],
        procedimiento: [null, []],
        ingredientes: this.fb.array([this.crearIngrediente()]), // Insertar ingrediente vacíó
      });



    }



    // Cargar data de los selects
    this.medida.getMedida().subscribe(res => {
      this.medidalist = res;
    });

    this.subrecetas.index().subscribe(res => {
      this.subrecetaslist = res;
    });

    // Obtener la moneda del restaurante
    this.restauranteService.getMoneda(localStorage.getItem('restaurante_seleccionado_id')).subscribe(res => {
      this.moneda = res.moneda;
    })
  }


  crearIngrediente(): FormGroup {
    return this.fb.group({
      cantidad: [null, [Validators.required]],
      porc_merma: [0],
      unidad_medida_id: [null, [Validators.required]],
      unidades_medida: [[]],
      // porc_merma_coccion: [0, [Validators.required]],
      costo_insumo: [null, [Validators.required]],
      insumo_id: [null, [Validators.required]],
      total: [null],
      alergenos: [null],
    })

  }


  agregarItem() {


    const control = <FormArray>this.form.controls['ingredientes'];

    control.push(this.crearIngrediente())

    console.log(this.form.get('ingredientes').value);

    console.log(this.getIngredientes().controls);
  }

  getIngredientes(): FormArray {
    return this.form.get('ingredientes') as FormArray;
  }

  borrarItem(i: number) {
    (<FormArray>this.form.controls['ingredientes']).removeAt(i);
    this.calcularPrecios();
  }

  getUnidadesMedidasInsumo(idInsumo) {
    let insumo = this.listInsumoWithUndMed.find(element => element.id == idInsumo);
    return insumo.unidades_medidas_opciones;
  }

  /**
   * @description METODO QUE PERMITE ASIGNAR EL EL [precio_estimado] AL INPUT DE COSTO CUANDO HAY UN CAMBIO EN EL SELECT.
   * @param ev ID DEL INSUMO.
  */
  selectInsumo(ev) {

    let control_ingredientes = <FormArray>this.form.controls['ingredientes'];
    this.listInsumoWithUndMed.forEach((element, index) => {
      //SETEAR LOS ALERGERNOS


      if (element.id == ev) {
        control_ingredientes.controls.forEach(elemento => {

          console.log(elemento, "INGREDIEEENTEEE");
          if (elemento.value.insumo_id == ev) {
            if (this.objetoEditar) {
              let ingre = this.objetoEditar.ingredientes.find(element => element.id == element.id);
              elemento.get('costo_insumo').setValue(elemento.value.costo_insumo);
            } else {

              elemento.get('costo_insumo').setValue(element.precio_estimado);
            }
            elemento.get('unidades_medida').setValue(element.unidades_medidas_opciones);
            elemento.get('unidad_medida_id').setValue(element.unidad_medida_id);

            // let objeto = this.listInsumoWithUndMed.find(sub => sub.id == element.insumo_id);
            elemento.get('alergenos').setValue(this.getMensajeAlergenos(element.alergenos));

          }
        });
      }

    });

    this.form.value.ingredientes.forEach(element => {

      if (element.insumo_id == "") {
        const modal = this.modalService.create({
          nzContent: InsumoComponent,
          nzFooter: null,
          nzWidth: 1000,
        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
            this.insumos.getInsumos().subscribe(res => {
              this.insumolist = res;
            });
          }
        });

      }

    });

    console.log(control_ingredientes);

  }


  calcularPrecios() {
    console.log('calculando...');

    if (this.form.value.porciones) {
      this.faltaPorciones = false;
    } else {
      this.faltaPorciones = true;
    }


    console.log(this.form.value.ingredientes);


    if (this.form.value.ingredientes) {
      console.log('hay ingredientes');


      this.form.value.ingredientes.forEach((ingrediente, index) => {

        if (ingrediente.cantidad === null || ingrediente.costo_insumo === null || ingrediente.porc_merma === null) {
          this.faltaDatosIngrediente = true;
        } else {
          this.faltaDatosIngrediente = false;
        }


      });
    }


    this.suma_costo_total = this.suma_costo_porcion = 0;

    if (!this.faltaPorciones && !this.faltaDatosIngrediente) {

      const porciones = this.form.value.porciones;
      console.log('calculando..');

      // this.form.value.ingredientes.forEach((ingrediente, index) => {

      //   const porc_usado = 100 - ingrediente.porc_merma;   // Ej 100 - 5
      //   const porc_requerido = 100 / porc_usado;  // Ej 100/95 = 1.05

      //   const cantidad_real = porc_requerido * ingrediente.cantidad;  // 1.05 * CANTIDAD



      //   // let costo_ingrediente = cantidad_real * ingrediente.costo_insumo;
      //   const costo_ingrediente = cantidad_real * ingrediente.total;
      //   this.suma_costo_total += costo_ingrediente;



      // });


      this.form.value.ingredientes.forEach((ingrediente, index) => {
        let sum = 0;
        // let porc_usado = 0;

        // porc_usado = 100 - ingrediente.porc_merma;   // Ej 100 - 5
        sum += ingrediente.cantidad * ingrediente.costo_insumo;
        // sum += ingrediente.total;

        if (ingrediente.porc_merma && ingrediente.porc_merma != 0) {
          let sumaMerma = 0;

          // sumaMerma = sum * ingrediente.porc_merma / porc_usado;
          // sumaMerma = (ingrediente.porc_merma / 100) * ingrediente.cantidad;
          sumaMerma = ingrediente.porc_merma * sum / 100;

          sum = sum + sumaMerma;
        }

        console.log(sum);
        this.suma_costo_total += sum;
      });

      this.suma_costo_total = Math.round(this.suma_costo_total * 100) / 100; // redondear suma_costo_total


      this.suma_costo_porcion = this.suma_costo_total / porciones;
      this.suma_costo_porcion = Math.round(this.suma_costo_porcion * 100) / 100; // // redondear suma_costo_porcion

    } else {
      console.log('no se calcula, faltan datos');
    }
    this.getTotalInsumo();







  }

  submitForm(): void {
    console.log('enviar');
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }


    for (let i of this.getIngredientes().controls) {

      for (let a in i['controls']) {
        console.log(a);
        i['controls'][a].markAsDirty();
        i['controls'][a].updateValueAndValidity();
      }
    }

    let payload = {};
    if (this.form.valid) {
      this.spinner.show();

      if (!this.isNew) {
        payload['id'] = this.form.value.id;
      }

      payload['nombre'] = this.form.value.nombre;
      payload['porciones'] = this.form.value.porciones;
      payload['unidad_medida_id'] = this.form.value.unidad_medida_id;
      payload['cantidad_preparar'] = this.form.value.cantidad_preparar;
      payload['procedimiento'] = this.form.value.procedimiento;
      payload['restaurante_id'] = localStorage.getItem("restaurante_seleccionado_id");
      payload['ingredientes'] = this.form.value.ingredientes;
      console.log(this.form.value);


      let servicio = this.isNew ? this.subrecetas.post(payload) : this.subrecetas.put(payload);

      servicio.subscribe(resSubReceta => {

        console.log("respuesta de recetas", resSubReceta);

        this.subrecetaslist = resSubReceta;
        console.log(resSubReceta, "recibi datos ")
        this.modal.destroy({ data: true });
        this.spinner.hide();

      }, err => {
        console.log(err);
        // this.mensaje="Ocurrio un problema";
        this.mensaje = err.error.errors;
        this.error = true;
        console.log(this.mensaje)
        this.spinner.hide();
      });


    }


  }



  // actualizarTotalInsumoWithEquivalencia(event=null,items=null){
  //   console.log(items);
  //   //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL SELECCIONAR UNA UND DE MEDIDA DISTINTA A LA PRINCIPAL MENDIANTE SELECT UNIDAD
  //   if(items&&event){
  //     let objeto = this.insumolist.find(element => element.id == items.value.insumo_id);
  //     objeto.unidades_medidas_opciones.forEach(element => {
  //       if(element.id==event){
  //         items.get('total').setValue((items.value.cantidad/element.equivalencia_unidad_principal)*items.value.costo_insumo);
  //       }
  //     });
  //   }

  //   //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL CAMBIAR LA CANTIDAD DEL INSUMO
  //   if(!items&&!event){
  //     let  controls = <FormArray>this.form.controls['ingredientes'];
  //     controls.controls.forEach(elemento => {
  //       let objeto = this.insumolist.find(element => element.id == elemento.value.insumo_id);
  //       objeto.unidades_medidas_opciones.forEach(element => {
  //         if(element.id==elemento.value.unidad_medida_id){
  //           // items.get('total').setValue((items.value.cantidad/element.equivalencia_unidad_principal)*items.value.precio_unitario);
  //           elemento.get('total').setValue(elemento.value.cantidad/element.equivalencia_unidad_principal*elemento.value.costo_insumo);
  //         }
  //       });

  //         // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
  //     });
  //   }

  //   this.calcularPrecios();
  // }

  actualizarTotalInsumoWithEquivalencia(event = null, items = null) {
    const controls = <FormArray>this.form.controls['ingredientes'];
    if (event != null && items != null) {
      // ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL SELECCIONAR UNA UND DE MEDIDA DISTINTA A LA PRINCIPAL MENDIANTE SELECT UNIDAD
      if (items && event) {
        const objeto = this.insumolist.find(element => element.id == items.value.insumo_id);
        objeto.unidades_medidas_opciones.forEach(element => {
          if (element.id == event) {
            element.equivalencia_unidad_principal == 1 ? items.get('costo_insumo').setValue(objeto.precio_estimado) : items.get('costo_insumo').setValue(objeto.precio_estimado / element.equivalencia_unidad_principal);
            items.get('total').setValue(items.value.cantidad * items.value.costo_insumo);
          }
        });
      }

      // ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL CAMBIAR LA CANTIDAD DEL INSUMO
      if (!items && !event) {
        controls.controls.forEach(elemento => {
          const objeto = this.insumolist.find(element => element.id == elemento.value.insumo_id);
          objeto.unidades_medidas_opciones.forEach(element => {
            if (element.id == elemento.value.unidad_medida_id) {
              element.equivalencia_unidad_principal == 1 ? elemento.get('costo_insumo').setValue(objeto.precio_estimado) : elemento.get('costo_insumo').setValue(objeto.precio_estimado / element.equivalencia_unidad_principal);
              elemento.get('total').setValue(elemento.value.cantidad * elemento.value.costo_insumo);
            }
          });

          // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
        });
      }
    }

    // const controls = <FormArray>this.form.controls['ingredientes'];
    controls.controls.forEach(elemento => {

      elemento.get('total').setValue(elemento.value.cantidad * elemento.value.costo_insumo);


    });

    this.calcularPrecios();

  }




  getMensajeAlergenos(alergenos) {
    const nombreAlergenos = [];
    alergenos.forEach(element => {
      nombreAlergenos.push(element.nombre);
    });
    if (nombreAlergenos.length) {
      return 'Contiene alérgenos: ' + nombreAlergenos;
    } else {
      return '';
    }
  }


  getTotalInsumo() {
    console.log('HOLAAAAAAAAAAAA');
    let sum = 0;
    this.form.value.ingredientes.forEach((ingrediente, index) => {
      console.log(ingrediente);





      sum = 0;
      // let porc_usado = 0;

      // porc_usado = 100 - ingrediente.porc_merma;   // Ej 100 - 5
      // sum += ingrediente.cantidad * ingrediente.costo_insumo;
      sum += ingrediente.cantidad * ingrediente.costo_insumo;

      if (ingrediente.porc_merma && ingrediente.porc_merma !== 0) {
        let sumaMerma = 0;

        sumaMerma = ingrediente.porc_merma * sum / 100;

        sum +=  sumaMerma;
      }
      // if (ingrediente.porc_merma_coccion && ingrediente.porc_merma_coccion != 0) {
      //   let sumaMerma;

      //   sumaMerma = sum * ingrediente.porc_merma_coccion / (100 - ingrediente.porc_merma_coccion);

      //   sum = sum + sumaMerma;
      // }
      // SETEAR VALOR AL TOTAL
      console.log(sum);
      const controls = <FormArray>this.form.controls['ingredientes'];
      console.log(controls.controls[0]);
      controls.controls[index].get('total').setValue(Math.round(sum * 100) / 100);

    });
  }

}
