import { UnidadMedida } from './unidad-medida.type';
import { Restaurant } from './restaurant.type';
import { IngredientesModel } from './ingredientes.type';
export interface ISubRecetas  {
    id?: number;
    nombre?: string; 
    cantidad_preparar?: number; 
    unidad_medida_id?: UnidadMedida;               
    porciones?: number;  
    procedimiento?: string; 
    restaurante_id?:Restaurant;
    ingredientes?:Array<IngredientesModel>; 
    recetas_count?: number; // cantidad de recetas asociadas
}


export class SubRecetasModel implements ISubRecetas {
    constructor(
       public id?: number,
       public nombre?: string, 
       public cantidad_preparar?: number, 
       public unidad_medida_id?: UnidadMedida,               
       public porciones?: number, 
       public procedimiento?: string, 
       public restaurante_id?:Restaurant,
       public ingredientes?:Array<IngredientesModel>,
       public recetas_count?: number
    ){}
}