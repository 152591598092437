import { InsumoService } from 'src/app/shared/services/insumo.service';
import { Component, OnInit } from '@angular/core';
import { AlmacenService } from 'src/app/shared/services/almacen.service';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service';
import { FormDestinoComponent } from 'src/app/forms/destino/destino.component';
import { NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-inventarios',
  templateUrl: './inventarios.component.html',
  styleUrls: ['./inventarios.component.css']
})
export class InventariosComponent implements OnInit {
  almaceneslist: any;
  addAlmacen;
  success = false;
  mensaje = "";
  inventarioList=[];
  moneda: String = "";

  constructor(
    private almacenService: AlmacenService,
    private modalService: NzModalService,
    private insumoService:InsumoService,
    private restauranteService:RestaurtantService,
  ) { }

  ngOnInit() {
    this.listarAlmacenes();
    this.cargarMoneda();

  }

  ionViewDidLeave() {
    this.addAlmacen="";
  }

  listarAlmacenes() {
    this.almacenService.index().subscribe(res => {
      this.almaceneslist = res;
      console.log(this.almaceneslist, "almacenes o destinos aqui")
    })
    this.addAlmacen="todos";
    this.nuevoAlmacen(this.addAlmacen);
  }

  /**
   * @description Obtener la moneda del restaurante
   */
  cargarMoneda(){
    this.restauranteService.getMoneda(localStorage.getItem('restaurante_seleccionado_id')).subscribe(res => {
      this.moneda = res.moneda;
    })
  }

  nuevoAlmacen(almacen) {
    if (almacen == "") {
      const modal = this.modalService.create({
        nzContent: FormDestinoComponent,
        nzFooter: null,

      });
      modal.afterClose.subscribe((result) => {
        console.log('[afterClose] The result is:', result)
        if (result) {
          this.listarAlmacenes();
        }
      });

    }else if (almacen=="todos"){
      this.insumoService.reportes_insumo().subscribe(res=>{
        console.log(res);
        this.inventarioList=res;
      },err=>{
        console.log(err);
      });
    }else{
      this.insumoService.reportes_insumo(almacen).subscribe(res=>{
        console.log(res);
        this.inventarioList=res;
      },err=>{
        console.log(err);
      });
    }
  }

}
