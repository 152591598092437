import { WizardPasswordComponent } from '../../forms/wizard-password/wizard-password.component';
import { WizardRestaurantComponent } from '../../forms/wizard-restaurant/wizard-restaurant.component';
import { NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {
  constructor(private router: Router,
    private modalService:NzModalService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      if (user && user.is_admin) {
          if(user.restaurantes && user.restaurantes.length==0){
            this.createComponentModal(WizardRestaurantComponent);
          }
          if(!user.pass_updated){
            this.createComponentModal(WizardPasswordComponent);
          }
  
          return true;
      }
     // this.router.navigate(['/welcome']);
      // return true;
      
  }
  

  createComponentModal(componente): void {
    const modal = this.modalService.create({
        nzContent: componente,
        nzFooter:null,          
        nzClosable:false,
        nzMaskClosable:false,
        nzKeyboard:false
    });
    // modal.afterClose.subscribe((result) => {            
    // });
  }
}
