import { Component, OnInit, Input } from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { FormBuilder,FormGroup,Validators  } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnidadMedidaService } from 'src/app/shared/services/unidad-medida.service';

@Component({
  selector: 'app-agregar-medida',
  templateUrl: './agregar-medida.component.html',
  styleUrls: ['./agregar-medida.component.css']
})

export class AgregarMedidaComponent implements OnInit {

  @Input() objetoEditar: any = null;  // Si se recibe un restaurante está en modo edición, sino modo crear

  success=false;
  mensaje="";
  medidaForm: FormGroup;
  error=false; 
  constructor(
    private modalService:NzModalService,
    private fb: FormBuilder,
    private modal: NzModalRef,
    private spinner:NgxSpinnerService,
    private medidas:UnidadMedidaService,    
  ) {
    this.medidaForm = this.fb.group({
      nombre: [ null, [ Validators.required ] ],
    });
   }

  ngOnInit() {
    if(this.objetoEditar){
      console.log("editando", this.objetoEditar)
      this.medidaForm.get('nombre').setValue(this.objetoEditar.nombre);
    }
    else
      console.log("nueva und de medida");
  }

  /**
   *@description METODO QUE ENVIA EL REGISTRO 
  */
 submitForm(): void {
  console.log('enviar');
  for (const i in this.medidaForm.controls) {
    this.medidaForm.controls[ i ].markAsDirty();
    this.medidaForm.controls[ i ].updateValueAndValidity();
  }
  let payload = {};
  if(true){
    this.spinner.show();
 
    payload['nombre'] = this.medidaForm.value.nombre;
    payload['restaurante_id'] = localStorage.getItem("restaurante_seleccionado_id");

    
    console.log(payload);

    // Editar unidad de medida
    if(this.objetoEditar){

        payload['id'] = this.objetoEditar.id;

        this.medidas.update(payload).subscribe(res =>{
          console.log(res);
          this.modal.destroy({ data: true });  
          this.spinner.hide();
        }, err =>{
          // this.mensaje="Ocurrio un problema."
          this.mensaje = err.error.errors
          this.error=true;
          this.spinner.hide();
          console.log(err);
        });
    }else{
        // Añadir unidad de medida
        this.medidas.registroMedida(payload).subscribe(res =>{
          console.log(res);
          this.modal.destroy({ data: true });  
          this.spinner.hide();
        }, err =>{
          // this.mensaje="Ocurrio un problema."
          this.mensaje = err.error.errors
          this.error=true;
          this.spinner.hide();
          console.log(err);
        });
    }

  }

 
}

}
