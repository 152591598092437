import { formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { GastoService } from './../../shared/services/gasto.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-gasto-form',
  templateUrl: './gasto-form.component.html',
  styleUrls: ['./gasto-form.component.css']
})
export class GastoFormComponent implements OnInit {
  @Input() tipo;
  @Input() data;
  form: FormGroup;
  loading = true;
  mensaje = "";
  constructor(
    private fb: FormBuilder,
    private gs: GastoService,
    private spinner: NgxSpinnerService,
    private modal: NzModalRef,
  ) {
    this.loading = true;
  }

  ngOnInit() {    
    this.loading = true;   
    this.form = this.fb.group({
      id: [this.data ? this.data.id : null],
      nombre: [this.data ? this.data.nombre : null, [Validators.required]],
      tipo: [this.tipo],
      monto: [this.data ? this.data.monto : null, [Validators.required, Validators.min(1)]],
      fecha: [{value: this.data ? this.data.fecha : null, disabled: this.tipo === 'empleado' ? true : false}, Validators.required],
      cantidad: [this.data ? this.data.cantidad : 1, [Validators.required, Validators.min(1)]],
      restaurante_id: [localStorage.getItem('restaurante_seleccionado_id')]
    });
    setTimeout(() => {
      this.loading = false;      
    }, 1000);
  }

  /**
   * @description Crear o editar restaurante
   */
  submit(): void {
    console.log('enviar');
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    // let payload = {};
    console.log(this.form);
    if (this.form.valid) {
      if(this.form.value.fecha){
        let fecha = formatDate(this.form.value.fecha, 'yyyy-MM-dd ', 'en');      
        this.form.get('fecha').setValue(fecha);
      }
      this.spinner.show();
      let service = this.data ? this.gs.put(this.form.value) : this.gs.store(this.form.value)
      service.subscribe(res => {
        console.log(res);
        this.modal.destroy({ data: true });
        this.spinner.hide();
      },err=>{
        console.log(err);
        if(err.error){
          if(err.error.errors)
            this.mensaje = err.error.errors;
          if(err.error.message)
            this.mensaje = err.error.message;
        }        
        // console.log(this.mensaje, )
        this.spinner.hide();
      });
    }


  }
  




}
