import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { rutaApi } from '../util/constants';

@Injectable({
  providedIn: 'root'
})
export class DevolucionService {

  constructor(private http: HttpClient) { }


 
   /**
  * 
  * @description Obtener devoluciones
  * @author Carlos diaz
  * @returns Observable<any> 
  */
 public index(): Observable<any> {
  let rest = localStorage.getItem('restaurante_seleccionado_id');
  return this.http.get<any>(rutaApi('/devolucion_compra?restaurante_id='+rest+''));
  }

   /**
  * 
  * @description Obtener devoluciones by id
  * @author Carlos diaz
  * @returns Observable<any> 
  */
 public get(id): Observable<any> {  
  return this.http.get<any>(rutaApi('/devolucion_compra/'+id+''));
  }

   /**
  * 
  * @description registra devolucion de una compra
  * @author Carlos diaz
  * @returns Observable<any> 
  */
 public create(object): Observable<any> {
  let rest = localStorage.getItem('restaurante_seleccionado_id');
  return this.http.post<any>(rutaApi('/devolucion_compra'),object);
  }

   /**
  * 
  * @description edita devolucion de una compra
  * @author Carlos diaz
  * @returns Observable<any> 
  */
 public edit(object): Observable<any> {
  
  return this.http.put<any>(rutaApi('/devolucion_compra/'+object.id+''),object);
  }


  
   /**
  * 
  * @description anula devolucion de una compra
  * @author Carlos diaz
  * @returns Observable<any> 
  */
 public anular(object): Observable<any> {
  
  return this.http.post<any>(rutaApi('/devolucion_compra/'+object.id+'/anular'),object);
  }
}
