import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DevolucionService } from './../../shared/services/devolucion.service';
import { DevolucionComponent } from './../../forms/devolucion/devolucion.component';
import { NzModalService } from 'ng-zorro-antd';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-devolucion',
  templateUrl: './card-devolucion.component.html',
  styleUrls: ['./card-devolucion.component.css']
})
export class CardDevolucionComponent implements OnInit {
  @Input() devolucion : any
  constructor(   
     private modalService:NzModalService,
     private devolucionService:DevolucionService,
     private spinner:NgxSpinnerService,
     private mensajeComponentService:MensajeComponentService
     ) { }

  ngOnInit() {
  }


   /**
   * @description Actualizar una subreceta
   * @author Javier Evans
   */  
  editar(): void {
    const modal = this.modalService.create({
        nzContent: DevolucionComponent,
        nzFooter: null,
        nzWidth:900,
        nzComponentParams: {
          objetoEditar : this.devolucion,
        }

    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){        
        this.mensajeComponentService.changeMessage("updateDevoluciones");
      }
    });
  }


   /**
   * @description Anular una devolucion compra
   * @author Carlos diaz
   */
  anular(): void {
    this.spinner.show();
    this.devolucionService.anular(this.devolucion).subscribe(res=>{
      console.log(res);
      this.mensajeComponentService.changeMessage("updateDevoluciones");             
      this.spinner.hide();
    },err=>{
      this.spinner.hide();      
    });
  }

}
