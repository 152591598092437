
import { Component, OnInit,Input } from '@angular/core';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
// Importaciones asociadas al modelo
import { FormRecetaComponent } from 'src/app/forms/receta/receta.component'; // Formulario (modal)
import { RecetaService } from 'src/app/shared/services/receta.service';                    // Servicio (conexión api)
import { RecetasModel } from './../../shared/interfaces/recetas.type';


@Component({
  selector: 'app-card-recetas',
  templateUrl: './card-recetas.component.html',
  styleUrls: ['./card-recetas.component.css']
})
export class CardRecetasComponent implements OnInit {

  @Input() receta: RecetasModel;
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private mensajeComponentService : MensajeComponentService,
    private modalService:NzModalService,
    private rs : RecetaService
  ) { }

  ngOnInit() {


  }

  /**
   * @description Actualizar una subreceta
   * @author Javier Evans
   */
  editar(): void {
    const modal = this.modalService.create({
        nzContent: FormRecetaComponent,
        nzFooter: null,
        nzWidth:1100,
        nzComponentParams: {
          objetoEditar : this.receta,
        }

    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){
        this.mensajeComponentService.changeMessage("updateRecetas");
      }
    });
  }

   /**
   * @description
   * @author Javier Evans
   */
  delete(): void {
    this.spinner.show();
    this.rs.delete(this.receta).subscribe(res=>{
      this.mensajeComponentService.changeMessage("updateRecetas");
      this.spinner.hide();
    },err=>{
      console.log(err);
      this.spinner.hide();
    });
  }



  descargarPdf(){
    let fileName = 'Receta '+this.receta.nombre;
    console.log("DAsds")
    this.rs.download(this.receta.id).subscribe(res=>{
      const blob = new Blob([res], { type : 'application/pdf' });
      const file = new File([blob], fileName + '.pdf', { type: 'application/pdf' });
      saveAs(file);
    },err=>{
      console.log(err);
    });

  }
}
