import { Component, OnInit } from '@angular/core';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenStorage } from 'src/app/shared/util/token.storage';
import { HttpClient } from '@angular/common/http';
import { FormSubrecetaComponent } from 'src/app/forms/subreceta/subreceta.component';
import { SubrecetaService } from 'src/app/shared/services/subreceta.service';

@Component({
  selector: 'app-subrecetas',
  templateUrl: './subrecetas.component.html',
  styleUrls: ['./subrecetas.component.css']
})
export class SubrecetasComponent implements OnInit {
  success = false;
  mensaje = "";
  error=false;
  banView:boolean=false;
  subrecetaslist:any[];
  subrecetaslistaux:any[];
  constructor(
    private modalService: NzModalService,
    //private modal: NzModalRef,
    private mensajeService:MensajeComponentService,
    private spinner:NgxSpinnerService,
    private tokenStorage:TokenStorage,
    private http: HttpClient,
     private msg: NzMessageService,
     private subrecetaService:SubrecetaService
  ) {
    this.mensajeService.currentMessage.subscribe(message => {
      switch (message) {


        case 'updateSubrecetas':
          this.listarSubRecetas();
          break;
        default:
          break;
      }
    });
   }

  ngOnInit() {
    this.listarSubRecetas();
  }


  nuevaSubReceta(): void {
    const modal = this.modalService.create({
      nzContent: FormSubrecetaComponent,
      nzFooter: null,
      nzWidth:1100,
      nzMaskClosable:false
    });

    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
         this.listarSubRecetas();

      }
    });
  }

  listarSubRecetas(){
    this.banView=false;
    this.subrecetaService.index().subscribe(res=>{

      console.log(res,"recetas");

      this.subrecetaslist=res;
      this.subrecetaslistaux=res;
      this.banView=true;
      // this.spinner.hide();

    },err=>{
      this.banView=true;
      this.mensaje="Ocurrio un problema."
      // this.spinner.hide();
      console.log(err);
    });
  }




  inputBusqueda="";
  filtrar(){
    let that=this;
    if(this.inputBusqueda==""){
      this.subrecetaslist=this.subrecetaslistaux;
    }else{
      this.subrecetaslist=this.subrecetaslistaux.filter(function (element) {
        if(element.nombre.toLowerCase().includes(that.inputBusqueda.toLowerCase()))
          return true;
        else
          return false;
      });
      console.log(this.subrecetaslist);
    }

  }
}
