import { FotoService } from './../../shared/services/foto.service';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { AgregarUsuarioComponent } from '../../forms/agregar-usuario/agregar-usuario.component';
import { NzModalService } from 'ng-zorro-antd';
import { MensajeComponentService } from './../../shared/services/mensaje-component.service';
import { User } from 'src/app/shared/interfaces/user.type';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-profile',
  templateUrl: './card-profile.component.html',
  styleUrls: ['./card-profile.component.css']
})
export class CardProfileComponent implements OnInit {

  @Input() user: User;


  constructor(
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private router: Router,
    private mensajeComponentService: MensajeComponentService,
    private modalService: NzModalService,
    private authService: AuthenticationService,
    public fotoService: FotoService

  ) {

  }

  ngOnInit() {
    // console.log(this.router.url,"nueva ruta");
  }


  confirm(): void {
    this.spinner.show();
    this.userService.deleteUser(this.user).subscribe(res => {
      console.log(res);
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      if (err.status === 200) {
        this.mensajeComponentService.changeMessage('updateListUser');
        // this.mensajeComponentService.changeMessage("updateListUser");
      }
    });
  }

  editarUsuario() {

    let modo;
    if (this.authService.currentUserValue.is_admin) {
      modo = 'admin';
    } else if (this.authService.currentUserValue.is_superadmin) {
      modo = 'super';
    }
    const modal = this.modalService.create({
      nzContent: AgregarUsuarioComponent,
      nzFooter: null,
      nzComponentParams: {
        modo: modo,
        user: this.user,
      }
    });
    modal.afterClose.subscribe((result) => {
      // console.log('[afterClose] The result is:', result)
      if (result) {
        this.mensajeComponentService.changeMessage('updateListUser');
      }
    });

  }
  /**
   *@description METODO QUE ABRE LA MODAL Y MUESTRA EL FORMULARIO.
  */

  subirFoto({ file, fileList }) {
    this.spinner.show();
    const status = file.status;
    console.log(status);
    console.log(file);
    if (status !== 'uploading') {
      console.log(file.thumbUrl);

      this.getBase64(file.originFileObj);
    }

  }
  getBase64(file) {
    const that = this;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {

      const payloads = {};
      payloads['imageable_id'] = that.user.id;
      payloads['foto'] = reader.result;
      payloads['columna'] = 'foto';
      payloads['imageable_type'] = 'App\\User';
      that.fotoService.create(payloads).subscribe(res => {
        console.log(res);
        that.user['foto'] = res;
        that.spinner.hide();
      }, err => {
        console.log(err);
        that.spinner.hide();
      });
      // console.log(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      that.spinner.hide();
    };
  }
}
