import { ChartsModule } from 'ng2-charts';
import { SharedModule } from './../shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpcionMenuComponent } from 'src/app/components/opcion-menu/opcion-menu.component';
import { CardProfileComponent } from './card-profile/card-profile.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SpinnerComponent } from './spinner/spinner.component';
import { PanelLateralComponent } from './panel-lateral/panel-lateral.component';
import { CardInfoComponent } from './card-info/card-info.component';
import { CardProveedorComponent } from './card-proveedor/card-proveedor.component';
import { CardMedidaComponent } from './card-medida/card-medida.component';
import { CardDestinoComponent } from './card-destino/card-destino.component';
import { CardRestauranteComponent } from './card-restaurante/card-restaurante.component';
import { CardCategoriaInsumosComponent } from './card-categoria-insumos/card-categoria-insumos.component';
import { CardMenuComponent } from './card-menu/card-menu.component';
import { CardRecetasComponent } from './card-recetas/card-recetas.component';
import { CardAreaRecetaComponent } from './card-area-receta/card-area-receta.component';
import { CardSubrecetaComponent } from './card-subreceta/card-subreceta.component';
import { CardComprasComponent } from './card-compras/card-compras.component';
import { CardDevolucionComponent } from './card-devolucion/card-devolucion.component';
import { CardEquivalenciaComponent } from './card-equivalencia/card-equivalencia.component';
import { CardVentasComponent } from './card-ventas/card-ventas.component';
import { NgxMaskModule } from 'ngx-mask';
import { ErrorsComponent } from './errors/errors.component';
import { GraficaBarrasComponent } from './grafica-barras/grafica-barras.component';
import { GraficaLineasComponent } from './grafica-lineas/grafica-lineas.component';
import { GraficaDonaComponent } from './grafica-dona/grafica-dona.component';
import { ExpireSessionComponent } from './expire-session/expire-session.component';
import { CardAlergenosComponent } from './card-alergenos/card-alergenos.component';
import { PedidoComponent } from './pedido/pedido.component';
import { GraficaMateriaPrimaComponent } from './grafica-materia-prima/grafica-materia-prima.component';
import { IngenieriaMenuComponent } from './ingenieria-menu/ingenieria-menu.component';
import { GastosComponent } from './gastos/gastos.component';


@NgModule({
  declarations: [
    OpcionMenuComponent,
    CardProfileComponent,
    SpinnerComponent,
    PanelLateralComponent,
    CardInfoComponent,
    CardProveedorComponent,
    CardMedidaComponent,
    CardDestinoComponent,
    CardRestauranteComponent,
    CardCategoriaInsumosComponent,
    CardMenuComponent,
    CardRecetasComponent,
    CardAreaRecetaComponent,
    CardSubrecetaComponent,
    CardComprasComponent,
    CardDevolucionComponent,
    CardEquivalenciaComponent,
    CardVentasComponent,
    ErrorsComponent,
    GraficaBarrasComponent,
    GraficaLineasComponent,
    GraficaDonaComponent,
    ExpireSessionComponent,
    CardAlergenosComponent,
    PedidoComponent,
    GraficaMateriaPrimaComponent,
    IngenieriaMenuComponent,
    GastosComponent,



  ],

  imports: [
    CommonModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    SharedModule,
    NgxMaskModule,
    ChartsModule,

  ],

  exports: [
    OpcionMenuComponent,
    CardProfileComponent,
    PanelLateralComponent,
    CardInfoComponent,
    CardProveedorComponent,
    CardMedidaComponent,
    CardDestinoComponent,
    SpinnerComponent,
    CardMenuComponent,
    CardRestauranteComponent,
    CardCategoriaInsumosComponent,
    CardRecetasComponent,
    CardAreaRecetaComponent,
    CardSubrecetaComponent,
    CardComprasComponent,
    CardDevolucionComponent,
    CardEquivalenciaComponent,
    CardVentasComponent,
    ErrorsComponent,
    GraficaBarrasComponent,
    GraficaLineasComponent,
    GraficaDonaComponent,
    ExpireSessionComponent,
    CardAlergenosComponent,
    PedidoComponent,
    GraficaMateriaPrimaComponent,
    IngenieriaMenuComponent,
    GastosComponent

  ],

  entryComponents: [
    OpcionMenuComponent,
    ErrorsComponent,
    ExpireSessionComponent

  ]
})
export class ComponentsModule { }
