import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { MenuRoutingModule } from './menu-routing.module';
import { ThemeConstantService } from '../shared/services/theme-constant.service';
import { ComponentsModule } from '../components/components.module';

import { MenuAdminComponent } from 'src/app/menu/menu-admin/menu-admin.component';
import { MenuSuperAdminComponent } from 'src/app/menu/menu-super-admin/menu-super-admin.component';

@NgModule({
  
  imports: [
    SharedModule,
    CommonModule,
    ComponentsModule,
    MenuRoutingModule,
  ],
  
  declarations: [
    MenuAdminComponent,
    MenuSuperAdminComponent,
  ],

  providers: [
    ThemeConstantService
  ],


})
export class MenuModule { }
