import { TokenStorage } from './../../shared/util/token.storage';
import { element } from 'protractor';
import { InsumoComponent } from '../../forms/insumo/insumo.component';
import { Component, OnInit } from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { CategoriaInsumoService } from 'src/app/shared/services/categoria-insumo.service';
import { InsumoService } from 'src/app/shared/services/insumo.service';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { rutaApi } from '../../shared/util/constants';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';

import { NzMessageService } from 'ng-zorro-antd/message';
import { UploadFile } from 'ng-zorro-antd/upload';
import { filter } from 'rxjs/operators';
import { AgregarCategoriaInsumosComponent } from 'src/app/forms/agregar-categoria-insumos/agregar-categoria-insumos.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-insumos',
  templateUrl: './insumos.component.html',
  styleUrls: ['./insumos.component.css']
})
export class InsumosComponent implements OnInit {

  expand: boolean = true;
  categoria_seleccionada_id = 'todos';
  success = false;
  mensaje :any= "";
  error=false;
  fileList1: any[];
  categoriaslistAux: any[];
  categoriaslist: any[];
  insumoslist: any[];
  moneda: String = "";
  auxi = [];
  banView:boolean=false;
  uploading = false;
  fileList: UploadFile[] = []; // Excel a cargar


  constructor(
    private modalService: NzModalService,
    private categorias: CategoriaInsumoService,
    private insumos: InsumoService,
    //private modal: NzModalRef,
    private mensajeService:MensajeComponentService,
    private spinner:NgxSpinnerService,
    private tokenStorage:TokenStorage,
    private restauranteService:RestaurtantService,

    private http: HttpClient, private msg: NzMessageService
  ) {
    this.mensajeService.changeMessage("/pages/insumos");
    this.mensajeService.currentMessage.subscribe(message => {
      switch (message) {
        case 'updateListInsumo':
          this.cargarInsumos();
        break;

        case 'deleteInsumo':
          this.cargarInsumos();
          break;
        default:
          break;
      }
    });

  }

  ngOnInit() {
    this.cargarCategorias();
    this.cargarInsumos();
    this.cargarMoneda();
  }

  /**
   * @description Obtener la moneda del restaurante
   */
  cargarMoneda(){    
    this.restauranteService.getMoneda(localStorage.getItem('restaurante_seleccionado_id')).subscribe(res => {
      this.moneda = res.moneda;

      var that = this;
      this.insumoslist =this.insumoslist.map(insumo => {
        insumo.moneda = that.moneda;
        return insumo;
      });
    })
  }

  cargarCategorias(){
    this.categorias.getCategoria().subscribe(res => {

      this.categoriaslistAux = res;
      this.categoriaslist=this.categoriaslistAux.filter(res=>{
        return res.categoria_insumos_padre_id==null;
      });
      this.banView=true;
    }, err => {
      console.log(err);
      this.banView=true;
    });
  }
  setMediaUploadHeaders = (file: UploadFile) => {
    return {
      "Accept": "application/json",
      "Authorization": "Bearer "+this.tokenStorage.getToken(),
    }
  };
  cargarInsumos(){
    this.insumos.getInsumos().subscribe(res => {

      console.log(res, "insumos registrados");
      this.insumoslist = res;
    }, err => {

      console.log(err);
    });
  }
  inputBusqueda="";
  insumosFiltrados(busqueda=null) {
    let lista;

    if(this.inputBusqueda){
      let that=this;
      this.categoria_seleccionada_id="todos";
      this.subcategoriaslist=[];
     lista = this.insumoslist.filter(function (insumo) {
        if(insumo.referencia.toLowerCase().includes(that.inputBusqueda.toLowerCase())||insumo.codigo_barras.toLowerCase().includes(that.inputBusqueda.toLowerCase()))
          return true;
        else
          return false;
      });
    }else{
      let that = this;
      if (this.categoria_seleccionada_id == 'todos') {
        lista= this.insumoslist
      } else {
        lista = this.insumoslist.filter(function (insumo) {

          if(insumo.categoria_insumos_id==that.categoria_seleccionada_id||(insumo.categoria_insumos.categoria_insumos_padre&&insumo.categoria_insumos.categoria_insumos_padre.id==that.categoria_seleccionada_id))
            return true;
          else
            return false;
        });
      }

    }
   return lista;
  }

//OBETENER LA CATEGORIA DADO EL ID DESDE EL INSUMO
//VALIDAR QUE LA CATEGORIA QUE TENGO TENGA PADRE SI TIENE PADRE DEBO TAMBIEN FILTRAR LOS INSUMOS POR LA CATEGORIA PADRE ID

  nuevoInsumo(): void {
    const modal = this.modalService.create({
      nzContent: InsumoComponent,
      nzFooter: null,
      nzWidth: 800,



    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.cargarInsumos();
        this.mensaje = "Registro exitoso.";
        this.success = true;
      }
    });
  }

  nuevaCategoria(): void {
    const modal = this.modalService.create({
      nzContent: AgregarCategoriaInsumosComponent,
      nzFooter: null,

    });

    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.cargarCategorias();
        this.mensaje = "Registro exitoso.";
        this.success = true;
      }
    });
  }


  abrir() {
    this.expand = false;
  }

  cerrar() {
    this.expand = true;
  }
  rutaSubirExcel(){
    return rutaApi('/insumo/cargar_excel?restaurante_id=' + localStorage.getItem("restaurante_seleccionado_id"));
  }

  /** Manejar los mensajes cuando se està cargando un archivo **/
  handleChange({ file, fileList }): void {

    console.log("change", file);

    const status = file.status;

    switch (status){
      case "uploading":
        this.success = false;
        this.error = false;
        this.spinner.show();
      break;

      case "done":
        this.mensaje="Importación exitosa."
        this.cargarCategorias();
        this.cargarInsumos();
        this.success = true;
        this.spinner.hide();
        break;
      case "error":
        console.log(file);
        let error_file = file.error.error.errors.file;
        let errores_validacion = [];

        if(error_file){
          this.mensaje = "Ocurrio un problema: " + error_file;
        }
        else{
          this.mensaje = "Ocurrio un problema";

          let errors = file.error.error.errors;

          if(errors.length){

            errores_validacion.push( "El archivo cargado contiene errores: \n\n" );

            errors.forEach(element => {
              errores_validacion.push( "Columna " + element.row + ": " + element.errors.shift() );
            });
            this.mensaje =  errores_validacion
          }
        }

        this.error = true;
        this.spinner.hide();

        // if(errores_validacion.length)
        //   setTimeout(()=>{
        //     alert( errores_validacion.join("\n") );
        //   }, 500)

      break;
    }




  }




  subirExcel(){
    let payload = {};

    this.spinner.show();

    payload['file'] = "";
    payload['restaurante_id'] = localStorage.getItem("restaurante_seleccionado_id");


    console.log(payload);

    this.insumos.registroExcel(payload).subscribe(res =>{
      console.log("registroexcel", res);
      this.spinner.hide();

    }, err =>{
      // this.mensaje="Ocurrio un problema."
      this.mensaje = err.error.errors
      this.error=true;
      this.spinner.hide();
      console.log(err);
    });

    }


    subcategoriaslist=[];
    categoriasChange(categoriaId){
      this.subcategoriaslist=this.categoriaslistAux.filter(res=>{
        // console.log(res);
        return res.categoria_insumos_padre_id==categoriaId;
      });
    }


    fileName = 'Insumos';
    exportar(){
      this.insumos.exportar().subscribe(res=>{
        // var file = new Blob([res], {type: 'application/xlxs'});
        // var fileURL = URL.createObjectURL(file);

        // Open new windows and show PDF


        const blob = new Blob([res], { type : 'application/xlxs' });
        const file = new File([blob], this.fileName + '.xlsx', { type: 'application/xlxs' });
        saveAs(file);
      },err=>{
        // console.log(err);
      });

    }




}
