import { Restaurant } from './../../shared/interfaces/restaurant.type';
import { Component, OnInit, Input } from '@angular/core';
import { WizardRestaurantComponent } from 'src/app/forms/wizard-restaurant/wizard-restaurant.component';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service';
import { MensajeComponentService } from './../../shared/services/mensaje-component.service';

@Component({
  selector: 'app-card-restaurante',
  templateUrl: './card-restaurante.component.html',
  styleUrls: ['./card-restaurante.component.css']
})
export class CardRestauranteComponent implements OnInit {
 
  @Input() restaurante:Restaurant

  constructor(
    private modalService:NzModalService,
    private restauranteService:RestaurtantService,
    private mensajeComponentService:MensajeComponentService,
  ) { }

  ngOnInit() {
  }


  
  editar(): void {

    console.log("editando");
    console.log(this.restaurante);

    const modal = this.modalService.create({
        nzContent: WizardRestaurantComponent,
        nzFooter:null,        
        nzComponentParams: {
          restaurante : this.restaurante,          
        }
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){
        console.log("restaurante actualiado");
        this.mensajeComponentService.changeMessage("updateRestaurantes");
      }
    });
  }
 

}
