import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { rutaApi } from '../util/constants';

@Injectable({
  providedIn: 'root'
})
export class FotoService {

  constructor(private http: HttpClient) { }

    /**
   * 
   * @description obtiene los insumos
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public create(payload): Observable<any>{    
    return this.http.post<any>(rutaApi('/foto'),payload);
  }

}
