import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnidadMedidaService } from 'src/app/shared/services/unidad-medida.service';
import { Util } from 'src/app/shared/util/util';
import { UnidadEquivalenteService } from 'src/app/shared/services/unidad-equivalente.service';
import { AgregarMedidaComponent } from '../agregar-medida/agregar-medida.component';

@Component({
  selector: 'app-unidad-equivalente',
  templateUrl: './unidad-equivalente.component.html',
  styleUrls: ['./unidad-equivalente.component.css']
})
export class UnidadEquivalenteComponent implements OnInit {
  equivalenteForm: FormGroup;
  unidadeslist: any[];
  error: boolean = false;
  mensaje: string = "";
  equivalenteslist: any;
  @Input() objetoEditar: any = null;  // Aquí se guarda el objeto a editar (cuando corresponde)
  isNew: boolean = true; // True si se está creando un registro, false si se está editando
  addUnidadMed;
  addUnidadMed2;
  success=false;
  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private modal: NzModalRef,
    private spinner: NgxSpinnerService,
    private unidades: UnidadMedidaService,
    private util: Util,
    private equivalenteService: UnidadEquivalenteService
  ) {
    this.equivalenteForm = this.fb.group({
      unidad_medida_id: [null, [Validators.required]],
      cantidad: [null, [Validators.required, Validators.min(1)]],
      unidad_medida_id2: [null, [Validators.required]],
      cantidad2: [null, [Validators.required, Validators.min(1)]],
    });
  }

  ngOnInit() {

    if (this.objetoEditar) {
      this.isNew = false;
      console.log("editando", this.objetoEditar)
      this.equivalenteForm.get('unidad_medida_id').setValue(this.objetoEditar.unidad_medida_a_id);
      this.equivalenteForm.get("cantidad").setValue(this.objetoEditar.unidad_medida_a_cant);
      this.equivalenteForm.get('unidad_medida_id2').setValue(this.objetoEditar.unidad_medida_b_id);
      this.equivalenteForm.get("cantidad2").setValue(this.objetoEditar.unidad_medida_b_cant);
    }

    // this.listarUnidadesEqui();
    this.listarUnidadesMed();


  }


  nuevoUnidad(unidad): void {
    if (unidad == 1) {
      const modal = this.modalService.create({
        nzContent: AgregarMedidaComponent,
        nzFooter: null,

      });
      modal.afterClose.subscribe((result) => {
        this.addUnidadMed = ""
        console.log('[afterClose] The result is:', result)
        if (result) {
         
          this.mensaje = "Registro exitoso.";
          this.success = true;
          this.addUnidadMed = ""
        }
      });
    }

    


  }

  nuevoUnidadMed(unidad): void {
    if (unidad == 1) {
      const modal = this.modalService.create({
        nzContent: AgregarMedidaComponent,
        nzFooter: null,

      });
      modal.afterClose.subscribe((result) => {
        this.addUnidadMed2 = ""
        console.log('[afterClose] The result is:', result)
        if (result) {

          this.mensaje = "Registro exitoso.";
          this.success = true;
          this.addUnidadMed2 = ""
        }
      });
    }
  }




  submitForm(): void {
    console.log("AQUIII", this.equivalenteForm.get("unidades_equivalentes") as FormArray);
    for (const i in this.equivalenteForm.controls) {
      this.equivalenteForm.controls[i].markAsDirty();
      this.equivalenteForm.controls[i].updateValueAndValidity();
    }


    if (this.equivalenteForm.valid) {
      let payload
      if (!this.isNew) { // Al editar, agregar id del objeto al payload
        payload = {
          id:this.objetoEditar.id,
          unidades_equivalentes: [
            {


              unidad_medida_id: this.equivalenteForm.value.unidad_medida_id,
              cantidad: this.equivalenteForm.value.cantidad
            },
            {
              unidad_medida_id: this.equivalenteForm.value.unidad_medida_id2,
              cantidad: this.equivalenteForm.value.cantidad2
            }
          ]
        }
      } else {
        payload = {
          unidades_equivalentes: [
            {


              unidad_medida_id: this.equivalenteForm.value.unidad_medida_id,
              cantidad: this.equivalenteForm.value.cantidad
            },
            {
              unidad_medida_id: this.equivalenteForm.value.unidad_medida_id2,
              cantidad: this.equivalenteForm.value.cantidad2
            }
          ]
        }

      }
      console.log(payload, "asi se manda")
      this.spinner.show();
      let servicio = this.isNew ? this.equivalenteService.crearUnidadEquivalente(payload) : this.equivalenteService.editarUniEquivalente(payload)
      servicio.subscribe(res => {
        this.modal.destroy({ data: true });
        this.spinner.hide();
      }, err => {
        
        this.mensaje = err.error.errors
        this.error = true;
        this.spinner.hide();
       
        // err.error.errors.forEach(element => {
        //   console.log(element);
        // });
      });





    }

  }

  listarUnidadesMed() {

    this.unidades.getMedida().subscribe(res => {
      console.log(res, "unidades de medidas");
      this.unidadeslist = res;
    });
  }

  // listarUnidadesEqui(){

  //   this.equivalenteService.getUniEquivalentes().subscribe(res => {
  //     console.log(res, "unidades de equivalencia");
  //     this.equivalenteslist = res;
  //   });
  // }



}
