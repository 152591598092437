import { Component, OnInit } from '@angular/core';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenStorage } from 'src/app/shared/util/token.storage';
import { HttpClient } from '@angular/common/http';
import { FormMenuComponent } from 'src/app/forms/menu/menu.component';
import { MenuService } from 'src/app/shared/services/menu.service';

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.css']
})
export class MenusComponent implements OnInit {
  success = false;
  mensaje = "";
  error = false; 
  banView : boolean = false;
  menusList : any[];
  menusListaux : any[];
  constructor(
    private modalService: NzModalService,
   
    //private modal: NzModalRef,
    private mensajeService:MensajeComponentService,
    private spinner:NgxSpinnerService,
    private tokenStorage:TokenStorage,
    private menuService:MenuService,


    private http: HttpClient,
     private msg: NzMessageService
  ) {
    this.mensajeService.currentMessage.subscribe(message => {
      switch (message) {
          case 'updateMenus':          
          this.listar();
          break;
        default:
          break;
      }
    });

   }

  ngOnInit() {
   this.listar();
  }


  nueva(): void {
    const modal = this.modalService.create({
      nzContent: FormMenuComponent,
      nzFooter: null,
      nzWidth:900,
      nzMaskClosable:false



    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
         this.listar();
        this.mensaje = "Registro exitoso.";
        this.success = true;
      }
    });
  }

  listar(){
    this.banView=false;
    this.menuService.index().subscribe(res=>{
      this.menusList = res;
      this.menusListaux=res;
      this.banView=true;
      // this.spinner.hide(); 
      
    },err=>{
      this.banView=true;
      this.mensaje="Ocurrio un problema."      
      // this.spinner.hide();
      console.log(err);
    });
  }



  inputBusqueda="";
  filtrar(){
    let that=this;    
    if(this.inputBusqueda==""){
      this.menusList=this.menusListaux;
    }else{
      this.menusList=this.menusListaux.filter(function (element) {            
        if(element.nombre.toLowerCase().includes(that.inputBusqueda.toLowerCase()))  
          return true;
        else
          return false;
      });
      
    }
    
  }
}
