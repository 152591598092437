import { SugerenciasComponent } from './sugerencias/sugerencias.component';

import { InventariosComponent } from './inventarios/inventarios.component';
import { DevolucionesComponent } from './devoluciones/devoluciones.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guard/auth.guard';
import { ProveedoresComponent } from './proveedores/proveedores.component';
import { InsumosComponent } from './insumos/insumos.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { MenusComponent } from './menus/menus.component';
import { RecetasComponent } from './recetas/recetas.component';
import { SubrecetasComponent } from './subrecetas/subrecetas.component';
import { ComprasComponent } from './compras/compras.component';
import { VentasComponent } from './ventas/ventas.component';
import { MovimientosComponent } from './movimientos/movimientos.component';




const routes: Routes = [


  {
    path: 'proveedores',
    component: ProveedoresComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Proveedores'
    },

  },
  {
    path: 'insumos',
    component: InsumosComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Insumos'
    },

  },
  {
    path: 'menus',
    component: MenusComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Menus'
    },

  },
  {
    path: 'recetas',
    component: RecetasComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Recetas'
    },

  },

  {
    path: 'subrecetas',
    component: SubrecetasComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'SubRecetas'
    },

  },

  {
    path: 'compras',
    component: ComprasComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Compras'
    },

  },
  {
    path: 'ventas',
    component: VentasComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Ventas'
    },

  },

  {
    path: 'devoluciones',
    component: DevolucionesComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Devoluciones'
    },

  },
  {
    path: 'configuraciones',
    component: ConfiguracionComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Configuración'
    },

  },
  {
    path: 'inventarios',
    component: InventariosComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Inventarios'
    },

  },
  {
    path: 'movimientos',
    component: MovimientosComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Movimientos'
    },

  },
  {
    path: 'sugerencias',
    component: SugerenciasComponent,
    // canActivate: [AuthGuard],
    data: {
      title: 'Sugerencias'
    },

  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
