
import { Component, OnInit,Input } from '@angular/core';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';

// Importaciones asociadas al modelo
import { FormSubrecetaComponent } from 'src/app/forms/subreceta/subreceta.component'; // Formulario (modal)
import { SubrecetaService } from 'src/app/shared/services/subreceta.service';                    // Servicio (conexión api)
import { SubRecetasModel } from './../../shared/interfaces/subrecetas.type';                     //


@Component({
  selector: 'app-card-subreceta',
  templateUrl: './card-subreceta.component.html',
  styleUrls: ['./card-subreceta.component.css']
})
export class CardSubrecetaComponent implements OnInit {

  @Input() subreceta: SubRecetasModel;   // Objeto a crear o modificar

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private mensajeComponentService : MensajeComponentService,
    private modalService:NzModalService,
    private servicio : SubrecetaService
  ) { }

  ngOnInit() {
  }



  /**
   * @description Actualizar una subreceta
   * @author Javier Evans
   */
  editar(): void {

    const modal = this.modalService.create({
        nzContent: FormSubrecetaComponent,
        nzFooter: null,
        nzWidth:1100,
        nzComponentParams: {
          objetoEditar : this.subreceta,
        }

    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){
        this.mensajeComponentService.changeMessage("updateSubrecetas");
      }
    });
  }



  /**
   * @description
   * @author Javier Evans
   */
  delete(): void {
    this.spinner.show();
    this.servicio.delete(this.subreceta).subscribe(res=>{
      this.mensajeComponentService.changeMessage("updateSubrecetas");
      this.spinner.hide();
    },err=>{
      console.log(err);
      this.spinner.hide();
    });
  }

}
