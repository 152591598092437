import { UnidadMedida } from './../../shared/interfaces/unidad-medida.type';
import { Component, OnInit,Input } from '@angular/core';
import { UnidadMedidaService } from 'src/app/shared/services/unidad-medida.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { AgregarMedidaComponent } from 'src/app/forms/agregar-medida/agregar-medida.component';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'app-card-medida',
  templateUrl: './card-medida.component.html',
  styleUrls: ['./card-medida.component.css']
})
export class CardMedidaComponent implements OnInit {
  
  @Input() medida : UnidadMedida
  
  constructor(
    private spinner:NgxSpinnerService,
    private unidades:UnidadMedidaService,
    private router:Router,
    private mensajeComponentService:MensajeComponentService,
    private modalService:NzModalService,
  ) { }

  ngOnInit() {
  }

  /**
   * @description borrar la unidad de medida
   * @author Javier Evans
   */
  confirm(): void {
    this.spinner.show();
    this.unidades.deleteMedidas(this.medida).subscribe(res=>{
      console.log(res);
      this.mensajeComponentService.changeMessage("deleteUnidadesMedidas");             
      this.spinner.hide();
    },err=>{
      this.spinner.hide();      
    });
  }


  /**
   * @description Actualizar una unidad de medida
   * @author Javier Evans
   */  
  editar(): void {

    console.log("editando");
    console.log(this.medida);

    const modal = this.modalService.create({
        nzContent: AgregarMedidaComponent,
        nzFooter:null,        
        nzComponentParams: {
          objetoEditar : this.medida,          
        }
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){        
        this.mensajeComponentService.changeMessage("updateUnidadesMedidas");
      }
    });
  }



}
