import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rutaApi } from '../util/constants';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AreaRecetaService {

  constructor(private http: HttpClient) { }

  /**
   * 
   * @description obtiene las medidas
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public getArea(): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/area_receta?restaurante_id=')+rest);
  }

  /**
   * 
   * @description registra las medidas
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public registro(object): Observable<any>{
    return this.http.post<any>(rutaApi('/area_receta'),object);
  }

  /**
   * 
   * @description Actualizar Unidad de Medida
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public update(object): Observable<any>{
    return this.http.put<any>(rutaApi('/area_receta/' + object.id ), object);
  }

   /**   
   * @description Eliminar unidades de medidas
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public delete(object): Observable<Array<any>>{
    return this.http.delete<any>(rutaApi('/area_receta/'+object.id+''));
  }


}
