import { InsumoService } from './../../shared/services/insumo.service';
import { Component, OnInit,Input } from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/shared/services/user.service';
import { ProveedorService } from 'src/app/shared/services/proveedor.service';
@Component({
  selector: 'app-proveedor',
  templateUrl: './proveedor.component.html',
  styleUrls: ['./proveedor.component.css']
})
export class ProveedorComponent implements OnInit {
  success=false;
  mensaje="";
  proveedorForm: FormGroup;
  error=false;

  proveedoreslist:any=[];
  listaServicios=[];
  serviciosProveedores:any;
  @Input() proveedor: any = null; //VARIABLE PARA SABER SI SE VA A EDITAR UN PROVEEDOR

  constructor(

    private modalService:NzModalService,
    private fb: FormBuilder,
    private modal: NzModalRef,
    private spinner:NgxSpinnerService,

    private Proveedor:ProveedorService,
    private is: InsumoService,
  ) {
    this.proveedorForm = this.fb.group({

      nombre: [ null, [ Validators.required ] ],
      domicilio: [ null, [ Validators.required ] ],
      telefono: [ null, [ Validators.required ] ],
      correo: [ null, [ Validators.required, Validators.pattern('^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)(\.[A-Za-z]{2,})$') ] ],
      tiempo: [ null, [ Validators.required ] ],
      insumos: [ [], [ Validators.required ] ],
    });
   }

  ngOnInit() {

    this.is.getInsumos().subscribe(res=>{
      console.log(res);
      this.listaServicios=res;
    },err=>{
      console.log(err);
    });

    if (this.proveedor) {
      console.log("editando estee", this.proveedor)
      this.proveedorForm.get("nombre").setValue(this.proveedor.nombre);
      this.proveedorForm.get("domicilio").setValue(this.proveedor.domicilio);
      this.proveedorForm.get("telefono").setValue(this.proveedor.telefono);
      this.proveedorForm.get("correo").setValue(this.proveedor.email);
      this.proveedorForm.get("tiempo").setValue(this.proveedor.tiempo_entrega);
      this.proveedorForm.get("insumos").setValue(this.proveedor.insumos_ids);

    }
  }



    /**
   *@description METODO QUE ENVIA EL REGISTRO DEL FORMULARIO DE PROVEEDORES.
  */
 submitForm(): void {
  if (this.proveedor==null) {
     console.log('enviar');
  for (const i in this.proveedorForm.controls) {
    this.proveedorForm.controls[ i ].markAsDirty();
    this.proveedorForm.controls[ i ].updateValueAndValidity();
  }
  let payload = {};
  if(this.proveedorForm.valid){
    this.spinner.show();

    payload['nombre'] = this.proveedorForm.value.nombre;
    payload['domicilio'] = this.proveedorForm.value.domicilio;
    payload['telefono'] = ""+this.proveedorForm.value.telefono;
    payload['email'] = this.proveedorForm.value.correo;
    payload['tiempo_entrega'] = this.proveedorForm.value.tiempo;
    payload['insumos_ids']=this.proveedorForm.value.insumos
    payload['restaurante_id'] = localStorage.getItem("restaurante_seleccionado_id");

    console.log(payload);



    this.Proveedor.registroProveedor(payload).subscribe(res =>{
      this.modal.destroy({ data: true });
      this.spinner.hide();

    }, err =>{
      this.mensaje = err.error.errors;
      this.error=true;
      this.spinner.hide();
      console.log(err);
    });

  }
  } else if (this.proveedor!=null){
    console.log('enviar la edicion');
  for (const i in this.proveedorForm.controls) {
    this.proveedorForm.controls[ i ].markAsDirty();
    this.proveedorForm.controls[ i ].updateValueAndValidity();
  }
  let payload = {};
  if(this.proveedorForm.valid){
    this.spinner.show();
    payload['id'] = this.proveedor.id;
    payload['nombre'] = this.proveedorForm.value.nombre;
    payload['domicilio'] = this.proveedorForm.value.domicilio;
    payload['telefono'] = ""+this.proveedorForm.value.telefono;
    payload['email'] = this.proveedorForm.value.correo;
    payload['tiempo_entrega'] = this.proveedorForm.value.tiempo;
    payload['insumos_ids']=this.proveedorForm.value.insumos
    payload['restaurante_id'] = localStorage.getItem("restaurante_seleccionado_id");

    console.log(payload);



    this.Proveedor.editarProveedor(payload).subscribe(res =>{
      this.modal.destroy({ data: true });
      this.spinner.hide();

    }, err =>{
      this.mensaje = err.error.errors;
      this.error=true;
      this.spinner.hide();
      console.log(err);
    });

  }
  }



}

}
