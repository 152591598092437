import { AreaReceta } from '../../shared/interfaces/area-receta.type';
import { IngredientesModel } from '../../shared/interfaces/ingredientes.type';
import { RecetasModel } from '../../shared/interfaces/recetas.type';
import { Component, OnInit, Input } from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnidadMedidaService } from 'src/app/shared/services/unidad-medida.service';
import { InsumoService } from 'src/app/shared/services/insumo.service';
import { MenuService } from 'src/app/shared/services/menu.service';
import { RecetaService } from 'src/app/shared/services/receta.service';
import { AreaRecetaService } from 'src/app/shared/services/area-receta.service';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service';
import { FormRecetaComponent } from '../receta/receta.component';

@Component({
  selector: 'app-form-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class FormMenuComponent implements OnInit {

  @Input() objetoEditar: any = null;  // Aquí se guarda el objeto a editar (cuando corresponde)

  isNew: boolean = true; // True si es un nuevo registro, false si se está editando
  form: FormGroup;
  error = false;
  mensaje="";
  success=false;
  recetasList:any[];
  moneda: String = "";


  constructor(
    private modalService: NzModalService,
    private fb: FormBuilder,
    private modal: NzModalRef,
    private spinner: NgxSpinnerService,
    private menuService: MenuService,
    private recetaService:RecetaService,
    private restauranteService:RestaurtantService,

  ) {




  }







  ngOnInit() {


    if(this.objetoEditar){
      this.isNew = false;

      // Se llenan campos del formulario
        this.form = this.fb.group({
          id:                [this.objetoEditar.id,                [Validators.required]],
          nombre:            [this.objetoEditar.nombre,            [Validators.required]],


          costo:             [this.objetoEditar.costo,             [Validators.required]],
          porc_materia_prima:[this.objetoEditar.porc_materia_prima,[Validators.required,Validators.min(1)]],
          factor_multiplicador : [0],
          valor_venta : [0],


          porc_impuestos:    [this.objetoEditar.porc_impuestos,    [Validators.required]],
          porc_servicios:    [this.objetoEditar.porc_servicios,    [Validators.required]],
          precio_venta:      [this.objetoEditar.precio_venta,      [Validators.required]],


          recetas: this.fb.array([])
        });


        console.log(this.objetoEditar);
        this.recetaService.indexWithPrice().subscribe(res => {
          console.log(res, "recetas con precios");
          this.recetasList = res;

          let control_recetas = <FormArray>this.form.controls['recetas'];
          this.objetoEditar.recetas.forEach(receta => {
            let objeto = this.recetasList.find(sub => sub.id == receta.id);
            control_recetas.push(this.fb.group({
              id:[receta.id, [Validators.required]],
              cantidad: [receta.pivot.cantidad, [Validators.required, Validators.min(0.1)]],
              alergenos:[this.getMensajeAlergenos(objeto.alergenos)],
            }));
          });
          this.calcularPrecios();
        });
      // Insertar recetas



    }else{ // Crear nuevo menu
      this.form = this.fb.group({

        nombre: [null, [Validators.required]],

        costo: [0, [Validators.required]],
        porc_materia_prima: [0, [Validators.required,Validators.min(1)]],
        factor_multiplicador : [0],
        valor_venta : [0],


        porc_impuestos: [0, [Validators.required]],
        porc_servicios: [0, [Validators.required]],
        precio_venta: [0, [Validators.required]],


        recetas: this.fb.array([this.crearReceta()])
      });
      this.recetaService.indexWithPrice().subscribe(res => {
        console.log(res, "recetas con precios");
        this.recetasList = res;

        this.calcularPrecios();
      });
    }


    // Obtener la moneda del restaurante
    this.restauranteService.getMoneda(localStorage.getItem('restaurante_seleccionado_id')).subscribe(res => {
      this.moneda = res.moneda;
    })
  }

  agregarItemReceta() {

    let control = <FormArray>this.form.controls['recetas'];

    control.push(this.crearReceta())

    console.log(this.form.get("recetas").value);

    console.log(this.getRecetas().controls);
  }


  getRecetas(): FormArray {
    return this.form.get("recetas") as FormArray;
  }


  crearReceta(): FormGroup {
    return this.fb.group({
      id:[null, [Validators.required]],
      cantidad: [null, [Validators.required]],
      alergenos: [null],
    })


  }


  filtrarArray(Arreglo, valor) {

    return Arreglo.filter(item => item !== valor);
  }


  borrarReceta(i: number) {
    (<FormArray>this.form.controls['recetas']).removeAt(i);
  }



  calcularPrecios(){
    console.log(this.form.value.recetas,"que hay aqui")

    this.form.value.recetas.forEach((element,index) => {
      let objeto = this.recetasList.find(sub => sub.id == element.id);
      let control = <FormArray>this.form.controls['recetas'];
      if(objeto)
        control.controls[index].get('alergenos').setValue(this.getMensajeAlergenos(objeto.alergenos));

      if (element.id=="") {

        const modal = this.modalService.create({
          nzContent: FormRecetaComponent,
          nzFooter: null,
          nzWidth: 900
        });
        modal.afterClose.subscribe((result) => {
          console.log('[afterClose] The result is:', result)
          if (result) {
            this.recetaService.indexWithPrice().subscribe(res => {
              console.log(res, "recetas con precios");
              this.recetasList = res;
            });
          }
        });
      }

    });


    console.log("calculando...");

    if(this.form.value.recetas){
      console.log("hay recetas");

      let suma_costo = 0;

      this.form.value.recetas.forEach((receta, index) => {

        if(receta.id && receta.cantidad){
          let recetaObj = this.recetasList.filter(rec => rec.id == receta.id ).shift();

          suma_costo += recetaObj.costo_total_porcion*receta.cantidad;

        }

      });


      suma_costo = this.redondea(suma_costo); // Redondear a 2 decimales

      //Asignar valor a costo
      this.form.get("costo").setValue(suma_costo);

      // Si se definiò porc_materia_prima calcular factor multiplicador y precio venta sugerido
      if( this.form.value.porc_materia_prima ){
        let factor = 100 / this.form.value.porc_materia_prima;

        this.form.get("factor_multiplicador").setValue( this.redondea(factor));


        // Valor de venta = factor_multiplicador * costo
        let valor_venta = factor * suma_costo;
        valor_venta = this.redondea(valor_venta);

        this.form.get("valor_venta").setValue(valor_venta);


        let impuestos = valor_venta * this.form.value.porc_impuestos/100;

        let servicio  = valor_venta * this.form.value.porc_servicios/100;

        let precio_venta = valor_venta + impuestos + servicio;

        this.form.get('precio_venta').setValue( this.redondea(precio_venta) );

      }



    }

  }

  redondea(number){
    return parseFloat(Number.parseFloat(""+number).toFixed(2))
  }

  submitForm(): void {
    console.log('enviar');
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    for (let i of this.getRecetas().controls) {

      for (let a in i['controls']) {
        console.log(a);
        i['controls'][a].markAsDirty();
        i['controls'][a].updateValueAndValidity();
      }
    }

    let payload = {};
    if (this.form.valid) {
      this.spinner.show();

      if (!this.isNew) {
        payload['id'] = this.form.value.id;
      }

      payload['nombre'] = this.form.value.nombre;
      payload['restaurante_id'] = localStorage.getItem("restaurante_seleccionado_id");
      payload['porc_materia_prima'] = this.form.value.porc_materia_prima;
      payload['porc_impuestos'] = this.form.value.porc_impuestos;
      payload['porc_servicios'] = this.form.value.porc_servicios;
      payload['precio_venta'] = this.form.value.precio_venta;
      payload['costo'] = this.form.value.costo;

      payload['recetas'] =  this.form.value.recetas;

      let servicio = this.isNew ? this.menuService.post(payload) : this.menuService.put(payload);

      servicio.subscribe(res =>{
        this.modal.destroy({ data: true });
        this.spinner.hide();

      },err=>{
        this.mensaje = err.error.errors;
        this.error=true;
        this.spinner.hide();
      });

    }


  }



  getMensajeAlergenos(alergenos){
    let nombreAlergenos=[];
    alergenos.forEach(element => {
      nombreAlergenos.push(element.nombre)
    });
    if(nombreAlergenos.length)
      return "Contiene alérgenos: "+nombreAlergenos;
    else
      return "";
  }

}
