import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rutaApi } from '../util/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MovimientosService {

  constructor(private http: HttpClient) { }
  /**
   * 
   * @description obtiene los movimientos
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public getMovimientos(): Observable<any> {
    let rest = localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/transaccion_insumos?restaurante_id=') + rest);
  }

  /**
* 
* @description registrar la transaccion
* @author Gabriel Baldez
* @returns Observable<any> 
*/
  public post(object): Observable<any> {
    return this.http.post<any>(rutaApi('/transaccion_insumos'), object);
  }



  
  /**
* 
* @description 
* @author 
* @returns Observable<any> 
*/
public get(object): Observable<any> {
  return this.http.get<any>(rutaApi('/transaccion_insumos/'+object.id));
}
}
