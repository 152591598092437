import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.css']
})
export class ErrorsComponent implements OnInit {
  @Input() errors:any;
  listErr=[];
  cerrarAlert:boolean=false
  constructor() { }

  ngOnInit() {
    console.log(this.errors,"ERRRR");
    if(typeof this.errors === "string" ){
      //MENSAJES DEL FRONT END
      this.listErr.push(this.errors);      
    }else{  
      //MENSAJES DEL BACKEND
      for (let indice in this.errors) {
        if(Array.isArray(this.errors[indice])){//CONDICION SE AGREGA POR COMO RECIBE EL MENSAJE DE ERROR EN EL LOGIN
          this.listErr.push(this.errors[indice][0]);      
        }else{
          this.listErr.push(this.errors[indice]);      
        }
      }
    }
    
    console.log("HOLaaaa",this.listErr)
  }

  cerrar(){
    this.cerrarAlert=true
  }

}
