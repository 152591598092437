import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { AgregarUsuarioComponent } from '../../forms/agregar-usuario/agregar-usuario.component';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';
import { UserService } from 'src/app/shared/services/user.service';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';

@Component({
  selector: 'app-gestion-admin',
  templateUrl: './gestion-admin.component.html',
  styleUrls: ['./gestion-admin.component.css']
})
export class GestionAdminComponent implements OnInit {
  memberList=[];
  success=false;
  mensaje="";
  banView=false;
  constructor(
    private router: Router,
    private modalService:NzModalService,
    private user:UserService,
    public authService:AuthenticationService,
    private mensajeService:MensajeComponentService
    
    ) { 
      
      this.mensajeService.currentMessage.subscribe(message => {
        switch (message) {
  
  
          case 'updateListUser':
            this.listarUsuarios();
            break;
          default:
            break;
        }
      });
    

  }

  ngOnInit() {    

    this.mensajeService.changeMessage("/gestion-usuario/gestion-admin");
    this.listarUsuarios();

  }



  
    /**
   * @description 
   * @param ev [Opcion del menu]
   */
  redireccion(ev){
    this.router.navigate([ev]);
  }




  createComponentModal(): void {
    const modal = this.modalService.create({
        nzContent: AgregarUsuarioComponent,
        nzFooter:null,
        nzComponentParams:{
          modo:"admin"
        }    
    });
    
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){
        this.listarUsuarios();
        this.mensaje="Registro exitoso.";
        this.success=true;
      }
    });
  
}


listarUsuarios(){
  this.banView=false;
  this.memberList=[];
  this.user.obtenerUser().subscribe(res=>{
    this.banView=true;
    this.memberList = res;
    console.log(this.memberList,"miembros");

  },err=>{
    console.log(err);
  });
}

}
