import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class MensajeComponentService {
  //PARA SUBSCRIBIRME A LOS MENSAJES CUANDO LLEGUE UNA NOTIFICACION PUSH
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();//SUCRIBIRSE A ESTE METODO, ES QUIEN DEVUELVE EL MENSAJE ENVIADO
  //FIN

  constructor(
    private http: HttpClient
  ) { }

  /**
   * @description metodo para subscribirse desde los componentes. cuando llegue una notificacion hacer la magia, dependiendo el caso!
   * @param message 
   */
  changeMessage(message: string) {
    this.messageSource.next(message)
  }



}
