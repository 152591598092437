
import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
// import * as firebase from 'firebase/app';


import { environment } from 'src/environments/environment.prod';

@Injectable({
    providedIn: 'root'
})

export class Util {
    //rooms = [];//CHATS ROOMS
    ref;
    service;
    geocoder;
    loading = null;
    error;
    constructor(

        public router: Router,



        private zone: NgZone,

    ) {



    }



    /**
     * Crear nombre para la imagen tomada
     */
    static createFileName() {
        var d = new Date(),
            n = d.getTime(),
            newFileName = n + ".jpg";
        return newFileName;
    }










    /**
     * 
     * @param Arreglo Array al cual se le removera el item
     * @param valor Item para ser eliminado del array
     * @returns Array con el iten Valor enviado filtrado
     */
    public filtrarArray(Arreglo, valor) {

        return Arreglo.filter(item => item !== valor);
    }

    /**
     * @description Agregar un item a un array si no existe en el mismo
     * @param Arreglo Array al que se le desea agregar un item
     * @param valor valor que se agregara al arrays si no existe en el mismo
     * @returns Arreglo con el item agregado de no existir.
     */
    public agregarItemInArrysSiNoExist(Arreglo, valor) {
        Arreglo.indexOf(valor) === -1 ? Arreglo.push(valor) : console.log("Este item ya existe");
        return Arreglo;
    }




 


    /**
     * @description actualizar al usuario logeuado guardado en localstorage despues de una calificacion etc...
     */
    updateUserStorage(): Promise<any> {
        /* return new Promise(Resolve => {
 
             let that = this;
             this.userService.getUserById().subscribe(res => {
                 localStorage.setItem('user', JSON.stringify(res));
                 Resolve(true);
                 // this.appComponent.enableOptionMenu();
             }, err => {
                 if (err.status == 401) {
                     var a = setInterval(function () {
                         that.userService.getUserById().subscribe(res => {
                             localStorage.setItem('user', JSON.stringify(res));
                             //                     that.appComponent.enableOptionMenu();
                             Resolve(true);
                         });
                         clearInterval(a);
                     }, 5000);
                 }
             });
         });*/
        return null;
    }

        
    /**
     * @description devuelve "true" si hay usuario authenticado "false" si no lo hay
     */
    // isAuthOrRefresh(token): Promise<boolean> {
    //     if (!firebase.apps.length) {
    //         firebase.initializeApp(environment.firebaseConfig);
    //     }
    //     return new Promise(Resolve => {
    //         firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
    //             token.saveToken(idToken).then((R) => {
    //                 Resolve(true);
    //             }).catch((r) => {
    //                 Resolve(false);
    //             });

    //         }).catch((r) => {
    //             Resolve(false);
    //         });
    //     });
    // }



}   