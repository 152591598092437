import { WizardRestaurantComponent } from 'src/app/forms/wizard-restaurant/wizard-restaurant.component';
import { WizardPasswordComponent } from './../../forms/wizard-password/wizard-password.component';
import { NzModalService } from 'ng-zorro-antd';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private modalService: NzModalService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this.auth.currentUserValue && user && (state.url === '/welcome' || state.url === '/login' || state.url === '/')) {
      // FALTA AGREGAR EL RETORNO DE LOS DASHBOARD A LOS EMPLEADOS
      if (this.auth.currentUserValue.is_superadmin) {
        this.router.navigate(['/menu/menu-super-admin']);
      }
      if (this.auth.currentUserValue.is_admin) {
        this.router.navigate(['/menu/menu-admin']);
      }
      if (this.auth.currentUserValue.is_empleado1 || this.auth.currentUserValue.is_empleado2) {
        this.router.navigate(['/dashboard/dashboard']);
      }
      return false;
    } else if (user && (user.is_empleado1 || user.is_empleado2)) {
      if (!user.pass_updated) {
        this.createComponentModal(WizardPasswordComponent);
        return true;
      }
      return true;
    } else if (user && user.is_admin) {
      if (user.restaurantes && user.restaurantes.length === 0) {
        this.createComponentModal(WizardRestaurantComponent);
      }
      if (!user.pass_updated) {
        this.createComponentModal(WizardPasswordComponent);
      }

      return true;
    } else if (!user && (state.url === '/welcome' || state.url === '/login' || state.url === '/')) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }


  }
  createComponentModal(componente): void {
    const modal = this.modalService.create({
      nzContent: componente,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false
    });
  }
}
