import { MensajeComponentService } from './../../shared/services/mensaje-component.service';
import { NzModalRef } from 'ng-zorro-antd';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expire-session',
  templateUrl: './expire-session.component.html',
  styleUrls: ['./expire-session.component.css']
})
export class ExpireSessionComponent implements OnInit {

  constructor(    
    private modal:NzModalRef,    
    private mcs: MensajeComponentService
    ) { 
      this.mcs.currentMessage.subscribe(message => {
        if(message === 'closeExpireSessionModal'){
            this.modal.close();
        }        
      });
    }

  ngOnInit() {
  }


  loading=false;
  handleOk(): void {
    this.loading=true;
    this.mcs.changeMessage('refreshTokenExpired');       
  }

  handleCancel(): void {
    // this.authService.logout();
    this.mcs.changeMessage('logout');
    this.modal.destroy({ data: true }); 
  }
}
