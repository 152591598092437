import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(
    private colorConfig: ThemeConstantService,
  ) { }

  ngOnInit() {
   
  }


  // VARIABLES DE LAS GRAFICAS 
  themeColors = this.colorConfig.get().colors;
  blue = this.themeColors.blue; 
  verdeATM="#50D166"  ;
  moradoATM="#715CB1" ; 
  blueLight = this.themeColors.blueLight;
  cyan = "#02BEB2";
  purple = "#6202EE";
  cyanLight = this.themeColors.cyanLight;
  gold = this.themeColors.gold;
  purpleLight = this.themeColors.purpleLight;
  red = this.themeColors.red;
  lima= this.themeColors.lime;
  magenta=this.themeColors.magenta;
  volcano=this.themeColors.volcano;
  orange=this.themeColors.orange;
  green=this.themeColors.green;
  geekBlue=this.themeColors.geekBlue;
  gray=this.themeColors.gray;
  white=this.themeColors.white;
  grayLightest=this.themeColors.grayLightest;
  redLight=this.themeColors.redLight;
  greenLight=this.themeColors.greenLight;


   //Bar Chart
   barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
        xAxes: [{
            categoryPercentage: 0.45,
            barPercentage: 0.70,
            display: true,
            scaleLabel: {
                display: false,
                labelString: 'Month'
            },
            gridLines: false,
            ticks: {
                display: true,
                beginAtZero: true,
                fontSize: 13,
                padding: 10
            }
        }],
        yAxes: [{
            categoryPercentage: 0.35,
            barPercentage: 0.70,
            display: true,
            scaleLabel: {
                display: false,
                labelString: 'Value'
            },
            gridLines: {
                drawBorder: false,
                offsetGridLines: false,
                drawTicks: false,
                borderDash: [3, 4],
                zeroLineWidth: 1,
                zeroLineBorderDash: [3, 4]
            },
            ticks: {
                max: 100,                            
                stepSize: 20,
                display: true,
                beginAtZero: true,
                fontSize: 13,
                padding: 10
            }
        }]
    }
};

barChartLabels: string[] = ['Pizzas', 'Panes', 'Postres', 'Lasagnas', 'Batidos'];
barChartType = 'bar';
barChartLegend = true;
barChartColors: Array<any> = [
    { 
        backgroundColor: this.verdeATM,
        borderWidth: 0
    },
    { 
        backgroundColor: this.moradoATM,
        borderWidth: 0
    },
    { 
      backgroundColor: this.cyan,
      borderWidth: 0
  },
  { 
    backgroundColor: this.cyanLight,
    borderWidth: 0
}
];
barChartData: any[] = [
    { 
        data: [65, 59, 80, 81, 56, 55],
        label: 'Enero',
        
    },
    
    { 
        data: [28, 48, 40, 19, 86, 27],
        label: 'Febrero'
    },
    { 
      data: [28, 48, 40, 19, 86, 27],
      label: 'Marzo'
  },
    
];


  // Doughnut Chart
  doughnutChartLabels: string[] = ['Pizzas', 'Postres', 'Batidos'];
  doughnutChartData: number[] = [350, 450, 100];
  doughnutChartColors: Array<any> =  [{ 
      backgroundColor: [this.verdeATM,this.moradoATM, this.cyan],
      pointBackgroundColor : [this.verdeATM, this.moradoATM, this.cyan]
  }];
  doughnutChartType = 'doughnut';
}








