import { Component, OnInit,Input } from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { FormBuilder ,Validators, FormGroup,} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InsumoService } from 'src/app/shared/services/insumo.service';
import { CategoriaInsumoService } from 'src/app/shared/services/categoria-insumo.service';

@Component({
  selector: 'app-agregar-categoria-insumos',
  templateUrl: './agregar-categoria-insumos.component.html',
  styleUrls: ['./agregar-categoria-insumos.component.css']
})
export class AgregarCategoriaInsumosComponent implements OnInit {
  @Input() editarCategoria: any = null;  // variable para saber si se va a editar

  success=false;
  mensaje="";
  categoriaForm: FormGroup;
  error=false;   
  categorialist:any[];
  categoriasPrincipales=[];
  categoriasHijas=[];
  constructor(
    private modalService:NzModalService,
    private fb: FormBuilder,
    private modal: NzModalRef,
    private spinner:NgxSpinnerService,
    private insumos:InsumoService,
    private categorias:CategoriaInsumoService,
    
  ) {
    this.categoriaForm = this.fb.group({
      nombre: [ null, [ Validators.required ] ],
      categoria: [ null, ], 
      subcategoria: [ null, ],  
    });
   }

  ngOnInit() {

    if(this.editarCategoria){
      console.log("editando", this.editarCategoria)
      this.categoriaForm.get('nombre').setValue(this.editarCategoria.referencia);
      this.categoriaForm.get("categoria").setValue(this.editarCategoria.categoria_insumos_padre_id); 
    }

    this.cargarCategorias();
   
   
  }


  
  cargarCategorias(){
    this.categorias.getCategoria().subscribe(res=>{
      console.log(res,"categorias");
      this.categorialist=res;
      this.categoriasPrincipales = this.categorialist
        .filter(cat => !cat.categoria_insumos_padre_id)
        .filter(cat => !cat.categoria);
      
      this.categoriasHijas = this.categorialist.filter(cat => cat.categoria_insumos_padre_id == this.categoriaForm.value.categoria );
      if(this.editarCategoria!=null){        
        let categoria = res.filter(cat => this.editarCategoria.categoria_insumos_id===cat.id)[0];
        console.log(categoria);
        if(categoria.categoria_insumos_padre_id==null){
          this.categoriaForm.get("categoria").setValue(this.editarCategoria.categoria_insumos_id);    
        }else{
          this.categoriaForm.get("categoria").setValue(categoria.categoria_insumos_padre_id);    
          this.categoriaForm.get("subcategoria").setValue(this.editarCategoria.categoria_insumos_id);    
          this.updateSelectCategorias();
        }
        
      }
    });
  }

  updateSelectCategorias(){
    this.categoriasHijas = this.categorialist.filter(cat => cat.categoria_insumos_padre_id == this.categoriaForm.value.categoria );
  }

  // FORMULARIO DE CARGAR CATEGORIA DE INSUMO
    /**
     *@description METODO QUE ENVIA EL REGISTRO DEL FORMULARIO DE CATEGORIA DE INSUMOS. 
    */
    submitCategoria(): void {
      

      if (this.editarCategoria==null) {
         for (const i in this.categoriaForm.controls) {
        this.categoriaForm.controls[ i ].markAsDirty();
        this.categoriaForm.controls[ i ].updateValueAndValidity();
      }
      let payload = {};
      if(this.categoriaForm.valid){
        this.spinner.show();
    
        payload['referencia'] = this.categoriaForm.value.nombre;
        payload['restaurante_id'] = localStorage.getItem("restaurante_seleccionado_id");
        
        
        payload['categoria_insumos_padre_id'] = 
          this.categoriaForm.value.categoria ? 
            this.categoriaForm.value.categoria 
            : 
            this.categoriaForm.value.subcategoria;


        console.log(payload);

        this.categorias.registroCategoria(payload).subscribe(res =>{
          console.log(res);
          this.modal.destroy({ data: true });
          this.spinner.hide();

        }, err =>{
          // this.mensaje="Ocurrio un problema."
          this.mensaje = err.error.errors
          this.error=true;
          this.spinner.hide();
          console.log(err);
        });

      }
      }else if(this.editarCategoria!=null){
        
      for (const i in this.categoriaForm.controls) {
        this.categoriaForm.controls[ i ].markAsDirty();
        this.categoriaForm.controls[ i ].updateValueAndValidity();
      }
      let payload = {};
      if(this.categoriaForm.valid){
        this.spinner.show();
        payload['id'] = this.editarCategoria.id;
        payload['referencia'] = this.categoriaForm.value.nombre;
        payload['restaurante_id'] = localStorage.getItem("restaurante_seleccionado_id");
        
        
        payload['categoria_insumos_padre_id'] = 
          this.categoriaForm.value.categoria ? 
            this.categoriaForm.value.categoria 
            : 
            this.categoriaForm.value.subcategoria;


        console.log(payload);

        this.categorias.editarCategoria(payload).subscribe(res =>{
          console.log(res);
          this.modal.destroy({ data: true });
          this.spinner.hide();

        }, err =>{
          // this.mensaje="Ocurrio un problema."
          this.mensaje = err.error.errors
          this.error=true;
          this.spinner.hide();
          console.log(err);
        });

      }

    
    }
      
      }
     

    
    }


