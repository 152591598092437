import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { rutaApi } from '../util/constants';
import { HttpClient } from '@angular/common/http';
import { RestResponse } from '../util/restResponse';
import { User } from 'src/app/shared/interfaces/user.type';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,) { }


  /**
   * 
   * @description registrar usuario 
   * @author Piter Ruiz
   * @returns Observable<any> 
   */
  public registerUser(userJson: User): Observable<any>{
    return this.http.post<any>(rutaApi('/register'), userJson);
  }


  
  /**
   * 
   * @description registrar usuario 
   * @author Piter Ruiz
   * @returns Observable<any> 
   */
  public put(userJson: User): Observable<any>{
    return this.http.put<any>(rutaApi('/user/'+userJson.id+''), userJson);
  }


   /**
   * 
   * @description registrar usuario 
   * @author Piter Ruiz
   * @returns Observable<any> 
   */
  public obtenerUser(): Observable<Array<User>>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/user?restaurante_id=') + rest);
  }

  /**   
   * @description Eliminar usuario
   * @author Carlos diaz
   * @returns Observable<any> 
   */
  public deleteUser(object): Observable<Array<User>>{
    return this.http.delete<any>(rutaApi('/user/'+object.id+''));
  }
  
}
