import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'categoriaInsumo'
})

export class CategoriaInsumoPipe implements PipeTransform {
    
    public transform(idCategoria,args?:any) {                
        return args.filter(cat => idCategoria===cat.id)[0].referencia;

    }
}