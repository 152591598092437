import { UnidadMedida } from './unidad-medida.type';
import { Restaurant } from './restaurant.type';
import { IngredientesModel } from './ingredientes.type';
export interface IRecetas {
    id?: number;
    nombre?: string; 
    cantidad_preparar?: number; 
    unidad_medida_id?: UnidadMedida;               
    porciones?: number; 
    area_recetas_id?: number; 
    procedimiento?: string; 
    foto?:any;
    restaurante_id?:Restaurant;
    porc_error?:number; 
    porc_materia_prima?:number;
    porc_impuestos?:number;
    porc_servicios?:number;
    ingredientes?:Array<IngredientesModel>; 
    
}


export class RecetasModel implements IRecetas {
    constructor(
       public id?: number,
       public nombre?: string, 
       public cantidad_preparar?: number, 
       public unidad_medida_id?: UnidadMedida,               
       public porciones?: number, 
       public area_recetas_id?: number, 
       public procedimiento?: string, 
       public foto?:any,
       public restaurante_id?:Restaurant,
       public porc_error?:number, 
       public porc_materia_prima?:number,
       public porc_impuestos?:number,
       public porc_servicios?:number,
       public ingredientes?:Array<IngredientesModel>
     
    ){}
}