
import { Component, OnInit,Input } from '@angular/core';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';

// Importaciones asociadas al modelo
import { FormMenuComponent } from 'src/app/forms/menu/menu.component'; // Formulario (modal)
import { MenuService } from 'src/app/shared/services/menu.service';    // Servicio (conexión api)
import { MenuModel } from '../../shared/interfaces/menu.type';


@Component({
  selector: 'app-card-menu',
  templateUrl: './card-menu.component.html',
  styleUrls: ['./card-menu.component.css']
})
export class CardMenuComponent implements OnInit {

  @Input() menu;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private mensajeComponentService : MensajeComponentService,
    private modalService:NzModalService,
    private servicio : MenuService
  ) { }

  ngOnInit() {
    console.log(this.menu)
  }

  /**
   * @description Actualizar una subreceta
   * @author Javier Evans
   */
  editar(): void {
    const modal = this.modalService.create({
        nzContent: FormMenuComponent,
        nzFooter: null,
        nzWidth:900,
        nzComponentParams: {
          objetoEditar : this.menu,
        }

    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){
        this.mensajeComponentService.changeMessage("updateMenus");
      }
    });
  }

   /**
   * @description
   * @author Javier Evans
   */
  delete(): void {
    this.spinner.show();
    this.servicio.delete(this.menu).subscribe(res=>{
      this.mensajeComponentService.changeMessage("updateMenus");
      this.spinner.hide();
    },err=>{
      console.log(err);
      this.spinner.hide();
    });
  }


}
