import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rutaApi } from '../util/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlergenosService {

  constructor(private http: HttpClient) { }


  /**
  * 
  * @description obtiene los alergenos
  * @author Gabriel Baldez
  * @returns Observable<any> 
  */
  public getAlergenos(): Observable<any> {
    let rest = localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/alergeno?restaurante_id=') + rest);
  }


  /**
   * 
   * @description registra los alergenos
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public registroAlergeno(object): Observable<any> {
    let rest = localStorage.getItem('restaurante_seleccionado_id');
    return this.http.post<any>(rutaApi('/alergeno?restaurante_id=') + rest, object);
  }


  /**
 * 
 * @description Actualizar alergenos
 * @author Gabriel Baldez
 * @returns Observable<any> 
 */
  public editarAlergeno(object): Observable<any> {
    return this.http.put<any>(rutaApi('/alergeno/' + object.id), object);
  }


}
