import { Component, OnInit, Input } from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { FormBuilder ,Validators, FormGroup} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AreaRecetaService } from 'src/app/shared/services/area-receta.service';
import { AlergenosService } from 'src/app/shared/services/alergenos.service';
import { InsumoService } from 'src/app/shared/services/insumo.service';

@Component({
  selector: 'app-alergenos',
  templateUrl: './alergenos.component.html',
  styleUrls: ['./alergenos.component.css']
})
export class AlergenosComponent implements OnInit {
  success = false;
  mensaje = "";
  alergenosForm: FormGroup;
  listaServicios = [];
  error = false;
  @Input() objetoEditar: any = null;  // Aquí se guarda el objeto a editar (cuando corresponde)
  constructor(
    private modalService: NzModalService,
    private fb: FormBuilder,
    private modal: NzModalRef,
    private spinner: NgxSpinnerService,
    private alergenoService: AlergenosService,
    private is:InsumoService
  ) { 
    this.alergenosForm = this.fb.group({
      nombre: [null, [Validators.required]],
      insumos: [[], [Validators.required]],
    });
  }

  ngOnInit() {
    this.is.getInsumos().subscribe(res => {
      console.log(res);
      this.listaServicios = res;
    }, err => {
      console.log(err);
    });

    if (this.objetoEditar) {
      console.log("editando", this.objetoEditar)
      this.alergenosForm.get('nombre').setValue(this.objetoEditar.nombre);
      this.alergenosForm.get('insumos').setValue(this.objetoEditar.insumos_ids);
      
    }
  }


  /**
   *@description METODO QUE ENVIA EL REGISTRO 
  */
  submitForm(): void {
    console.log('enviar');
    for (const i in this.alergenosForm.controls) {
      this.alergenosForm.controls[i].markAsDirty();
      this.alergenosForm.controls[i].updateValueAndValidity();
    }
    let payload = {};
    if (this.alergenosForm.valid) {
      this.spinner.show();

      payload['nombre'] = this.alergenosForm.value.nombre;
      payload['insumos_ids'] = this.alergenosForm.value.insumos;
      payload['restaurante_id'] = localStorage.getItem("restaurante_seleccionado_id");


      console.log(payload);
      if (this.objetoEditar) {

        payload['id'] = this.objetoEditar.id;

        this.alergenoService.editarAlergeno(payload).subscribe(res => {
          console.log(res);
          this.modal.destroy({ data: true });
          this.spinner.hide();
        }, err => {
          // this.mensaje = "Ocurrio un problema."
          this.mensaje = err.error.errors
          this.error = true;
          this.spinner.hide();
          console.log(err);
        });
      } else {
        
        this.alergenoService.registroAlergeno(payload).subscribe(res => {
          console.log(res);
          this.modal.destroy({ data: true });
          this.spinner.hide();
        }, err => {
          // this.mensaje = "Ocurrio un problema."
          this.mensaje = err.error.errors
          this.error = true;
          this.spinner.hide();
          console.log(err);
        });
      }
    }
  }

}
