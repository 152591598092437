import { MensajeComponentService } from './../../shared/services/mensaje-component.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AgregarUsuarioComponent } from '../../forms/agregar-usuario/agregar-usuario.component';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-gestion-super-admin',
  templateUrl: './gestion-super-admin.component.html',
  styleUrls: ['./gestion-super-admin.component.css']
})
export class GestionSuperAdminComponent implements OnInit {
  opcionMenu: any[] = [];
  memberList=[];
  
  success=false;
  mensaje="";
  banView:boolean=false;
  
  constructor(
    private router: Router,
    private modalService:NzModalService,
    private user:UserService,
    private mensajeService:MensajeComponentService,
    private spinner: NgxSpinnerService,
    public authService:AuthenticationService
  ) { 

    this.mensajeService.currentMessage.subscribe(message => {
      switch (message) {


        case 'updateListUser':
          this.listarUsuarios();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {
    // this.spinner.show(); 
    this.listarUsuarios();
    
  
  }

  
  createComponentModal(): void {
    const modal = this.modalService.create({
        nzContent: AgregarUsuarioComponent,
        nzFooter:null,
        nzComponentParams:{
          modo:"super"
        }    
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){
        this.listarUsuarios();
        this.mensaje="Registro exitoso.";
        this.success=true;
      }
    });
  }


  listarUsuarios(){
    this.banView=false;
    this.memberList=[];
    this.user.obtenerUser().subscribe(res=>{
      console.log(res);
      this.memberList = res;
      // if (this.memberList.length>1) {
        this.banView=true;
      // }
      // this.spinner.hide(); 
    },err=>{
      console.log(err);
    });
  }
}
