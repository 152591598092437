import { Proveedor } from './../../shared/interfaces/proveedor.type';
import { element } from 'protractor';
import { Component, OnInit,Input } from '@angular/core';
import { ProveedorService } from 'src/app/shared/services/proveedor.service';
import { Router } from '@angular/router';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProveedorComponent } from 'src/app/forms/proveedor/proveedor.component';
import { NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-card-proveedor',
  templateUrl: './card-proveedor.component.html',
  styleUrls: ['./card-proveedor.component.css']
})
export class CardProveedorComponent implements OnInit {
  @Input() proveedor: Proveedor;

//  proveedoreslist:any[];
  constructor(
    private spinner:NgxSpinnerService,
    private proveedores:ProveedorService,
    private router:Router,
    private mensajeComponentService:MensajeComponentService,
    private modalService:NzModalService,
  ) { }

 


  ngOnInit() {
    
  

  }


  editarPro(){
    console.log("editando");
    console.log(this.proveedor,"javierrr");
  
    const modal = this.modalService.create({
        nzContent: ProveedorComponent,
        nzFooter:null,
        nzComponentParams:{
          proveedor:this.proveedor
        }    
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){
        console.log("Proveedor actulaizado");
        this.mensajeComponentService.changeMessage("updateProveedor");
      }
    });
  
  }

  confirm(): void {
    this.spinner.show();
    this.proveedores.deleteProveedor(this.proveedor).subscribe(res=>{      
      this.mensajeComponentService.changeMessage("deleteProveedor");             
      this.spinner.hide();
    },err=>{
      this.spinner.hide();      
    });
  }

}
