
import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef, da_DK } from 'ng-zorro-antd';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlmacenService } from 'src/app/shared/services/almacen.service';



@Component({
  selector: 'app-form-destino',
  templateUrl: './destino.component.html',
  styleUrls: ['./destino.component.css']
})
export class FormDestinoComponent implements OnInit {

  @Input() objetoEditar: any = null;  // Aquí se guarda el objeto a editar (cuando corresponde)

  isNew : boolean = true; // True si se está creando un registro, false si se está editando
  mensaje = "";
  form : FormGroup;
  error: boolean = false; 

  constructor(    
    private fb: FormBuilder,
    private modal: NzModalRef,
    private spinner:NgxSpinnerService,
    private almacen: AlmacenService,

  ) { 
    this.form = this.fb.group({
      nombre: [ null, [ Validators.required ] ],
      default_entrada: [ false ],
      default_salida: [ false],

    });
  }

  ngOnInit() {

    console.log( "this.objetoEditar", this.objetoEditar );

    // Se está editando un registro
    if(this.objetoEditar){
      this.isNew = false;

      // Asignar propiedades del objeto al formulario
      this.form.get('nombre').setValue(this.objetoEditar.nombre);
      this.form.get('default_entrada').setValue(this.objetoEditar.default_entrada==0?false:true);
      this.form.get('default_salida').setValue(this.objetoEditar.default_salida==0?false:true);
    }
    
  }


  submit(): void {
    
    for (const i in this.form.controls) {
      this.form.controls[ i ].markAsDirty();
      this.form.controls[ i ].updateValueAndValidity();
    }
    
    if(this.form.valid){

      this.spinner.show();

      let payload = {
        nombre : this.form.value.nombre,
        default_salida : this.form.value.default_salida,
        default_entrada : this.form.value.default_entrada,
        restaurante_id : localStorage.getItem("restaurante_seleccionado_id")
      };
      

      if(!this.isNew){ // Al editar, agregar id del objeto al payload
        payload['id'] = this.objetoEditar.id;
      }

      let servicio = this.isNew ? this.almacen.post(payload) : this.almacen.put(payload);
      
      

      console.log(payload);
        
      servicio.subscribe(res =>{        
        this.modal.destroy({ data: true });  
        this.spinner.hide();
      }, err =>{
        // this.mensaje="Ocurrio un problema."
        this.mensaje = err.error.errors;
        this.error=true;
        this.spinner.hide();
        console.log(err);
      });
  
    }
  
   
  }

  

}
