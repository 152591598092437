import { InsumoComponent } from '../../forms/insumo/insumo.component';
import { NzModalService } from 'ng-zorro-antd';
import { Insumos } from './../../shared/interfaces/insumos.type';
import { Component, OnInit,Input } from '@angular/core';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { InsumoService } from 'src/app/shared/services/insumo.service';
import { AlergenosService } from 'src/app/shared/services/alergenos.service';

@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.css']
})
export class CardInfoComponent implements OnInit {
  expand: boolean = false;
  gridStyle = {
    width    : '50%',
    padding  : '5px',
    textAlign: 'left'
  };
  gridStyleOne = {
    width    : '100%',
    padding  : '5px',
    textAlign: 'left'
  };

  @Input() insumo;
  @Input() categorias:any;

  insumoAlergeno:any;
  alergenos: any;


  constructor(
    private spinner:NgxSpinnerService,
    private router:Router,
    private mensajeComponentService:MensajeComponentService,
    private insumos:InsumoService,
    private modalService: NzModalService,
    private alergenosService:AlergenosService
  ) { }

  ngOnInit() {

    // this.insumos.getInsumoById(this.insumo.id).subscribe(res => {
    //   this.insumoAlergeno=res;

    // })


  }


  confirm(): void {
    this.spinner.show();
    this.insumos.deleteInsumo(this.insumo).subscribe(res=>{
      this.mensajeComponentService.changeMessage("deleteInsumo");
      this.spinner.hide();
    },err=>{
      this.spinner.hide();
    });
  }



  editarInsumo(){
    const modal = this.modalService.create({
      nzContent: InsumoComponent,
      nzFooter: null,
      nzComponentParams:{
        insumo:this.insumo,
      },
      nzWidth: 1000,
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.mensajeComponentService.changeMessage("updateListInsumo");
        // this.cargarInsumos();
        // this.mensaje = "Registro exitoso.";
        // this.success = true;
      }
    });

  }

}
