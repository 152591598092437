import { MensajeComponentService } from '../../shared/services/mensaje-component.service';

import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service';
import { NzModalRef } from 'ng-zorro-antd';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wizard-restaurant',
  templateUrl: './wizard-restaurant.component.html',
  styleUrls: ['./wizard-restaurant.component.css']
})

export class WizardRestaurantComponent implements OnInit {

  @Input() restaurante: any = null;  // Si se recibe un restaurante está en modo edición, sino modo crear

  registro:FormGroup
  registroForm:FormGroup
  error=false;
  mensaje="";
  constructor(
    private spinner:NgxSpinnerService,
    private fb: FormBuilder,
    private restaurantService:RestaurtantService,
    private modal:NzModalRef,
    public router:Router,
    private authService:AuthenticationService,
    private mensajeService:MensajeComponentService
  ) {

    this.registro = this.fb.group({
      nombre: [ null, [ Validators.required ] ],
      moneda: [ null, [ Validators.required, Validators.maxLength(5) ] ],
    });

    this.registroForm = this.fb.group({
      nombre: [ null, [ Validators.required ] ],
      moneda: [ null, [ Validators.required, Validators.maxLength(5) ] ],
    });
   }

  ngOnInit() {
    if(this.restaurante){
      console.log("editando", this.restaurante)
      this.registroForm.get('nombre').setValue(this.restaurante.nombre);
      this.registroForm.get('moneda').setValue(this.restaurante.moneda);
    }
    else
      console.log("nuevo restaurante");
  }

  submitForm(): void {
    console.log('enviar');
    for (const i in this.registro.controls) {
      this.registro.controls[ i ].markAsDirty();
      this.registro.controls[ i ].updateValueAndValidity();
    }
    let payload = {};
    if(this.registro.valid){
      this.spinner.show();
   
      payload['nombre'] = this.registro.value.nombre;   
      payload['moneda'] = this.registro.value.moneda;   
  
      console.log(payload);
  
      this.restaurantService.registerRest(payload).subscribe(res =>{
        console.log(res);
        this.authService.currentUserValue.restaurantes.push(res);
        localStorage.setItem('currentUser', JSON.stringify(this.authService.currentUserValue));
        this.modal.destroy({ data: true });  
        this.mensajeService.changeMessage("updateRestaurantes");
        this.spinner.hide();
      }, err =>{
        // this.mensaje="Ocurrio un problema."
        this.mensaje = err.error.errors;
        this.error=true;
        this.spinner.hide();
        console.log(err);
      });
      
      
    }
  
  }


  /**
   * @description Crear o editar restaurante
   */
  submit(): void {
    console.log('enviar');
    for (const i in this.registroForm.controls) {
      this.registroForm.controls[ i ].markAsDirty();
      this.registroForm.controls[ i ].updateValueAndValidity();
    }
    let payload = {};
    if(this.registroForm.valid){
      this.spinner.show();
      if(this.restaurante){
        payload['id'] = this.restaurante.id;
      }
      payload['nombre'] = this.registroForm.value.nombre;
      payload['moneda'] = this.registroForm.value.moneda;

      console.log(payload);

      // Modo editar restaurante
      if(this.restaurante){
          this.restaurantService.update(payload).subscribe(res =>{
            console.log(res);            
            this.modal.destroy({ data: true });  
            this.spinner.hide();
          }, err =>{
            // this.mensaje="Ocurrio un problema."
            this.mensaje = err.error.errors;
            this.error=true;
            this.spinner.hide();
            console.log(err);
          });
      }
      // Nuevo restaurante
      else{          
          this.restaurantService.registerRest(payload).subscribe(res =>{
            console.log(res);
            this.authService.currentUserValue.restaurantes.push(res);
            localStorage.setItem('currentUser', JSON.stringify(this.authService.currentUserValue));
            this.modal.destroy({ data: true });  
            this.spinner.hide();
          }, err =>{
            // this.mensaje="Ocurrio un problema."
            this.mensaje = err.error.errors;
            this.error=true;
            this.spinner.hide();
            console.log(err);
          });
      }

      
      
    }
  
   
  }

}
