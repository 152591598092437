import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup,  Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
// import { TokenStorage } from 'src/app/shared/util/token.storage';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  correo;
  loginForm: FormGroup;
  isVisible = false;
  mensaje="";
  success=false;
  error=false;    
  passwordVisible = false;  
  constructor(
    private fb: FormBuilder,
    // public angularFireAuth: AngularFireAuth,
    private notification: NzNotificationService,
    private spinner: NgxSpinnerService,
    // private token: TokenStorage,
    // private userService: UserService,
    private router: Router,
    private auth:AuthenticationService,
    private _ngZone: NgZone,
    
  ) { }

  ngOnInit() {
    // this.spinner.show();
    this.loginForm = this.fb.group({
      email: [ null, [ Validators.required, Validators.pattern('^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)(\.[A-Za-z]{2,})$') ] ],
      password: [ null, [ Validators.required ] ],
      remember_token: [ 1 ],
      recuerdame: [ false ]
    });

    this.loginForm.get("recuerdame").setValue(localStorage.getItem('recuerdame'));
    if (this.loginForm.value.recuerdame) {
      this.loginForm.get("recuerdame").setValue(true);
      this.loginForm.get("email").setValue(localStorage.getItem("correo"));
    }
  }


  async submitForm() {
    console.log("submitForm");
    for (const i in this.loginForm.controls) {
        this.loginForm.controls[ i ].markAsDirty();
        this.loginForm.controls[ i ].updateValueAndValidity();
    }       
    this.error=false;
    this.success=false;
    this.recordarme();
    if (this.loginForm.valid) {
      this.spinner.show();    
      
      let login = await this.auth.login(this.loginForm.value.email,this.loginForm.value.password,this.loginForm.value.recuerdame);      
      console.log(login);
      if(login.login){

        // Se selecciona al primer restaurante asociado al usuario
        if(this.auth.currentUserValue.restaurantes.length>0){
          localStorage.setItem("restaurante_seleccionado_id", "" + this.auth.currentUserValue.restaurantes[0].id);
        }

        if(this.auth.currentUserValue.is_superadmin)
          this.router.navigate(['/menu/menu-super-admin']);
        if(this.auth.currentUserValue.is_admin)
          this.router.navigate(['/menu/menu-admin']);
        if(this.auth.currentUserValue.is_empleado1 || this.auth.currentUserValue.is_empleado2)
          this.router.navigate(['/dashboard/dashboard']);

      }else{
        // if(login.error=='invalid_credentials'){
        //   this.mensaje="Credenciales invalidas, valide la informacion.";

        // }else{
          this.mensaje=login.error;
        // }
        this.error=true;
      }
      this.spinner.hide();      
      console.log(this.auth.currentUserValue);
    }
  }

  recordarme() {
    if (this.loginForm.value.recuerdame) {
      localStorage.setItem("recuerdame", this.loginForm.value.recuerdame);
      localStorage.setItem("correo", this.loginForm.value.email);
    } else {
      localStorage.removeItem("recuerdame");
      localStorage.removeItem("correo");
    }
    // if (this.loginForm.value.recuerdame) {
    //   this.loginForm.get('recuerdame').setValue(false);
    // } else {
    //   this.loginForm.get('recuerdame').setValue(true);
    // }
  }
  

 
  
}
