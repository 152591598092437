import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { ReporteService } from './../../shared/services/reporte.service';
import { Component, OnInit, Input } from '@angular/core';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-grafica-barras',
  templateUrl: './grafica-barras.component.html',
  styleUrls: ['./grafica-barras.component.css']
})
export class GraficaBarrasComponent implements OnInit {
  @Input() tipo; //[compra o venta]
  fechaElegida;//mes y anio model para grafico de ventas
  ventasxMesAnioList=[]//para grafica de ventas por meses anio en reporte ventas
  moneda = "";
  constructor(
    private reporteService:ReporteService,
  ) { }

  ngOnInit() {
    this.actualizarGrafica();
  }

  /**
   * @description Actualizar gráfica de ventas/compras del anio/mes.
   */
  actualizarGrafica(){

    
    
    let year = null;
    let month = null;

    if(this.fechaElegida){
      console.log(this.fechaElegida);
      year = formatDate(this.fechaElegida, 'yyyy', 'en');
      month = formatDate(this.fechaElegida, 'MM', 'en');
    }else{
      let date = new Date()
      year = formatDate(date, 'yyyy', 'en');
      month = formatDate(date, 'MM', 'en');
    }

    let servicio = this.tipo=="ventas" ?  this.reporteService.ventas(year,Number(month)) : this.reporteService.compras(year,Number(month));

    servicio.subscribe(res=>{
      console.log("respuesta", res);
      this.barChartLabels=res.dias;
      this.barChartData=res.data_set;
      this.ventasxMesAnioList=res.extras;
      this.moneda=res.moneda;
    },err=>{
      console.log(err);
    });
  }


  // GRAFICA TOTAL VENTAS DIARIAS, MISMO DIA SEMANA ANTERIOR, VENTAS ACUMULADA SEMANA, VENTAS ACUMULADA MES -->
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  // public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { data: [0], label: 'Año Anterior' },
    { data: [0], label: 'Año Actual' }
  ];

}
