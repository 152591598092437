import { AuthAdminGuard } from './../shared/guard/auth-admin.guard';
import { AuthSuperAdminGuard } from './../shared/guard/auth-super-admin.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuAdminComponent } from './menu-admin/menu-admin.component';
import { MenuSuperAdminComponent } from './menu-super-admin/menu-super-admin.component';

const routes: Routes = [
  {
    path: 'menu-admin',
    component: MenuAdminComponent,
    canActivate: [AuthAdminGuard],
    data: {
        title: 'Menu-Admin ',
        
    }
  },

  {
      path: 'menu-super-admin',
      component: MenuSuperAdminComponent,
      canActivate: [AuthSuperAdminGuard],
      data: {
          title: 'Menu-Super-Admin ',          
      }
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenuRoutingModule { }
