import { PedidoFormComponent } from './../../forms/pedido/pedido.component';
import { Component, OnInit } from '@angular/core';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenStorage } from 'src/app/shared/util/token.storage';
import { HttpClient } from '@angular/common/http';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { ModalCompraComponent } from 'src/app/forms/compra/modal-compra.component';
import { ComprasService } from 'src/app/shared/services/compras.service';
@Component({
  selector: 'app-compras',
  templateUrl: './compras.component.html',
  styleUrls: ['./compras.component.css']
})
export class ComprasComponent implements OnInit {
  success = false;
  mensaje = "";
  error = false;
  compraslist:any=[];
  pedidosList:any=[];
  banView: boolean = false;
  view="pedidos";//vista por defecto
  constructor(
    private modalService: NzModalService,
    private mensajeService: MensajeComponentService,
    private spinner: NgxSpinnerService,
    private tokenStorage: TokenStorage,
    private http: HttpClient,
    private msg: NzMessageService,
     private compras: ComprasService,
  ) {

    this.mensajeService.currentMessage.subscribe(message => {
      switch (message) {


        case 'deleteCompras':
          this.listarCompras();
          this.listarPedidos();
          break;
        case 'updateCompras':
          this.listarCompras();
          this.listarPedidos();
          break;
        default:
          break;
      }
    });
   }

  ngOnInit() {
    this.mensajeService.changeMessage("/pages/compras");
    this.listarCompras();
    this.listarPedidos();
  }

  nuevaCompra(): void {
    const modal = this.modalService.create({
      nzContent: PedidoFormComponent,
      nzFooter: null,
      nzWidth: 1200,
      nzMaskClosable:false



    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.listarCompras();
        this.listarPedidos();
        this.mensaje = "Registro exitoso.";
        this.success = true;
      }
    });
  }


  listarCompras() {
    this.banView=false;


    this.compras.index("compra").subscribe(res => {

      console.log(res, "compras");

      this.compraslist = res;
      this.banView = true;
      // this.spinner.hide();

    }, err => {
      this.banView = true;
      // this.mensaje = "Ocurrio un problema."
      this.mensaje = err.error.errors;
      this.error=true;
      // this.spinner.hide();
      console.log(err);
    });

  }


  listarPedidos() {
    this.banView=false;
    this.compras.index("precompra").subscribe(res => {

      console.log(res, "PEdidos");

      this.pedidosList = res;
      this.banView = true;
      // this.spinner.hide();

    }, err => {
      this.banView = true;
      // this.mensaje = "Ocurrio un problema."
      this.mensaje = err.error.errors;
      this.error=true;
      // this.spinner.hide();
      console.log(err);
    });

  }

}
