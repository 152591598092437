import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { rutaApi } from '../util/constants';

@Injectable({
  providedIn: 'root'
})
export class RestaurtantService {

  constructor(private http: HttpClient) { }


  /**
   * 
   * @description obtiene los  restaurantes
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public getRest(): Observable<any>{
    return this.http.get<any>(rutaApi('/restaurante'));
  }

  /**
   * 
   * @description registrar restaurant 
   * @author carlos diaz
   * @returns Observable<any> 
   */
  public registerRest(object): Observable<any>{
    return this.http.post<any>(rutaApi('/restaurante'),object);
  }

  /**
   * 
   * @description Editar restaurante
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public update(object): Observable<any>{
    return this.http.put<any>(rutaApi('/restaurante/' + object.id) , object);
  }

  /**
   * @description Obtener la moneda de un restaurante
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public getMoneda($restaurante_id): Observable<any>{
    return this.http.get<any>(rutaApi('/restaurante/' + $restaurante_id + '/moneda'));
  }
}
