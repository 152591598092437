import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rutaApi } from '../util/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProveedorService {

  constructor( private http: HttpClient) { }

   /**
   * 
   * @description obtiene los proveedores
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public getProveedor(): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/proveedor?restaurante_id='+ rest));
  }

  /**
   * 
   * @description registrar proveedor
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public registroProveedor(object): Observable<any>{
    return this.http.post<any>(rutaApi('/proveedor'),object);
  }

   /**
   * 
   * @description Editar Proveedor
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public editarProveedor(object): Observable<any>{
    return this.http.put<any>(rutaApi('/proveedor/' + object.id) , object);
  }


  /**   
   * @description Eliminar proveedor
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public deleteProveedor(object): Observable<Array<any>>{
    return this.http.delete<any>(rutaApi('/proveedor/'+object.id+''));
  }
}
