import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthSuperAdminGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      if (user && user.is_superadmin) {
          return true;
      }
      this.router.navigate(['/login']);
      return false;

  }
}
