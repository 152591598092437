import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SugerenciasService } from 'src/app/shared/services/sugerencias.service';

@Component({
  selector: 'app-sugerencias',
  templateUrl: './sugerencias.component.html',
  styleUrls: ['./sugerencias.component.css']
})
export class SugerenciasComponent implements OnInit {
  success = false;
  mensaje: any = '';
  error = false;
  form: FormGroup;

  lista = [];
  banView = true;
  constructor(
    private fb: FormBuilder,
    private ss: SugerenciasService,
    private spinner: NgxSpinnerService,
    public auth: AuthenticationService,
  ) {
    this.form = this.fb.group({
      tipo: ['sugerencia', [Validators.required]],
      contenido: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(300)]],
    });
  }
  // this.auth.currentUserValue && this.auth.currentUserValue.is_empleado1
  ngOnInit() {
    if (this.auth.currentUserValue && this.auth.currentUserValue.is_superadmin) {
      this.banView = false;
      this.ss.listar().subscribe(res => {
        console.log(res);
        this.lista = res;
        this.banView = true;
      }, err => {
        console.log(err);
        this.banView = true;
      });
    }
  }

  enviar() {
    if (this.form.valid) {
      this.spinner.show();
      this.ss.crear(this.form.value).subscribe(res => {
        console.log(res);
        this.form.reset();
        this.form.get('tipo').setValue('sugerencia');
        this.mensaje = 'GRACIAS POR SU MENSAJE, En breve un representante le estará contactando para darle seguimiento';
        this.success = true;
        this.spinner.hide();
      }, err => {
        console.log(err);
        this.spinner.hide();
      });
    }
  }

  borrar(id) {
    console.log(id);
    this.spinner.show();
    this.ss.borrar(id).subscribe(res => {
      this.spinner.hide();
      const aux = this.lista.filter( item => (item.id !== id));
      this.lista = aux;
      console.log(res);
    }, err => {
      this.spinner.hide();
      console.log(err);
    });
  }

}
