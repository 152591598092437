import { rutaApi } from 'src/app/shared/util/constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SugerenciasService {

  constructor(private http: HttpClient) { }

  public crear(value): Observable<any> {
    return this.http.post<any>(rutaApi('/sugerencia'), value);
  }

  public listar(): Observable<any> {
    return this.http.get<any>(rutaApi('/sugerencia'));
  }

  public borrar(id): Observable<any> {
    return this.http.delete<any>(rutaApi('/sugerencia/') + id);
  }
}
