import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rutaApi } from '../util/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnidadEquivalenteService {

  constructor(private http: HttpClient) { }


  /**
   * 
   * @description obtiene los insumos
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public getUniEquivalentes(): Observable<any> {
    let rest = localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/unidad_equivalente?restaurante_id=') + rest);
  }

  /**
  * 
  * @description Actualizar Unidad de medida equivalente
  * @author Gabriel Baldez
  * @returns Observable<any> 
  */
  public editarUniEquivalente(object): Observable<any> {
    return this.http.put<any>(rutaApi('/unidad_equivalente/' + object.id), object);
  }

  /**
   * 
   * @description registrar una unidad equivalente
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public crearUnidadEquivalente(object): Observable<any> {
    return this.http.post<any>(rutaApi('/unidad_equivalente'), object);
  }
}
