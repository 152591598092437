import { PedidoFormComponent } from './../../forms/pedido/pedido.component';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { ModalCompraComponent } from 'src/app/forms/compra/modal-compra.component';
import { Router } from '@angular/router';
import { MensajeComponentService } from './../../shared/services/mensaje-component.service';
import { ComprasService } from './../../shared/services/compras.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.css']
})
export class PedidoComponent implements OnInit {
  @Input() compra: any;
  customMask: ["separator.2", null];
  acciones=2;
  constructor(
    private spinner: NgxSpinnerService,
    private compras: ComprasService,
    private router: Router,
    private mensajeComponentService: MensajeComponentService,
    private modalService: NzModalService,
    public authService:AuthenticationService,

  ) {


  }

  ngOnInit() {
    // if(){
    //   this.acciones = 1; ///['Eliminar','Editar','Aprobar'];
    //  }else if(this.compra.aprobado==1){
    //    this.acciones = 2;// ['Eliminar','Editar','Crear'];
    //  }
  }


  /**
  * @description Actualizar una compra
  * @author Gabriel Baldez
  */
  editar(): void {
    const modal = this.modalService.create({
      nzContent: PedidoFormComponent,
      nzFooter: null,
      nzWidth: 1200,
      nzComponentParams: {
        objetoEditar: this.compra,
      }

    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.mensajeComponentService.changeMessage("updateCompras");
      }
    });
  }


  /**
  * @description
  * @author Gabriel Baldez
  */
  delete(): void {
    this.spinner.show();
    this.compras.deleteCompra(this.compra).subscribe(res => {
      this.mensajeComponentService.changeMessage("deleteCompras");
      this.spinner.hide();
    }, err => {
      console.log(err);
      this.spinner.hide();
    });
  }


    /**
    * @description
    * @author Gabriel Baldez
    */
   aprobarPedido(): void {
    const modal = this.modalService.create({
      nzContent: PedidoFormComponent,
      nzFooter: null,
      nzWidth: 1200,
      nzComponentParams: {
        objetoEditar: this.compra,
        tipo: "aprobar",
      }

    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.mensajeComponentService.changeMessage("updateCompras");
      }
    });
  }


  crearFactura(){
    const modal = this.modalService.create({
      nzContent: ModalCompraComponent,
      nzFooter: null,
      nzWidth: 1200,
      nzComponentParams: {
        objetoEditar: this.compra,
      }

    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.mensajeComponentService.changeMessage("updateCompras");
      }
    });

  }



  descargarPdf(){
    let fileName = 'Pedido '+this.compra.referencia;
    console.log("DAsds")
    this.compras.download(this.compra.id).subscribe(res=>{
      const blob = new Blob([res], { type : 'application/pdf' });
      const file = new File([blob], fileName + '.pdf', { type: 'application/pdf' });
      saveAs(file);
    },err=>{
      console.log(err);
    });

  }

}
