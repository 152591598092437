import { ReporteService } from './../../shared/services/reporte.service';
import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-ingenieria-menu',
  templateUrl: './ingenieria-menu.component.html',
  styleUrls: ['./ingenieria-menu.component.css']
})
export class IngenieriaMenuComponent implements OnInit {
  start;
  end;
  dosDimensionesData = [];
  tresDimensionesData = [];
  loadingdos = false;
  loadingtres = false;
  constructor(
    private rs: ReporteService
  ) { }

  ngOnInit() {

  }

 dosDimensiones(){
   if(this.start, this.end){
    this.loadingdos = true;
    this.rs.matrizDimensiones(formatDate(this.start, 'yyyy-MM-dd', 'en'),formatDate(this.end, 'yyyy-MM-dd', 'en'),2).subscribe(res=>{
      console.log(res);
      this.dosDimensionesData = [];
      if(res !== 'No hay datos'){
        for (const key in res) {
          if (Object.prototype.hasOwnProperty.call(res, key)) {
            res[key].forEach(element => {
              element['area'] = key;
              this.dosDimensionesData.push(element);
            });
            
          }
        }        
      }
      this.loadingdos = false;
    });
    this.loadingtres = true;
    this.rs.matrizDimensiones(formatDate(this.start, 'yyyy-MM-dd', 'en'),formatDate(this.end, 'yyyy-MM-dd', 'en'),3).subscribe(res=>{
      console.log(res);
      this.tresDimensionesData = [];
      if(res !== 'No hay datos'){
        for (const key in res) {
          if (Object.prototype.hasOwnProperty.call(res, key)) {
            res[key].forEach(element => {
              element['area'] = key;
              this.tresDimensionesData.push(element);
            });
            
          }
        }        
      }
      this.loadingtres = false;
    });
   }
   
 }



}
