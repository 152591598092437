import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rutaApi } from '../util/constants';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SubrecetaService {

  constructor(private http: HttpClient) { }

  /**
   * 
   * @description obtiene las subrecetas
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public index(): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/subreceta?restaurante_id='+ rest));
  }

   /**
   * 
   * @description obtiene las subrecetas
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public indexWithPrice(): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/subreceta_with_price?restaurante_id='+ rest));
  }

   /**
   * 
   * @description registrar la subreceta
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public post(object): Observable<any>{
    return this.http.post<any>(rutaApi('/subreceta'),object);
  }
   
  /**
   * 
   * @description actualizar la subreceta
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public put(object): Observable<any>{
    return this.http.put<any>(rutaApi('/subreceta/' + object.id ), object);
  }

  /**   
   * @description Eliminar objeto
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public delete(object): Observable<Array<any>>{
    return this.http.delete<any>(rutaApi('/subreceta/' + object.id ));
  }
}
