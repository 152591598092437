import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../shared/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { RolService } from 'src/app/shared/services/rol.service';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service';
import { min } from 'rxjs/operators';

@Component({
  selector: 'app-agregar-usuario',
  templateUrl: './agregar-usuario.component.html',
  styleUrls: ['./agregar-usuario.component.css']
})
export class AgregarUsuarioComponent implements OnInit {
  @Input() modo: string;
  @Input() user: any = null; //VARIABLE PARA SABER SI SE VA A EDITAR UN USUARIO

  registesUserForm: FormGroup;
  error = false;
  mensaje = "";
  isVisible = false;
  passwordVisible = false;
  password: string;
  roleslist: any = [];
  restaurantelist: any = [];
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private roles: RolService,
    private modal: NzModalRef,
    private restaurante: RestaurtantService,
    private authService: AuthenticationService
  ) {
    //SI ES IGUAL CREO UN USUARIO SI ES DISTINTO EDITO UN USUARIO
    console.log(this.user);
   
  }

  ngOnInit() {
    //VALIDA SI EL QUE INTERACTUA ES EL SUPERADMIN PARA SETEAR EL ROL DE FORMULARIO CON EL ID DEL ROL DE ADMIN DE RESTAURANTES
    // if (this.authService.currentUserValue.is_superadmin)
    //   this.registesUserForm.get("roles").setValue(2);      


    this.roles.getRols().subscribe(res => {
      console.log(res, "roless");
      this.roleslist = res;
    });


    if(this.user==null){
      //SI ES NULO ES UN NUEVO REGISTRO
      if(this.authService.currentUserValue.is_superadmin){
        this.registesUserForm = this.fb.group({
          id: [null],
          nombre: [null, [Validators.required]],
          email: [null, [Validators.required, Validators.pattern('^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)(\.[A-Za-z]{2,})$')]],
          password: [null,[Validators.required]],
          roles: [null],
        });  
      }else{
        this.registesUserForm = this.fb.group({
          id: [null],
          nombre: [null, [Validators.required]],
          email: [null, [Validators.required, Validators.pattern('^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)(\.[A-Za-z]{2,})$')]],
          password: [null,[Validators.required]],
          roles: [null,[Validators.required]],
        });  
      }
    }else{               
      this.registesUserForm = this.fb.group({
        id: [null],
        nombre: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.pattern('^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)(\.[A-Za-z]{2,})$')]],
        password: [null],
        roles: [null],
      });
      this.registesUserForm.get("id").setValue(this.user.id);
      this.registesUserForm.get("nombre").setValue(this.user.nombre);
      this.registesUserForm.get("email").setValue(this.user.email);
      this.registesUserForm.get("roles").setValue(this.user.rol.id);      
    }
   
  }





  /**
   *@description METODO QUE ENVIA EL REGISTRO DEL FORMULARIO. 
  */
  submitForm(): void {





    console.log('enviar');
    for (const i in this.registesUserForm.controls) {
      this.registesUserForm.controls[i].markAsDirty();
      this.registesUserForm.controls[i].updateValueAndValidity();
    }
    let payload = {};


    if (this.registesUserForm.valid) {
      this.spinner.show();      
      payload['nombre'] = this.registesUserForm.value.nombre;
      payload['email'] = this.registesUserForm.value.email;
      payload['password'] = this.registesUserForm.value.password;
      payload['rol_id'] = this.registesUserForm.value.roles;
      if (this.authService.currentUserValue.is_admin) {
        payload['restaurante_id'] = localStorage.getItem("restaurante_seleccionado_id");
      }
      console.log(payload);

      this.userService.registerUser(payload).subscribe(res => {
        console.log(res);
        this.modal.destroy({ data: true });
        this.spinner.hide();
      }, err => {
        // this.mensaje = "Ocurrio un problema."
        this.mensaje = err.error.errors
        this.error = true;
        this.spinner.hide();
        console.log(err);
      });

    }


  }



  
  /**
   *@description METODO QUE EDITA EL USUARIO. 
  */
 editUser(): void {

  for (const i in this.registesUserForm.controls) {
    this.registesUserForm.controls[i].markAsDirty();
    this.registesUserForm.controls[i].updateValueAndValidity();
  }
  let payload = {};


  if (this.registesUserForm.valid) {
    this.spinner.show();      
    payload['id'] = this.registesUserForm.value.id;
    payload['nombre'] = this.registesUserForm.value.nombre;
    payload['email'] = this.registesUserForm.value.email;
    payload['password'] = this.registesUserForm.value.password;
    payload['rol_id'] = this.registesUserForm.value.roles;   
    
    console.log(payload);

    this.userService.put(payload).subscribe(res => {
      console.log(res);
      this.modal.destroy({ data: true });
      
      this.spinner.hide();
    }, err => {
      // this.mensaje = "Ocurrio un problema."
      this.mensaje = err.error.errors
      this.error = true;
      this.spinner.hide();
      console.log(err);
    });
    this.registesUserForm.get("roles").setValue(this.user.rol.id);

  }


}


 

}
