import { PedidoFormComponent } from './../../forms/pedido/pedido.component';
import { ModalCompraComponent } from './../../forms/compra/modal-compra.component';
import { ModalVentasComponent } from './../../forms/modal-ventas/modal-ventas.component';
import { DevolucionComponent } from './../../forms/devolucion/devolucion.component';
import { InsumoService } from './../../shared/services/insumo.service';
import { MovimientoInternoComponent } from './../../forms/movimiento-interno/movimiento-interno.component';
import { MovimientoComponent } from '../../forms/movimiento/movimiento.component';
import { Component, OnInit } from '@angular/core';
import { MovimientosService } from 'src/app/shared/services/movimientos.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service';
import { TokenStorage } from 'src/app/shared/util/token.storage';
import { NzModalService } from 'ng-zorro-antd';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-movimientos',
  templateUrl: './movimientos.component.html',
  styleUrls: ['./movimientos.component.css']
})
export class MovimientosComponent implements OnInit {
  success = false;
  mensaje: any = "";
  error = false;
  banView: boolean = false;
  movimientoslist=[];
  movimientoskardexlist=[];
  auxmovimientoslist:any;
  radioValue="all";
  insumoslist=[];
  selectMov="Transacciones";
  insumoKardex;
  moneda: String = "";
  constructor(
    private modalService: NzModalService,
    private movimientosService:MovimientosService,
    private spinner: NgxSpinnerService,
    private mensajeService: MensajeComponentService,
    private restauranteService:RestaurtantService,
    private tokenStorage: TokenStorage,
    private insumos:InsumoService
  ) {
    this.mensajeService.changeMessage("/pages/movimientos");
    this.mensajeService.currentMessage.subscribe(message => {
      switch (message) {


        case 'deleteMovimiento':

          break;
        case 'updateMovimiento':

          break;
        default:
          break;
      }
    });
   }

  ngOnInit() {

    this.listarMovimientos();
    this.cargarInsumos();
    this.cargarMoneda();
  }

  /**
   * @description Obtener la moneda del restaurante
   */
  cargarMoneda(){
    this.restauranteService.getMoneda(localStorage.getItem('restaurante_seleccionado_id')).subscribe(res => {
      this.moneda = res.moneda;
      console.log("moneda cargada" +this.moneda)
    })
  }

  abrirSalida(): void {
    const modal = this.modalService.create({
      nzContent: MovimientoComponent,
      nzFooter: null,
      nzWidth: 1000,
      nzComponentParams: {
        tipo: "otra_salida"
      },
      nzMaskClosable:false

    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {

        this.listarMovimientos();


        this.mensaje = "Registro exitoso.";
        this.success = true;
      }
    });
  }

  abrirEntrada(): void {
    const modal = this.modalService.create({
      nzContent: MovimientoComponent,
      nzFooter: null,
      nzWidth: 1000,
      nzComponentParams: {
        tipo: "otra_entrada"
      },
      nzMaskClosable:false

    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.listarMovimientos();
        this.mensaje = "Registro exitoso.";
        this.success = true;
      }
    });
  }

  interno(): void {
    const modal = this.modalService.create({
      nzContent: MovimientoInternoComponent,
      nzFooter: null,
      nzWidth: 1000,
      nzMaskClosable:false
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.listarMovimientos();
        this.mensaje = "Registro exitoso.";
        this.success = true;
      }
    });
  }

  listarMovimientos(){
    this.movimientosService.getMovimientos().subscribe(res => {
      this.movimientoslist = res;
      this.auxmovimientoslist=res;
      console.log(this.movimientoslist, "todos los movimientos")
    })
  }

  radioGroup(){
    console.log(this.radioValue);
    switch (this.radioValue) {
      case "all":
        this.movimientoslist=this.auxmovimientoslist;
        break;
      default:
        this.movimientoslist=this.auxmovimientoslist.filter(item => item.tipo === this.radioValue);
        break;
    }
  }



  cargarInsumos(){
    this.insumos.getInsumos().subscribe(res => {

      console.log(res, "insumos registrados");
      this.insumoslist = res;
    }, err => {

      console.log(err);
    });
  }



  kardexChange(){
    console.log("JASJASDJ",this.insumoKardex);
    this.insumos.getKardexInsumo(this.insumoKardex).subscribe(res=>{
        console.log(res);
        this.movimientoskardexlist=res;
    },err=>{
      console.log(err);
    });
  }



  abirMovimiento(movimiento){
    switch (movimiento.tipo) {
      case "devolucion_compra":
        this.openDevoluciones(movimiento);
        break;
      case "venta":
        this.openVentas(movimiento);
        break;
      case "atencion":
        this.openVentas(movimiento);
        break;
      case "regalo":
        this.openVentas(movimiento);
        break;
      case "compra":
        this.openCompra(movimiento);
        break;
      case "precompra":
        this.openPreCompra(movimiento);
        break;
      case "otra_entrada":
        this.openEntradaSalida(movimiento);
        break;
      case "otra_salida":
        this.openEntradaSalida(movimiento);
        break;
      case "interno":
        this.openInterno(movimiento);
        break;

      default:
        break;
    }
  }





  openDevoluciones(object){
    const modal = this.modalService.create({
      nzContent: DevolucionComponent,
      nzFooter: null,
      nzWidth:900,
      nzComponentParams: {
        objetoEditar :object,
      }

  });
  modal.afterClose.subscribe((result) => {
    console.log('[afterClose] The result is:', result)
    if(result){
      this.listarMovimientos();
    }
  });
  }


    /**
   * @description
   * @author
   */
  openVentas(object): void {
    const modal = this.modalService.create({
        nzContent: ModalVentasComponent,
        nzFooter: null,
        nzWidth:900,
        nzComponentParams: {
          objetoEditar : object,
        }

    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){
        this.listarMovimientos();
      }
    });
  }



  /**
  * @description
  * @author
  */
 openCompra(object): void {
  const modal = this.modalService.create({
    nzContent: ModalCompraComponent,
    nzFooter: null,
    nzWidth: 1000,
    nzComponentParams: {
      objetoEditar: object,
      tipo:'ver'
    }

  });
  modal.afterClose.subscribe((result) => {
    console.log('[afterClose] The result is:', result)
    if (result) {
      this.listarMovimientos();
    }
  });
}

/**
  * @description
  * @author
  */
 openPreCompra(object): void {
  const modal = this.modalService.create({
    nzContent: PedidoFormComponent,
    nzFooter: null,
    nzWidth: 1000,
    nzComponentParams: {
      objetoEditar: object,
    }

  });
  modal.afterClose.subscribe((result) => {
    console.log('[afterClose] The result is:', result)
    if (result) {
      this.listarMovimientos();
    }
  });
}



openEntradaSalida(object): void {


  const modal = this.modalService.create({
    nzContent: MovimientoComponent,
    nzFooter: null,
    nzWidth: 1000,
    nzComponentParams: {
      tipo: object.tipo,
      objetoEditar: object
    },

    nzMaskClosable:false

  });
  modal.afterClose.subscribe((result) => {
    console.log('[afterClose] The result is:', result)
    if (result) {
      this.listarMovimientos();
      this.mensaje = "Registro exitoso.";
      this.success = true;
    }
  });
}




openInterno(object): void {


  const modal = this.modalService.create({
    nzContent: MovimientoInternoComponent,
    nzFooter: null,
    nzWidth: 1000,
    nzComponentParams: {
      objetoEditar: object
    },

    nzMaskClosable:false

  });
  modal.afterClose.subscribe((result) => {
    console.log('[afterClose] The result is:', result)
    if (result) {
      this.listarMovimientos();
      this.mensaje = "Registro exitoso.";
      this.success = true;
    }
  });
}




fileName = 'All_Movimientos';
exportarMovimientos(){
  this.insumos.exportarMovimientos().subscribe(res=>{
    // var file = new Blob([res], {type: 'application/xlxs'});
    // var fileURL = URL.createObjectURL(file);

    // Open new windows and show PDF


    const blob = new Blob([res], { type : 'application/xlxs' });
    const file = new File([blob], this.fileName + '.xlsx', { type: 'application/xlxs' });
    saveAs(file);
  },err=>{
    // console.log(err);
  });

}




fileNameKardex = 'Kardex_';
exportarKardex(){
  this.insumos.exportarKardex(this.insumoKardex).subscribe(res=>{
    // var file = new Blob([res], {type: 'application/xlxs'});
    // var fileURL = URL.createObjectURL(file);

    // Open new windows and show PDF


    const blob = new Blob([res], { type : 'application/xlxs' });
    const file = new File([blob], this.fileNameKardex +this.insumoKardex+ '.xlsx', { type: 'application/xlxs' });
    saveAs(file);
  },err=>{
    // console.log(err);
  });

}
}
