import { NzTableModule } from 'ng-zorro-antd/table';
import { FormsAppModule } from './../forms/forms.module';
import { ComponentsModule } from './../components/components.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PagesRoutingModule } from './pages-routing.module';
import { NgZorroAntdModule} from 'ng-zorro-antd';
import { CommonModule } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import { NgChartjsModule } from 'ng-chartjs';
import { ThemeConstantService } from '../shared/services/theme-constant.service';
import es from '@angular/common/locales/es';
import { ProveedoresComponent } from './proveedores/proveedores.component';
import { InsumosComponent } from './insumos/insumos.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MenusComponent } from './menus/menus.component';
import { RecetasComponent } from './recetas/recetas.component';
import { SubrecetasComponent } from './subrecetas/subrecetas.component';
import { ComprasComponent } from './compras/compras.component';
import { DevolucionesComponent } from './devoluciones/devoluciones.component';
import { VentasComponent } from './ventas/ventas.component';
import { InventariosComponent } from './inventarios/inventarios.component';
import { MovimientosComponent } from './movimientos/movimientos.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { SugerenciasComponent } from './sugerencias/sugerencias.component';

registerLocaleData(es);
@NgModule({
    imports: [
        CommonModule,
        NgZorroAntdModule,
        ReactiveFormsModule,
        FormsModule,
        NgChartjsModule,
        PagesRoutingModule,
        ComponentsModule,
        FormsAppModule,
        NzRadioModule,
        NgxMaskModule.forRoot(options),
        NzDropDownModule,
        NzTableModule
    ],
    declarations: [
        ProveedoresComponent,
        InsumosComponent,
        ConfiguracionComponent,
        LandingPageComponent,
        MenusComponent,
        RecetasComponent,
        SubrecetasComponent,
        ComprasComponent,
        DevolucionesComponent,
        VentasComponent,
        InventariosComponent,
        MovimientosComponent,
        SugerenciasComponent,
    ],
    providers: [
        ThemeConstantService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class PagesModule {}
