import { MovimientosService } from 'src/app/shared/services/movimientos.service';
import { FormDestinoComponent } from 'src/app/forms/destino/destino.component';
import { AgregarMedidaComponent } from './../agregar-medida/agregar-medida.component';
import { AlmacenService } from './../../shared/services/almacen.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { InsumoComponent } from './../insumo/insumo.component';
import { InsumoService } from './../../shared/services/insumo.service';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-movimiento-interno',
  templateUrl: './movimiento-interno.component.html',
  styleUrls: ['./movimiento-interno.component.css']
})
export class MovimientoInternoComponent implements OnInit {
  @Input() objetoEditar: any;
  form: FormGroup;
  insumoslist=[];
  almacenlist=[];
  error = false;
  success = false;
  mensaje = "";
  constructor(    
    private fb: FormBuilder,
    private modalService: NzModalService,
    private modal: NzModalRef,
    private insumoService: InsumoService,
    private spinner: NgxSpinnerService,
    private transaccionService: MovimientosService,
    private almacenService: AlmacenService,
    private movimientoService: MovimientosService
    ) {
    this.form = this.fb.group({
      tipo: ['interno', [Validators.required]],
      fecha: [null, [Validators.required]],
      restaurante_id: [Number(localStorage.getItem('restaurante_seleccionado_id')), [Validators.required]],      
      insumos_movidos: this.fb.array([this.crearInsumosMovidos()]),
    });
   }

  ngOnInit() {
    this.insumoService.indexWithUnit().subscribe(res => {
      console.log(res, "insumos");
      this.insumoslist = res;      
      
    if(this.objetoEditar){
      this.form= this.fb.group({
        tipo: ['interno', [Validators.required]],
        fecha: [formatDate(this.objetoEditar.fecha, 'yyyy-MM-dd HH:mm:ss', 'en'), [Validators.required]],
        restaurante_id: [Number(localStorage.getItem('restaurante_seleccionado_id')), [Validators.required]],      
        insumos_movidos: this.fb.array([]),
      });      
      this.movimientoService.get(this.objetoEditar).subscribe(res => {
        console.log(res, "objeto con id");
        res.insumos_movidos.forEach(element => {
          // this.agregarItem(element);
          let control = <FormArray>this.form.controls['insumos_movidos'];
          console.log(control);
          let data =this.fb.group({                
            cantidad: [element.cantidad, [Validators.required]],            
            insumo_id: [element.insumo_id, [Validators.required]],
            unidad_medida_id: [element.unidad_medida_id, [Validators.required]],
            almacen_origen_id: [element.almacen_origen_id, [Validators.required]],                                            
            almacen_destino_id: [element.almacen_destino_id, [Validators.required]],
            
          });
          control.push(data);        

        });
        
      });
    }    
    });
    this.almacenService.index().subscribe(res => {
      this.almacenlist = res;
      console.log(this.almacenlist, "aqui los almacenes")
    });

  }


  crearInsumosMovidos(): FormGroup {
    return this.fb.group({
      cantidad: [null, [Validators.required]],
      insumo_id: [null, [Validators.required]],
      unidad_medida_id: [null, [Validators.required]],
      almacen_origen_id: [null, [Validators.required]],
      almacen_destino_id: [null, [Validators.required]],
    });

  }

  getInsumosMovidos(): FormArray {
    return this.form.get("insumos_movidos") as FormArray;
  }


   
    /**
   * Obtener las opciones de unidades de medidas de un insumo
   * @param insumo_id
   * @return Array de unidades de medidas 
   */
  getUnidadesMedidasInsumo(insumo_id: number) {
    if(insumo_id!=0){
      return (insumo_id && this.insumoslist && this.insumoslist.length) ? this.insumoslist.find(element => element.id == insumo_id).unidades_medidas_opciones : [];
    }
  }


  borrarItemInsumoEntrada(i: number) {

    (<FormArray>this.form.controls['insumos_movidos']).removeAt(i);
  }


  agregarItemInsumo() {
    let control = <FormArray>this.form.controls['insumos_movidos'];
    control.push(this.crearInsumosMovidos())
  }



  nuevoInsumo(ev) {
    
    this.form.value.insumos_movidos.forEach(element => {

      if (element.insumo_id == "") {
        // this.mostrarPlusMovido = true
        const modal = this.modalService.create({
          nzContent: InsumoComponent,
          nzFooter: null,


        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
            this.insumoService.indexWithUnit().subscribe(res => {
              console.log(res, "insumos");
              this.insumoslist = res;      
            });
          }
        });

      } 

    });



    let controls = <FormArray>this.form.controls['insumos_movidos'];
   
    this.insumoslist.forEach(element => {
      if (element.id == ev) {
        controls.controls.forEach(elemento => {
          if (elemento.value.insumo_id == ev) {
          
            elemento.get('unidad_medida_id').setValue(element.unidad_medida_id);

          }
        });
      }

    });

  }




 
  submitEntrada(): void {
    
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();

    }  

    
    for (let i of this.getInsumosMovidos().controls) {

      for (let a in i['controls']) {
        
        i['controls'][a].markAsDirty();
        i['controls'][a].updateValueAndValidity();
      }
    }

    if (this.form.valid) {
      this.spinner.show();
      let fecha = formatDate(this.form.value.fecha, 'yyyy-MM-dd HH:mm:ss', 'en');
      this.form.get('fecha').setValue(fecha);

      // let control = <FormArray>this.form.controls['insumos_movidos'];
      // control.controls.forEach(elemento => {
      //   elemento.get('fecha_vencimiento').setValue(formatDate(elemento.value.fecha_vencimiento, 'yyyy-MM-dd ', 'en'));
      // });

      let servicio = this.transaccionService.post(this.form.value)
      servicio.subscribe(res => {
        console.log(res);
        if (res) {
          this.modal.destroy({ data: true });
          this.spinner.hide();
        }
      }, err => {
        // this.spinner.hide();
        this.mensaje = err.error.errors;
        this.error = true;
        this.spinner.hide();
        console.log(err);
      });

    }


  }



  nuevaMedida() {
    this.form.value.insumos_movidos.forEach(element => {

      if (element.unidad_medida_id == 0) {
        
        const modal = this.modalService.create({
          nzContent: AgregarMedidaComponent,
          nzFooter: null,


        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
            this.mensaje = "Registro exitoso.";
            this.success = true;
          }
        });

      } else if (element.unidad_medida_id >= 1) {
        
      }

    });

  }



  nuevoAlmacen(type){
    
    this.form.value.insumos_movidos.forEach(element => {
      console.log(element,type)
      if ((type=="destino"&&element.almacen_destino_id == "")||(type=="origen"&&element.almacen_origen_id == "")) {
        
         const modal = this.modalService.create({
        nzContent: FormDestinoComponent,
        nzFooter: null,

      });
      modal.afterClose.subscribe((result) => {        
        console.log('[afterClose] The result is:', result)
        if (result) {          
          this.almacenService.index().subscribe(res => {
            this.almacenlist = res;
            console.log(this.almacenlist, "aqui los almacenes")
          });
        }
      });
      }
    });
    
  
    
  }
}
