import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rutaApi } from '../util/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComprasService {

  constructor(private http: HttpClient) { }

  /**
  *
  * @description obtiene las compras
  * @author Gabriel Baldez
  * @param tipo [compra,precompra]
  * @returns Observable<any>
  */
  public index(tipo): Observable<any> {
    let params;
    if(tipo=="precompra"){
      params = {
        restaurante_id:localStorage.getItem('restaurante_seleccionado_id'),
        filter_tipo:'precompra'
      }
    }else if(tipo=="compra"){
      params = {
        restaurante_id:localStorage.getItem('restaurante_seleccionado_id'),
        filter_tipo:'compra'
      }
    }
    return this.http.get<any>(rutaApi('/compra'),{params:params});
  }

  /**
*
* @description Obtener devoluciones by id
* @author Carlos diaz
* @returns Observable<any>
*/
  public getCompra(id): Observable<any> {
    return this.http.get<any>(rutaApi('/compra/' + id + ''));
  }


  /**
  *
  * @description registrar la compra
  * @author Gabriel Baldez
  * @returns Observable<any>
  */
 public crearCompra(object): Observable<any> {

  return this.http.post<any>(rutaApi('/compra'), object);
}
  /**
  *
  * @description registrar la compra
  * @author Gabriel Baldez
  * @returns Observable<any>
  */
  public crearPreCompra(object): Observable<any> {

    return this.http.post<any>(rutaApi('/compra?precompra=1'), object);
  }


  /**
   *
   * @description Listado de insumos con unidad de medida
   * @author Gabriel Baldez
   * @returns Observable<any>
   */
  public indexWithUnit(): Observable<any> {
    let rest = localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/insumo?restaurante_id=' + rest +'&with_unidades_medidas_opciones=yes'));
}


  /**
     *
     * @description Editar la compra
     * @author Gabriel Baldez
     * @returns Observable<any>
     */


  public editarCompra(object): Observable<any> {

    return this.http.put<any>(rutaApi('/compra/' + object.id + ''), object);
  }

  /**
   * @description Eliminar objeto
   * @author Javier Evans
   * @returns Observable<any>
   */
  public deleteCompra(object): Observable<Array<any>> {
    return this.http.delete<any>(rutaApi('/compra/' + object.id));
  }

  /**
   * @description Aprobar compra
   * @author Carlos diaz
   * @returns Observable<any>
   */
  public aprobarCompra(id,payloads): Observable<Array<any>> {
    return this.http.post<any>(rutaApi('/compra/'+id+'/aprobar_precompra'),payloads);
  }




  /**
   * @description Descargar pdf pedido
   * @author
   * @returns Observable<any>
   */
  public download(object): Observable<any>{
    return this.http.get(rutaApi('/compra/'+object+'/pdf' ),{ responseType: 'blob' });
  }

}
