import { rutaApi } from 'src/app/shared/util/constants';
import { UploadFile } from 'ng-zorro-antd/upload';
import { VentasService } from './../../shared/services/ventas.service';
import { Component, OnInit } from '@angular/core';
import { ModalVentasComponent } from 'src/app/forms/modal-ventas/modal-ventas.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { HttpClient } from '@angular/common/http';
import { TokenStorage } from 'src/app/shared/util/token.storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.css']
})
export class VentasComponent implements OnInit {
  success = false;
  mensaje:any="";
  error = false;
  ventalist:any=[];
  banView: boolean = false;
  constructor(
    private modalService: NzModalService,
    private mensajeService: MensajeComponentService,
    private spinner: NgxSpinnerService,
    private tokenStorage: TokenStorage,
    private http: HttpClient,
    private msg: NzMessageService,
    private VentasService:VentasService,
    private mensajeComponentService: MensajeComponentService,
  ) {

    this.mensajeService.currentMessage.subscribe(message => {
      switch (message) {


        case 'deleteVentas':
          // this.listarCompras();
          break;
        case 'updateVentas':
          this.listarVentas();
          this.success = true;
          this.mensaje = "Registro editado exitosamente.";
          break;
        default:
          break;
      }
    });
   }

  ngOnInit() {
    this.mensajeService.changeMessage("/pages/ventas");
    this.listarVentas();
  }


  nuevaVenta(ventica): void {
    console.log(ventica,"venta para editar")
    const modal = this.modalService.create({
      nzContent: ModalVentasComponent,
      nzFooter: null,
      nzWidth: 1100,
      nzMaskClosable:false,
      nzComponentParams: {
        objetoEditar: ventica,
      }

    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.listarVentas();
        this.mensaje = "Registro exitoso.";
        this.success = true;
      }
    });
  }

  eliminarVenta(ventica){
    this.spinner.show();
    this.VentasService.delete(ventica).subscribe(res => {
      console.log(res,"entre al servicio eliminar")
      this.mensajeComponentService.changeMessage("deleteVenta");
      this.spinner.hide();
      this.listarVentas();
    }, err => {
      console.log(err);
      this.spinner.hide();
    });

  }

  listarVentas(){
    this.VentasService.indexWithRestaurante().subscribe(res=>{
      this.banView=true;
      this.ventalist=res;
      console.log(this.ventalist,"todas las ventas")
    })
  }



     /** Manejar los mensajes cuando se està cargando un archivo **/
     handleChange({ file, fileList }): void {

      console.log("change", file);

      const status = file.status;

      switch (status){
        case "uploading":
          this.success = false;
          this.error = false;
          this.spinner.show();
        break;

        case "done":
          this.mensaje="Importación exitosa."
          this.listarVentas();

          this.success = true;
          this.spinner.hide();
          break;
        case "error":
          console.log(file,"holaaaa");
          let error_file = file.error.error.errors.file;
          let errores_validacion = [];
          let nombres=[];

          if(error_file){
            console.log(error_file,"holaa2")
            this.mensaje = "Ocurrio un problema: " + error_file;
          }
          else{
            this.mensaje = "Ocurrio un problema";

            let errors = file.error.error.errors;
            console.log(errors,"holaaa3")

            if(errors.length){

              errores_validacion.push( "El archivo cargado contiene errores: \n\n" );

              // console.log(Object.keys(errors))

              errors.forEach((element) => {

                console.log(Object.keys(element),"nombres")

                nombres = Object.keys(element)

                errores_validacion.push( element[nombres[0]])


                
              });

              this.mensaje =  errores_validacion
            }
          }

          this.error = true;
          this.spinner.hide();

          // if(errores_validacion.length)
          //   setTimeout(()=>{
          //     alert( errores_validacion.join("\n") );
          //   }, 500)

        break;
      }




    }

    setMediaUploadHeaders = (file: UploadFile) => {
      return {
        "Accept": "application/json",
        "Authorization": "Bearer "+this.tokenStorage.getToken(),
      }
    };
    rutaSubirExcel(){
      
      return rutaApi('/venta/cargar_excel?restaurante_id=' + localStorage.getItem("restaurante_seleccionado_id"));
    }



}
