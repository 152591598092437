import { AreaReceta } from './../../shared/interfaces/area-receta.type';
import { AgregarAreaRecetaComponent } from '../../forms/agregar-area-receta/agregar-area-receta.component';
import { AreaRecetaService } from 'src/app/shared/services/area-receta.service';
import { NzModalService } from 'ng-zorro-antd';
import { MensajeComponentService } from './../../shared/services/mensaje-component.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-area-receta',
  templateUrl: './card-area-receta.component.html',
  styleUrls: ['./card-area-receta.component.css']
})
export class CardAreaRecetaComponent implements OnInit {

  @Input() Area : AreaReceta

  constructor(
    private spinner:NgxSpinnerService,
    private area: AreaRecetaService,
    private router:Router,
    private mensajeComponentService:MensajeComponentService,
    private modalService:NzModalService,
  ) { }

  ngOnInit() {
  }

  /**
   * @description borrar la unidad de medida
   * @author Javier Evans
   */
  confirm(): void {
    this.spinner.show();
    this.area.delete(this.Area).subscribe(res=>{
      console.log(res);
    //  this.mensajeComponentService.changeMessage("deleteAreaReceta");    
      this.mensajeComponentService.changeMessage("updateAreaRecetas");         
      this.spinner.hide();
    },err=>{
      this.spinner.hide();      
    });
  }


  /**
   * @description Actualizar una unidad de medida
   * @author Javier Evans
   */  
  editar(): void {

    console.log("editando");
    console.log(this.Area);

    const modal = this.modalService.create({
        nzContent: AgregarAreaRecetaComponent,
        nzFooter:null,        
        nzComponentParams: {
          areaEditar : this.Area,          
        }
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){        
        this.mensajeComponentService.changeMessage("updateAreaRecetas");
      }
    });
  }

}
