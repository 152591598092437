import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { formatDate } from '@angular/common';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { NgxSpinnerService } from 'ngx-spinner';
import { VentasService } from 'src/app/shared/services/ventas.service';
import { RecetaService } from 'src/app/shared/services/receta.service';
import { Util } from 'src/app/shared/util/util';
import { MenuService } from 'src/app/shared/services/menu.service';
import { FormMenuComponent } from '../menu/menu.component';
import { InsumoService } from 'src/app/shared/services/insumo.service';
import { AlmacenService } from 'src/app/shared/services/almacen.service';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service';
import { InsumoComponent } from '../insumo/insumo.component';
import { FormDestinoComponent } from '../destino/destino.component';
import { UnidadMedidaService } from 'src/app/shared/services/unidad-medida.service';
import { AgregarMedidaComponent } from '../agregar-medida/agregar-medida.component';

@Component({
  selector: 'app-modal-ventas',
  templateUrl: './modal-ventas.component.html',
  styleUrls: ['./modal-ventas.component.css']
})
export class ModalVentasComponent implements OnInit {
  @Input() objetoEditar: any = null;  // Aquí se guarda el objeto a editar (cuando corresponde)
  form: FormGroup;
  menuRecetaList: any;
  banView = true;
  error = false;
  mensaje = "";
  success = false;
  mostrarPlus = false;
  mostrarPlusMovido = false;
  insumoslist: any;
  almacenlist:any;
  unidadeslist:any;
  moneda: String = "";
  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private modal: NzModalRef,
    private spinner: NgxSpinnerService,
    private ventasService: VentasService,
    private recetaService: RecetaService,
    private insumoService:InsumoService,
    private almacenService:AlmacenService,
    private unidadesService:UnidadMedidaService,
    private restauranteService:RestaurtantService,
  ) {

  }

  ngOnInit() {

    if (this.objetoEditar) {
      this.ventasService.get(this.objetoEditar.id).subscribe(res=>{
        console.log(res,"OBJETO EDITARRR");
        this.objetoEditar=res;
          // Se llenan campos del formulario

          this.insumoService.indexWithUnit().subscribe(res => {
            console.log(res, "insumos");
            this.insumoslist = res;


        this.form = this.fb.group({
          id:[this.objetoEditar.id,[Validators.required]],
          fecha: [this.objetoEditar.fecha, [Validators.required]],
          tipo_venta: [this.objetoEditar.tipo_venta, [Validators.required]],
          referencia: [this.objetoEditar.referencia, [Validators.required]],
          tipo: [this.objetoEditar.tipo, [Validators.required]],
          restaurante_id: [Number(localStorage.getItem('restaurante_seleccionado_id')), [Validators.required]],
          items_venta: this.fb.array([]),
          insumos_movidos: this.fb.array([]),
          total: [0],
        });

        let control = <FormArray>this.form.controls['items_venta'];
        this.objetoEditar.items_venta.forEach(element => {
          let objeto
          if(element.receta_menu.tipo=="receta") {
            objeto = this.menuRecetaList.recetas.find(sub => sub.id == element.receta_menu.id);
          }else{
            objeto = this.menuRecetaList.menus.find(sub => sub.id == element.receta_menu.id);
          }
          console.log(element)
          control.push(this.fb.group({
            cantidad: [element.cantidad, [Validators.required, Validators.min(1)]],
            precio_unitario: [element.precio_unitario, [Validators.required]],
            receta_id: [element.receta_id, [Validators.required]],
            total: [null],
            alergenos: [this.getMensajeAlergenos(objeto.alergenos)],
          }));
        });
        control = <FormArray>this.form.controls['insumos_movidos'];
        this.objetoEditar.insumos_movidos.forEach(element => {
          console.log(element)
          let objeto = this.insumoslist.find(sub => sub.id == element.insumo_id);
          control.push(this.fb.group({
            cantidad: [element.cantidad_absoluta, [Validators.required, Validators.min(0.1)]],
            precio_unitario: [element.precio_unitario, [Validators.required]],
            insumo_id: [element.insumo_id, [Validators.required]],
            unidad_medida_id: [element.unidad_medida_id, [Validators.required]],
            almacen_origen_id: [element.almacen_origen_id, [Validators.required]],
            alergenos: [this.getMensajeAlergenos(objeto.alergenos)],
            total: [null],
          }));
        });
        this.calculosMenus();
        this.calculosInsumosMovidos();
      });

      },err=>{
        console.log(err);
      });
    }else{
      this.form = this.fb.group({
        fecha: [null, [Validators.required]],
        tipo_venta: [null, [Validators.required]],
        tipo: [null, [Validators.required]],
        referencia: [null, [Validators.required]],
        restaurante_id: [Number(localStorage.getItem('restaurante_seleccionado_id')), [Validators.required]],
        items_venta: this.fb.array([this.crearItemsVentas()]),
        insumos_movidos: this.fb.array([]),
        total: [0],
      });

      this.insumoService.indexWithUnit().subscribe(res => {
        console.log(res, "insumos");
        this.insumoslist = res;
      });

    }

    this.recetaService.receta_menu().subscribe(res => {
      this.menuRecetaList = res;
      console.log(this.menuRecetaList, "aqui los menus")
    })



    this.almacenService.index().subscribe(res => {
      this.almacenlist = res;
      console.log(this.almacenlist, "aqui los almacenes")
    })

    this.unidadesService.getMedida().subscribe(res => {
      this.unidadeslist = res;
      console.log(this.unidadeslist, "aqui las unidades de medida")
    })

    // Obtener la moneda del restaurante
    this.restauranteService.getMoneda(localStorage.getItem('restaurante_seleccionado_id')).subscribe(res => {
      this.moneda = res.moneda;
    })

  }

  agregarItem() {


    let control = <FormArray>this.form.controls['items_venta'];

    control.push(this.crearItemsVentas())

    console.log(this.form.get("items_venta").value);

    console.log(this.getItemsVentas().controls);
  }

  agregarItemInsumo() {


    let control = <FormArray>this.form.controls['insumos_movidos'];

    control.push(this.crearInsumosMovidos())

    console.log(this.form.get("insumos_movidos").value);

    console.log(this.getInsumosMovidos().controls);
  }


  crearItemsVentas(): FormGroup {
    return this.fb.group({
      cantidad: [null, [Validators.required, Validators.min(1)]],
      precio_unitario: [null, [Validators.required]],
      receta_id: [null, [Validators.required]],
      total: [null],
      alergenos: [null],
    });

  }

  crearInsumosMovidos(): FormGroup {
    return this.fb.group({
      cantidad: [null, [Validators.required, Validators.min(0.1)]],
      precio_unitario: [null, [Validators.required]],
      insumo_id: [null, [Validators.required]],
      unidad_medida_id: [null, [Validators.required]],
      almacen_origen_id: [null, [Validators.required]],
      total: [null],
      alergenos: [null],
    });





  }

  getItemsVentas(): FormArray {
    return this.form.get("items_venta") as FormArray;
  }

  getInsumosMovidos(): FormArray {
    return this.form.get("insumos_movidos") as FormArray;
  }

  borrarItem(i: number) {

    (<FormArray>this.form.controls['items_venta']).removeAt(i);

    this.calculosMenus();
  }


  borrarItemInsumo(i: number) {

    (<FormArray>this.form.controls['insumos_movidos']).removeAt(i);

    this.calculosMenus();
  }



  nuevoMenu(ev) {
    // this.form.value.items_venta.forEach(element => {

    //   if (element.receta_id == "") {

    //     const modal = this.modalService.create({
    //       nzContent: FormMenuComponent,
    //       nzFooter: null,
    //       nzWidth: 900

    //     });
    //     modal.afterClose.subscribe((result) => {

    //       console.log('[afterClose] The result is:', result)
    //       if (result) {
    //         // this.mensaje = "Registro exitoso.";
    //         // this.success = true;
    //         this.menuService.index().subscribe(res => {
    //           this.menuRecetaList = res;
    //           console.log(this.menuRecetaList, "aqui los menus")
    //         });
    //       }
    //     });

    //   }

    // });




    let controls = <FormArray>this.form.controls['items_venta'];

    this.menuRecetaList.recetas.forEach(element => {
      if (element.id == ev && element.tipo=="receta") {


        controls.controls.forEach(elemento => {
          if (elemento.value.receta_id == ev) {
              elemento.get('precio_unitario').setValue(element.precio_venta);
              elemento.get('alergenos').setValue(this.getMensajeAlergenos(element.alergenos));


          }
        });
      }

    });
    this.menuRecetaList.menus.forEach(element => {
      if (element.id == ev && element.tipo=="menu") {
        controls.controls.forEach(elemento => {
          if (elemento.value.receta_id == ev) {
              elemento.get('precio_unitario').setValue(element.precio_venta);
              elemento.get('alergenos').setValue(this.getMensajeAlergenos(element.alergenos));


          }
        });
      }

    });
    this.calculosMenus();
  }


  nuevaMedida(event,items) {
    this.form.value.insumos_movidos.forEach(element => {

      if (element.unidad_medida_id == 0) {
        this.mostrarPlusMovido = true
        const modal = this.modalService.create({
          nzContent: AgregarMedidaComponent,
          nzFooter: null,


        });
        modal.afterClose.subscribe((result) => {


          console.log('[afterClose] The result is:', result)
          if (result) {
            this.mensaje = "Registro exitoso.";
            this.success = true;
          }
        });

      } else if (element.unidad_medida_id >= 1) {
        this.mostrarPlusMovido = false
      }

    });

    this.actualizarTotalInsumoWithEquivalencia(event,items);
    // items.get('total').setValue();
  }


  nuevoInsumo(ev) {
    this.form.value.insumos_movidos.forEach((element,index) => {

      //SETEAR LOS ALERGERNOS
      let objeto = this.insumoslist.find(sub => sub.id == element.insumo_id);
      let control = <FormArray>this.form.controls['insumos_movidos'];
      if(objeto)
        control.controls[index].get('alergenos').setValue(this.getMensajeAlergenos(objeto.alergenos));


      if (element.insumo_id == "") {

        const modal = this.modalService.create({
          nzContent: InsumoComponent,
          nzFooter: null,
          nzWidth:1000


        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
            this.mensaje = "Registro exitoso.";
            this.success = true;
          }
        });

      }

    });


  let controls = <FormArray>this.form.controls['insumos_movidos'];

    this.insumoslist.forEach(element => {
      if (element.id == ev) {
        controls.controls.forEach(elemento => {
          if (elemento.value.insumo_id == ev) {
            try{
              elemento.get('precio_unitario').setValue(element.precio_estimado);
            }catch(except){

            }
            elemento.get('unidad_medida_id').setValue(element.unidad_medida_id);

          }
        });
      }

    });
    this.calculosInsumosMovidos();
  }

  nuevoAlmacen() {
    this.form.value.insumos_movidos.forEach(element => {

      if (element.almacen_origen_id == 0) {
        this.mostrarPlusMovido = true
        const modal = this.modalService.create({
          nzContent: FormDestinoComponent,
          nzFooter: null,


        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
            this.mensaje = "Registro exitoso.";
            this.success = true;
          }
        });

      } else if (element.almacen_origen_id >= 1) {
        this.mostrarPlusMovido = false
      }

    });
  }

  submitForm(): void {
    console.log("AQUIII", this.form.get("items_venta") as FormArray);
    console.log(this.form.value,"esto se mandaaaa")
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();

    }

    for (let i of this.getItemsVentas().controls) {

      for (let a in i['controls']) {
        console.log(a);
        i['controls'][a].markAsDirty();
        i['controls'][a].updateValueAndValidity();
      }
    }



    for (let i of this.getInsumosMovidos().controls) {

      for (let a in i['controls']) {
        console.log(a);
        i['controls'][a].markAsDirty();
        i['controls'][a].updateValueAndValidity();
      }
    }
    if (this.form.valid) {
      this.spinner.show();
      let fecha = formatDate(this.form.value.fecha, 'yyyy-MM-dd HH:mm:ss', 'en');
      this.form.get('fecha').setValue(fecha);

      // let servicio = this.ventasService.crearVenta(this.form.value)
      let servicio = this.objetoEditar ? this.ventasService.editar(this.form.value) : this.ventasService.crearVenta(this.form.value)
      servicio.subscribe(res => {
        console.log(res);
        if (res) {
          this.modal.destroy({ data: true });
          this.spinner.hide();
        }
      }, err => {
        // this.spinner.hide();
        if(err.error && err.error.errors){
          this.mensaje = err.error.errors;
        }else if(err.error && err.error.message){
          this.mensaje = err.error.message;
        }else if(err && err.message){
          this.mensaje = err.message;
        }
        this.error=true;
        this.spinner.hide();
        console.log(err);
      });

    }


  }





  calculosInsumosMovidos(){


        this.actualizarTotalInsumoWithEquivalencia();


  }


  calculosMenus(){

    let controls = <FormArray>this.form.controls['items_venta'];
        controls.controls.forEach(elemento => {

            elemento.get('total').setValue(elemento.value.cantidad*elemento.value.precio_unitario);

            // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
        });


         this.calculaTotal();


  }



  calculaTotal(){
    this.form.get('total').setValue(0);
    let controls;
    let total=0;
      controls = <FormArray>this.form.controls['items_venta'];
      controls.controls.forEach(elemento => {
        // total += Number.parseFloat(""+elemento.get('total').value).toFixed(2);
        total += Number(elemento.get('total').value);

        // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
    });


    controls = <FormArray>this.form.controls['insumos_movidos'];

    controls.controls.forEach(elemento => {
      // total += Number.parseFloat(""+elemento.get('total').value).toFixed(2);
      total += Number(elemento.get('total').value);

        // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
    });


        this.form.get('total').setValue(total);
  }




    /**
   * Obtener las opciones de unidades de medidas de un insumo
   * @param insumo_id
   * @return Array de unidades de medidas
   */
  getUnidadesMedidasInsumo(insumo_id: number) {
    if(insumo_id!=0){
      return (insumo_id && this.insumoslist && this.insumoslist.length) ? this.insumoslist.find(element => element.id == insumo_id).unidades_medidas_opciones : [];
    }
  }




  // actualizarTotalInsumoWithEquivalencia(event=null,items=null){
  //   console.log(items);
  //   //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL SELECCIONAR UNA UND DE MEDIDA DISTINTA A LA PRINCIPAL MENDIANTE SELECT UNIDAD
  //   if(items&&event){
  //     let objeto = this.insumoslist.find(element => element.id == items.value.insumo_id);
  //     objeto.unidades_medidas_opciones.forEach(element => {
  //       if(element.id==event){
  //         items.get('total').setValue((items.value.cantidad/element.equivalencia_unidad_principal)*items.value.precio_unitario);
  //       }
  //     });
  //   }

  //   //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL CAMBIAR LA CANTIDAD DEL INSUMO
  //   if(!items&&!event){
  //     let  controls = <FormArray>this.form.controls['insumos_movidos'];
  //     controls.controls.forEach(elemento => {
  //       let objeto = this.insumoslist.find(element => element.id == elemento.value.insumo_id);
  //       objeto.unidades_medidas_opciones.forEach(element => {
  //         if(element.id==elemento.value.unidad_medida_id){
  //           // items.get('total').setValue((items.value.cantidad/element.equivalencia_unidad_principal)*items.value.precio_unitario);
  //           elemento.get('total').setValue(elemento.value.cantidad/element.equivalencia_unidad_principal*elemento.value.precio_unitario);
  //         }
  //       });

  //         // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
  //     });
  //   }

  //   this.calculaTotal();
  // }

  actualizarTotalInsumoWithEquivalencia(event = null, items = null) {

    if (event != null && items != null) {
      //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL SELECCIONAR UNA UND DE MEDIDA DISTINTA A LA PRINCIPAL MENDIANTE SELECT UNIDAD
    if (items && event) {
      let objeto = this.insumoslist.find(element => element.id == items.value.insumo_id);
      objeto.unidades_medidas_opciones.forEach(element => {
        if (element.id == event) {
          element.equivalencia_unidad_principal == 1 ? items.get('precio_unitario').setValue(objeto.precio_estimado) : items.get('precio_unitario').setValue(objeto.precio_estimado / element.equivalencia_unidad_principal);
          items.get('total').setValue(items.value.cantidad * items.value.precio_unitario);
        }
      });
    }

    //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL CAMBIAR LA CANTIDAD DEL INSUMO
    if (!items && !event) {
      let controls = <FormArray>this.form.controls['insumos_movidos'];
      controls.controls.forEach(elemento => {
        let objeto = this.insumoslist.find(element => element.id == elemento.value.insumo_id);
        objeto.unidades_medidas_opciones.forEach(element => {
          if (element.id == elemento.value.unidad_medida_id) {
            element.equivalencia_unidad_principal == 1 ? elemento.get('precio_unitario').setValue(objeto.precio_estimado) : elemento.get('precio_unitario').setValue(objeto.precio_estimado / element.equivalencia_unidad_principal);
            elemento.get('total').setValue(elemento.value.cantidad * elemento.value.precio_unitario);
          }
        });

        // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
      });
    }
    }

    let controls = <FormArray>this.form.controls['insumos_movidos'];
    controls.controls.forEach(elemento => {

      elemento.get('total').setValue(elemento.value.cantidad * elemento.value.precio_unitario);


    });

    this.calculaTotal();

  }




  getMensajeAlergenos(alergenos){
    let nombreAlergenos=[];
    alergenos.forEach(element => {
      nombreAlergenos.push(element.nombre)
    });
    if(nombreAlergenos.length)
      return "Contiene alérgenos: "+nombreAlergenos;
    else
      return "";
  }



}
