import { MensajeComponentService } from './../../services/mensaje-component.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Component } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { RestaurtantService } from '../../services/restaurtant.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})

export class HeaderComponent{

    searchVisible : boolean = false;
    quickViewVisible : boolean = false;
    isFolded : boolean;
    isExpand : boolean;
    visible=true;
    restaurante_seleccionado;
    restaurantesList : any[];

    constructor( 
        private themeService: ThemeConstantService,
        private restaurante: RestaurtantService,
        public authService: AuthenticationService,
        private mensajeService:MensajeComponentService,
        private router:Router
        ) {
           
        }

    ngOnInit(): void {
        
       

        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.mensajeService.currentMessage.subscribe(message => {
            switch (message) {
              case 'updateRestaurantes':
                console.log("Asdas");
                this.listarRestaurantes();
              break;                                    
              default:
                break;
            }
          });
        this.listarRestaurantes();
    }

    logout(){
        this.authService.logout(true);        
    }


    listarRestaurantes(){
        if(!this.authService.currentUserValue.is_superadmin){
            this.restaurante.getRest().subscribe(res=>{    
                this.restaurantesList = res;    
                let aux;
                // Si hay un restaurante elegido en localStorage, asignarlo al modelo 'restaurante_seleccionado'
                if ( aux = localStorage.getItem("restaurante_seleccionado_id")){
                    this.restaurante_seleccionado =  parseInt(aux); // Se convierte a entero para que coincida con el valor del modelo
                }else{
                    // Si noy hay restaurante seteado asigna el primer restaurante del listado
                    this.restaurante_seleccionado = this.restaurantesList[0].id;
                    localStorage.setItem("restaurante_seleccionado_id", this.restaurante_seleccionado);
                    window.location.reload();
                }
          })
        }
      
    }

    cambiarRestaurante(){
        localStorage.setItem("restaurante_seleccionado_id", this.restaurante_seleccionado);
        window.location.reload();
    }


    

    // METODO PARA EL BOTON DE REGRESAR

    volver() {
        if(this.authService.currentUserValue.is_empleado1||this.authService.currentUserValue.is_empleado2){
            this.router.navigateByUrl('/dashboard/dashboard');
        }else if(this.authService.currentUserValue.is_admin){    
            this.router.navigateByUrl('menu/menu-admin');
        }else{
            this.router.navigateByUrl('menu/menu-super-admin');
        }

        // if (this.router.url == '/dashboard/dashboard-admin') {
        //     this.router.navigateByUrl('menu/menu-admin')
        // } else if (this.router.url == '/gestion-usuario/gestion-admin') {
        //     this.router.navigateByUrl('menu/menu-admin')
        // } else if (this.router.url == '/pages/proveedores') {
        //     this.router.navigateByUrl('menu/menu-admin')
        // } else if (this.router.url == '/pages/insumos') {
        //     this.router.navigateByUrl('menu/menu-admin')
        // } else if (this.router.url == '/pages/configuraciones') {
        //     this.router.navigateByUrl('menu/menu-admin')
        // } else if (this.router.url == '/dashboard/dashboard-super') {
        //     this.router.navigateByUrl('menu/menu-super-admin')
        // } else if (this.router.url == '/gestion-usuario/gestion-super-admin') {
        //     this.router.navigateByUrl('menu/menu-super-admin')
        // } else if (this.router.url == '/pages/recetas') {
        //     this.router.navigateByUrl('menu/menu-admin')
        // } else if (this.router.url == '/pages/subrecetas') {
        //     this.router.navigateByUrl('menu/menu-admin')
        // } else if (this.router.url == '/pages/compras') {
        //     this.router.navigateByUrl('menu/menu-admin')
        // } else if (this.router.url == '/pages/menus') {
        //     this.router.navigateByUrl('menu/menu-admin')
        // } else if (this.router.url == '/pages/devoluciones') {
        //     this.router.navigateByUrl('menu/menu-admin')
        // } else if (this.router.url == '/pages/ventas') {
        //     this.router.navigateByUrl('menu/menu-admin')
        // }

    }

    // toggleFold() {
    //     this.isFolded = !this.isFolded;
    //     this.themeService.toggleFold(this.isFolded);
    // }

    // toggleExpand() {
    //     this.isFolded = false;
    //     this.isExpand = !this.isExpand;
    //     this.themeService.toggleExpand(this.isExpand);
    //     this.themeService.toggleFold(this.isFolded);
    // }

    // searchToggle(): void {
    //     this.searchVisible = !this.searchVisible;
    // }

    // quickViewToggle(): void {
    //     this.quickViewVisible = !this.quickViewVisible;
    // }

    // notificationList = [
    //     {
    //         title: 'You received a new message',
    //         time: '8 min',
    //         icon: 'mail',
    //         color: 'ant-avatar-' + 'blue'
    //     },
    //     {
    //         title: 'New user registered',
    //         time: '7 hours',
    //         icon: 'user-add',
    //         color: 'ant-avatar-' + 'cyan'
    //     },
    //     {
    //         title: 'System Alert',
    //         time: '8 hours',
    //         icon: 'warning',
    //         color: 'ant-avatar-' + 'red'
    //     },
    //     {
    //         title: 'You have a new update',
    //         time: '2 days',
    //         icon: 'sync',
    //         color: 'ant-avatar-' + 'gold'
    //     }
    // ];
}
