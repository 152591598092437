import { ReporteService } from './../../shared/services/reporte.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { formatDate } from '@angular/common';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
@Component({
  selector: 'app-grafica-lineas',
  templateUrl: './grafica-lineas.component.html',
  styleUrls: ['./grafica-lineas.component.css']
})
export class GraficaLineasComponent implements OnInit {
  @Input() tipo; //[compra o venta]
  datePlatos;//MODEL PARA ANIO DEL GRAFICO PLATOS
  radioValuePlatos="diezmasvendidos";//model para filtros de platos
  response={por_cantidad:{chartData:[],chartLabels:[], moneda:"" },por_monto:{chartData:[],chartLabels:[], moneda:""}};
  constructor(
    private reporteService:ReporteService,
  ) { }

  ngOnInit() {
    this.tipo=="ventas"?(this.radioValuePlatos="diezmasvendidos"):(this.radioValuePlatos="cantidad");
    console.log("EEEEE",this.radioValuePlatos);
    // if(this.tipo=="venta"){}else if(this.tipo=="compra"){}
      this.actualizarGrafica();
   
  }


 /**
   * ACTUALIZAR GRAFICA DE PLATOS MAS VENDIDOS
   */
  actualizarGrafica(){
    let year;
    if(this.datePlatos){
      year  = formatDate(this.datePlatos, 'yyyy', 'en');   
    }else{          
      year = formatDate(new Date(), 'yyyy', 'en');    
    }
    let servicio = this.tipo=="ventas"?this.reporteService.platos(year):this.reporteService.insumosTop(year);
    servicio.subscribe(res=>{
      console.log(res);
      this.response=res;
      if(this.radioValuePlatos=="cantidad"){
        this.lineChartData=this.response.por_cantidad.chartData;
        this.lineChartLabels=this.response.por_cantidad.chartLabels;
        this.moneda=this.response.por_cantidad.moneda;
      }else if (this.radioValuePlatos=="monto"){
        this.lineChartData=this.response.por_monto.chartData;
        this.lineChartLabels=this.response.por_monto.chartLabels;
        this.moneda=this.response.por_monto.moneda;
      }else{
        this.lineChartData=res.chartData
        this.lineChartLabels=res.chartLabels;
        this.moneda=res.moneda;
      }
      
      // this.barChartLabels=res.dias;
      // this.barChartData=res.data_set;
      // this.ventasxMesAnioList=res.extras;
    },err=>{
      console.log(err);
    });
  }


  changeRadio(){
    if(this.radioValuePlatos=="cantidad"){
      this.lineChartData=this.response.por_cantidad.chartData;
      this.lineChartLabels=this.response.por_cantidad.chartLabels;
    }else if (this.radioValuePlatos=="monto"){
      this.lineChartData=this.response.por_monto.chartData;
      this.lineChartLabels=this.response.por_monto.chartLabels;
    }
  }

   //  GRAFICA PLATOS MAS VENDIDOS -->

   public lineChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0, 0, 0,0,0,0,0,0], label: 'no data' },
    // { data: [28, 48, 40, 19, 86.54, 27, 90,115.75,75,80,95,125], label: 'Lasaña' },  
  ];
  public lineChartLabels: Label[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul','Ago','Sep','Oct','Nov','Dic'];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        // {
        //   id: 'y-axis-1',
        //   position: 'right',
        //   gridLines: {
        //     color: 'rgba(255,0,0,0.3)',
        //   },
        //   ticks: {
        //     fontColor: 'red',
        //   }
        // }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          // label: {
          //   enabled: true,
          //   fontColor: 'orange',
          //   content: 'LineAnno'
          // }
        },
      ],
    },
  };
  public moneda : String = "";
  public lineChartColors: Color[] = [
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // green
      backgroundColor: 'rgba(0, 255, 0, 0.3)',
      borderColor: 'green',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }
   
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];
  @ViewChild(BaseChartDirective, { }) chart: BaseChartDirective; 
}
