
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rutaApi } from '../util/constants';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class InsumoService {

  constructor(private http: HttpClient) { }
  /**
   * 
   * @description obtiene los insumos
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public getInsumos(): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/insumo?restaurante_id=')+rest);
  }


  /**
   * 
   * @description Listado de insumos con unidad de medida
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public indexWithUnit(): Observable<any> {
    let rest = localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/insumo?restaurante_id=' + rest +'&with_unidades_medidas_opciones=yes'));
  }

  /**
* 
* @description Obtener insumos by id
* @author Gabriel Baldez
* @returns Observable<any> 
*/
  public getInsumoById(id): Observable<any> {
    return this.http.get<any>(rutaApi('/insumo/' + id + ''));
  }


  /**
  * 
  * @description Listado de insumos con proveedores
  * @author Gabriel Baldez
  * @returns Observable<any> 
  */
  public insumosWhitProveedor(id): Observable<any> {
    let rest = localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/insumo?restaurante_id=' + rest + '&with_unidades_medidas_opciones=yes&excel=0&filter_proveedor_id='+id));
  }


  /**
   * 
   * @description Editar insumo
   * @author CJ
   * @returns Observable<any> 
   */
  public put(object): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.put<any>(rutaApi('/insumo/'+object.id+''),object);
  }

   /**
   * 
   * @description registra los insumos
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public registroInsumo(object): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.post<any>(rutaApi('/insumo?restaurante_id=') + rest,object);
  }


  /**   
   * @description Eliminar Insumo
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public deleteInsumo(object): Observable<Array<any>>{
    return this.http.delete<any>(rutaApi('/insumo/'+object.id+''));
  }


   /**
   * 
   * @description Carga el excel
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public registroExcel(object): Observable<any>{    
    return this.http.post<any>(rutaApi('/insumo/cargar_excel'), object);
  }



    /**
   * 
   * @description Listado de insumos existentes en un almacen
   * @author cd
   * @returns Observable<any> 
   */
  public reportes_insumo(id=null): Observable<any> {
    if(!id){
      let rest = localStorage.getItem('restaurante_seleccionado_id');
      return this.http.get<any>(rutaApi('/reporte_insumos?restaurante_id=' + rest ));
    }else{    
      let rest = localStorage.getItem('restaurante_seleccionado_id');
      return this.http.get<any>(rutaApi('/reporte_insumos?restaurante_id=' + rest +'&almacen_id='+id+''));
    }
  }





   /**
   * 
   * @description exportar a excel
   * @author cd
   * @returns Observable<any> 
   */
  public exportar(): Observable<any> {

    let rest = localStorage.getItem('restaurante_seleccionado_id');
    // return this.http.get<any>(rutaApi('/insumo?restaurante_id=' + rest +'&excel=1'));
   return this.http.get(rutaApi('/insumo?restaurante_id=' + rest +'&excel=1'), { responseType: 'blob' });   
  }





   /**
   * 
   * @description obtiene kardex del insumo
   * @author
   * @returns Observable<any> 
   */
  public getKardexInsumo(id): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/insumo_movido?restaurante_id=')+rest+'&from=kardex&filter_insumo_id='+id);
  }



   /**
   * 
   * @description obtiene kardex del insumo
   * @author
   * @returns Observable<any> 
   */
  public exportarKardex(id): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get(rutaApi('/insumo_movido?restaurante_id=')+rest+'&from=kardex&excel=1&filter_insumo_id='+id, { responseType: 'blob' });  
  }



     /**
   * 
   * @description exportar a excel
   * @author cd
   * @returns Observable<any> 
   */
  public exportarMovimientos(): Observable<any> {

    let rest = localStorage.getItem('restaurante_seleccionado_id');
    // return this.http.get<any>(rutaApi('/insumo?restaurante_id=' + rest +'&excel=1'));
   return this.http.get(rutaApi('/insumo_movido?restaurante_id=' + rest +'&from=kardex&excel=1'), { responseType: 'blob' });   
  }

}
