import { ChartType } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import { ReporteService } from './../../shared/services/reporte.service';
import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-grafica-dona',
  templateUrl: './grafica-dona.component.html',
  styleUrls: ['./grafica-dona.component.css']
})
export class GraficaDonaComponent implements OnInit {
  dateTipo;//MODEL PARA ANIO DE LA GRAFICA VENTAS POR TIPO
  constructor(
    private reporteService:ReporteService,
  ) { }

  ngOnInit() {
    this.actualizarGraficaVentaPorTipo();
  }

  actualizarGraficaVentaPorTipo(){
    if(this.dateTipo){
      let year = formatDate(this.dateTipo, 'yyyy', 'en');
      this.reporteService.ventasPorTipo(year).subscribe(res=>{
        console.log(res);
       this.doughnutChartLabels=res.labels;
       this.doughnutChartData=res.data;
      },err=>{
        console.log(err);
      });
    }else{          
      let year = formatDate(new Date(), 'yyyy', 'en');
      this.reporteService.ventasPorTipo(year).subscribe(res=>{
        console.log(res);
        this.doughnutChartLabels=res.labels;
        this.doughnutChartData=res.data;
      },err=>{
        console.log(err);
      });
    }
  }


  public doughnutChartLabels: Label[] = ['Venta', 'Regalo', 'Atencion'];
  public doughnutChartData: MultiDataSet = [
    [450, 50, 100]    
  ];
  public doughnutChartType: ChartType = 'doughnut';
}
