import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.css']
})
export class MenuAdminComponent implements OnInit {

  opcionMenu: any[] = [];

  constructor(
    private router: Router,
    public authService:AuthenticationService,
    private mensajeService:MensajeComponentService,
    ) { }

  ngOnInit() {
    this.mensajeService.changeMessage("/menu/menu-admin");
    this.opcionMenu = [
      {
        titulo: 'Dashboard',
        icon: 'assets/img/reportes.svg',
        path: '/dashboard/dashboard-admin',
      },
      {
        titulo: 'Ventas',
        icon: 'assets/img/ventas.svg',
        path: '/pages/ventas',
      },
      {
        titulo: 'Inventarios',
        icon: 'assets/img/inventario.svg',
        path: '/pages/inventarios',
      },
      {
        titulo: 'Compras',
        icon: 'assets/img/compras.svg',
        path: '/pages/compras',
      },
      {
        titulo: 'Devoluciones',
        icon: 'assets/img/devoluciones.svg',
        path: '/pages/devoluciones',
      },
      {
        titulo: 'Movimientos',
        icon: 'assets/img/transacciones.svg',
        path: '/pages/movimientos',
      },
      {
        titulo: 'Insumos',
        icon: 'assets/img/insumos.svg',
        path: '/pages/insumos',
      },
      {
        titulo: 'Proveedores',
        icon: 'assets/img/proveedores.svg',
        path: '/pages/proveedores',
      },
      {
        titulo: 'Menus',
        icon: 'assets/img/menu.svg',
        path: '/pages/menus',
      },
      {
        titulo: 'Recetas',
        icon: 'assets/img/recetas.svg',
        path: '/pages/recetas',
      },
      {
        titulo: 'Subrecetas',
        icon: 'assets/img/subreceta.svg',
        path: '/pages/subrecetas',
      },
      {
        titulo: 'Usuarios',
        icon: 'assets/img/menu_user.svg',
        path: '/gestion-usuario/gestion-admin',
      },
      {
        titulo: 'Sugerencias',
        icon: 'assets/img/dudas.svg',
        path: '/pages/sugerencias',
      },
      {
        titulo: 'Configuración',
        icon: 'assets/img/conf.svg',
        path: '/pages/configuraciones',
      },
    ];
  }

    /**
   * @description Metodo que permite redireccionar de acuerdo al parametro que recibe.
   * @param ev [Opcion del menu]
   */
  redireccion(ev){
    this.router.navigate([ev]);
  }



}
