import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { ReporteService } from './../../shared/services/reporte.service';
import { NavigationEnd } from '@angular/router';
import { Component, OnInit,Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-panel-lateral',
  templateUrl: './panel-lateral.component.html',
  styleUrls: ['./panel-lateral.component.css']
})
export class PanelLateralComponent implements OnInit {
   @Input() data: any;
   ventas={hoy:0,semana:0};
   compras={hoy:0,semana:0};
   adminsList=[]; //OBTIENE TODOS LOS ADMINISTRADORES
   empleadosList=[]; //OBTIENE TODOS LOS EMPLEADOS
   spinner="";
   objeto={hoy:0,semana:0};
   usuario={empleado1:0,empleado2:0};
  constructor(
    public router:Router,
    public authService:AuthenticationService,
    private ReporteService:ReporteService,
    private mensajeService:MensajeComponentService
    
  ) {     

  
  }
 subribirse =null;
  ngOnInit() {
     this.mensajeService.currentMessage.pipe(first()).subscribe(message => {
        console.log(message,"MENSAJEEEE COMPOENTN");
        if(message.startsWith('/')){
          switch (message) {
            case "/pages/ventas":
              
                this.spinner = message;            
                this.ReporteService.sideBar("venta").subscribe(res=>{
                  console.log(res,'venta');
                  if(res){
                    this.objeto=res;
                  }
                  this.spinner=""
                },err=>{
                  this.spinner=""
                });
              
            break;
            case "/pages/compras":                
                this.spinner = message;
                this.ReporteService.sideBar("compra").subscribe(res=>{
                  console.log(res,'compras');
                  if(res){
                    this.objeto=res;
                  }
                  this.spinner=""
                },err=>{
                  this.spinner=""
                });                
            break;
            case "/pages/devoluciones":
              this.spinner = message;
              this.ReporteService.sideBar("devolucion_compra").subscribe(res=>{
                console.log(res,'devolucion_compra');
                if(res){
                  this.objeto=res;
                }
                this.spinner=""
              },err=>{
                this.spinner=""
              });
            break;
            case "/pages/movimientos":
              this.spinner = message;
              this.ReporteService.sideBar("movimiento").subscribe(res=>{
                console.log(res,'movimiento');
                if(res){
                  this.objeto=res;
                }
                this.spinner=""
              },err=>{            
                this.spinner=""
              });
            break;
            case "/pages/insumos":
              this.spinner = message;
              this.ReporteService.sideBar("insumo_vendido").subscribe(res=>{
                console.log(res,'insumo_vendido');
                if(res){
                  this.objeto=res;
                }
                this.spinner=""
              },err=>{
                this.spinner=""
              });
              break;
            case "/gestion-usuario/gestion-admin":
              this.spinner = message;
              this.ReporteService.sideBar("usuario").subscribe(res=>{
                console.log(res,'usuario');            
                if(res){
                  this.usuario=res;
                }
                this.spinner=""
              },err=>{
                this.spinner=""
              });
              break;

            case "/menu/menu-admin":
              
                this.spinner = message;            
                this.ReporteService.sideBar("venta").subscribe(res=>{
                  console.log(res,'venta');
                  if(res){
                    this.ventas=res;
                  }
                  this.spinner=""
                },err=>{
                  this.spinner=""
                });
                this.ReporteService.sideBar("compra").subscribe(res=>{
                  console.log(res,'compras');
                  if(res){
                    this.compras=res;
                  }
                  this.spinner=""
                },err=>{
                  this.spinner=""
                });           
            break;
            
                    
            default:
              break;            
          }    
        }    
      });
    
   

  
   
  }


  // METODO PARA EL BOTON DE REGRESAR

  volver(){
    if(this.authService.currentUserValue.is_empleado1||this.authService.currentUserValue.is_empleado2){
      this.router.navigateByUrl('/dashboard/dashboard');
  }else if(this.authService.currentUserValue.is_admin){    
      this.router.navigateByUrl('menu/menu-admin');
  }else{
      this.router.navigateByUrl('menu/menu-super-admin');
  }     
    
  }







}
