import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'subCategoriaInsumo'
})

export class SubCategoriaInsumoPipe implements PipeTransform {
    
    public transform(idCategoria,args?:any) {                
        // return args.filter(cat => idCategoria===cat.id)[0].referencia;
        let cate = null;
        let subcate = null;
        args.forEach(element => {
            if(element.id == idCategoria)
                cate=element;
        }); 
        // args.filter(function(el) {
        //     // console.log(el,idCategoria)
        //     if(el.id == idCategoria){

        //         if(el.categoria_insumos_padre_id!=null){                   
        //             console.log(args.filter(cat => el.categoria_insumos_padre_id===cat.id)[0].referencia) ;
        //             // cate = args.filter(cat => el.categoria_insumos_padre_id===cat.id)[0].referencia;
        //         }
        //     }                              
        // });
        subcate = args.filter(cat => cate.categoria_insumos_padre_id===cat.id);
        if(subcate[0]){            
            return subcate[0].referencia;
        }
    }
}