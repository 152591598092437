import { AuthAdminGuard } from './../shared/guard/auth-admin.guard';
import { AuthSuperAdminGuard } from './../shared/guard/auth-super-admin.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { GestionSuperAdminComponent } from './gestion-super-admin/gestion-super-admin.component';
import { GestionAdminComponent } from './gestion-admin/gestion-admin.component';

const routes: Routes = [
  {
    path: 'gestion-super-admin',
    component: GestionSuperAdminComponent,
    canActivate: [AuthSuperAdminGuard],
    data: {
        title: 'Super Admin ',        
    },
  
},

{
  path: 'gestion-admin',
  component: GestionAdminComponent,  
  canActivate: [AuthAdminGuard],
  data: {
      title: 'Admin ',      
  }
},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionUsuarioRoutingModule { }
