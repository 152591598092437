import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-opcion-menu',
  templateUrl: './opcion-menu.component.html',
  styleUrls: ['./opcion-menu.component.css']
})
export class OpcionMenuComponent implements OnInit {

  @Input() path: string = '';
  @Input() icon: string = '';
  @Input() titulo: string = '';

  constructor() { }

  ngOnInit() {
  }

}
