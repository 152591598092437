import { ReporteService } from './../../shared/services/reporte.service';
import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-grafica-materia-prima',
  templateUrl: './grafica-materia-prima.component.html',
  styleUrls: ['./grafica-materia-prima.component.css']
})
export class GraficaMateriaPrimaComponent implements OnInit {
  fechaReporte;
  reporteVentalist = [];
  constructor(
    private reporteService: ReporteService,
  ) { }

  ngOnInit() {
    this.reportesGenerales();
  }

  reportesGenerales() {
    if (this.fechaReporte) {      
      let year = formatDate(this.fechaReporte, 'yyyy', 'en');
      this.reporteService.ventasPorcentaje(year).subscribe(res => {
        this.reporteVentalist = res;
        console.log(this.reporteVentalist, "reportes de ventas mmpp")
      });

    } else {      
      let date = new Date()
      let year = formatDate(date, 'yyyy', 'en');
      this.reporteService.ventasPorcentaje(year).subscribe(res => {
        this.reporteVentalist = res;
        console.log(this.reporteVentalist, "reportes generado a la primera")
      });
    }
  }

}
