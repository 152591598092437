import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { NzModalService } from 'ng-zorro-antd';
import { UnidadEquivalenteService } from 'src/app/shared/services/unidad-equivalente.service';
import { UnidadEquivalencia } from 'src/app/shared/interfaces/unidad-equivalencia.type';
import { UnidadEquivalenteComponent } from 'src/app/forms/unidad-equivalente/unidad-equivalente.component';

@Component({
  selector: 'app-card-equivalencia',
  templateUrl: './card-equivalencia.component.html',
  styleUrls: ['./card-equivalencia.component.css']
})
export class CardEquivalenciaComponent implements OnInit {
  @Input() equivalencia: UnidadEquivalencia

  constructor(
    private spinner: NgxSpinnerService,
    private equivalenteService:UnidadEquivalenteService,
    private router: Router,
    private mensajeComponentService: MensajeComponentService,
    private modalService: NzModalService,
  ) { }

  ngOnInit() {
  }

  /**
   * @description Actualizar unidad de equivalencia
   * @author Gabriel Baldez
   */
  editar(): void {

    console.log("editando");
    console.log(this.equivalencia);

    const modal = this.modalService.create({
      nzContent: UnidadEquivalenteComponent,
      nzFooter: null,
      nzWidth: 900,
      nzComponentParams: {
        objetoEditar: this.equivalencia,
      }
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.mensajeComponentService.changeMessage("updateUnidadesEquivalentes");
      }
    });
  }

  /**
   * @description borrar la equivalencia
   * @author Gabriel Baldez
   */
  // delete(): void {
  //   this.spinner.show();
  //   this.almacen.delete(this.destino).subscribe(res => {
  //     this.mensajeComponentService.changeMessage("updateDestinos");
  //     this.spinner.hide();
  //   }, err => {
  //     console.log(err);
  //     this.spinner.hide();
  //   });
  // }

}
