import { Observable } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.type';
import { Injectable } from '@angular/core';
import { rutaApi } from '../util/constants';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class RolService {

  constructor(
    private http: HttpClient,
  ) { }


  
   /**
   * 
   * @description Obtiene los roles
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public getRols(): Observable<Array<User>>{
    return this.http.get<any>(rutaApi('/rol'));
  }
}
