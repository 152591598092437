import { AuthenticationService } from '../../shared/services/authentication.service';
import { UserService } from 'src/app/shared/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzModalRef } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wizard-password',
  templateUrl: './wizard-password.component.html',
  styleUrls: ['./wizard-password.component.css']
})
export class WizardPasswordComponent implements OnInit {
  registro:FormGroup
  error=false;
  mensaje="";

  constructor(
    private fb: FormBuilder,
    private modal:NzModalRef,
    private spinner:NgxSpinnerService,
    private userService:UserService,
    private authService:AuthenticationService
  ) { 
    
    this.registro = this.fb.group({
      password: [ null, [ Validators.required ] ],    
    });

  }

  ngOnInit() {
  }


  submitForm(): void {
    
    for (const i in this.registro.controls) {
      this.registro.controls[ i ].markAsDirty();
      this.registro.controls[ i ].updateValueAndValidity();
    }
    let payload = {};
    if(this.registro.valid){
      this.spinner.show();
      let user = this.authService.currentUserValue;            
      user['password'] = this.registro.value.password;   
      user['rol_id'] = user.rol.id;
  
      console.log(payload);
  
      this.userService.put(user).subscribe(res =>{                
        this.modal.destroy({ data: true });  
        this.authService.currentUserValue.pass_updated=true;        
        localStorage.setItem('currentUser', JSON.stringify(this.authService.currentUserValue));
        this.spinner.hide();
      }, err =>{
        // this.mensaje="Ocurrio un problema."
        this.mensaje = err.error.errors;
        this.error=true;
        this.spinner.hide();
        console.log(err);
      });
      
      
    }
  
   
  }

}
