import { Component, OnInit } from '@angular/core';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenStorage } from 'src/app/shared/util/token.storage';
import { HttpClient } from '@angular/common/http';
import { FormRecetaComponent } from 'src/app/forms/receta/receta.component';
import { RecetaService } from 'src/app/shared/services/receta.service';

@Component({
  selector: 'app-recetas',
  templateUrl: './recetas.component.html',
  styleUrls: ['./recetas.component.css']
})
export class RecetasComponent implements OnInit {
  success = false;
  mensaje = "";
  error=false;
  banView:boolean=false;
  recetaslist:any[];
  recetaslistaux:any[];
  constructor(
    private modalService: NzModalService,

    //private modal: NzModalRef,
    private mensajeService:MensajeComponentService,
    private spinner:NgxSpinnerService,
    private tokenStorage:TokenStorage,
    private recetaService:RecetaService,


    private http: HttpClient,
     private msg: NzMessageService
  ) {
    this.mensajeService.currentMessage.subscribe(message => {
      switch (message) {


        case 'deleteRecetas':
          this.listarRecetas();
          break;
          case 'updateRecetas':
          this.listarRecetas();
          break;
        default:
          break;
      }
    });

   }

  ngOnInit() {
   this.listarRecetas();
   
  }


  nuevaReceta(): void {
    const modal = this.modalService.create({
      nzContent: FormRecetaComponent,
      nzFooter: null,
      nzWidth:1100,
      nzMaskClosable:false



    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
         this.listarRecetas();
        this.mensaje = "Registro exitoso.";
        this.success = true;
      }
    });
  }

  listarRecetas(){
    this.banView=false;
    this.recetaService.index().subscribe(res=>{

      console.log(res,"recetas");

      this.recetaslist=res;
      this.recetaslistaux=res;
      this.banView=true;
      // this.spinner.hide();

    },err=>{
      this.banView=true;
      this.mensaje="Ocurrio un problema."
      // this.spinner.hide();
      console.log(err);
    });
  }

  inputBusqueda="";
  filtrar(){
    let that=this;
    if(this.inputBusqueda==""){
      this.recetaslist=this.recetaslistaux;
    }else{
      this.recetaslist=this.recetaslistaux.filter(function (element) {
        if(element.nombre.toLowerCase().includes(that.inputBusqueda.toLowerCase()))
          return true;
        else
          return false;
      });
      console.log(this.recetaslist);
    }

  }
}
