import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rutaApi } from '../util/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VentasService {

  constructor(private http: HttpClient) { }

  /**
 * 
 * @description obtiene todas las ventas
 * @author Gabriel Baldez
 * @returns Observable<any> 
 */
  public index(): Observable<any> {
    let rest = localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/venta?restaurante_id=' + rest));
  }

  /**
   * 
   * @description obtiene todas las ventas con su restaurante asociado
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public indexWithRestaurante(): Observable<any> {
    let rest = localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/venta?restaurante_id=' + rest +  "&with_restaurante=1"));
  }

  /**
* 
* @description registrar la venta
* @author Gabriel Baldez
* @returns Observable<any> 
*/
  public crearVenta(object): Observable<any> {
    return this.http.post<any>(rutaApi('/venta'), object);
  }


  
  /**
* 
* @description editar la venta
* @author Gabriel Baldez
* @returns Observable<any> 
*/
public editar(object): Observable<any> {
  return this.http.put<any>(rutaApi('/venta/'+object.id), object);
}


  /**   
    * @description Eliminar venta
    * @author Gabriel Baldez
    * @returns Observable<any> 
    */
  public delete(object): Observable<Array<any>> {
    return this.http.delete<any>(rutaApi('/venta/' + object.id));
  }


   /**
 * 
 * @description 
 * @author 
 * @returns Observable<any> 
 */
public get(id): Observable<any> {
  
  return this.http.get<any>(rutaApi('/venta/'+id));
}
}
