import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rutaApi } from '../util/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InsumosMovidosService {

  constructor(private http: HttpClient) { }


  /**
  * 
  * @description obtiene las compras
  * @author Gabriel Baldez
  * @returns Observable<any> 
  */
 public compras(proveedor): Observable<any> {
  let rest = localStorage.getItem('restaurante_seleccionado_id');
  return this.http.get<any>(rutaApi('/insumo_movido?restaurante_id='+rest+'&from=devolucion&filter_proveedor_id='+proveedor+''));
  }


}
