import { rutaApi } from 'src/app/shared/util/constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GastoService {

  constructor(private http: HttpClient) { }

  /**
  * 
  * @description Obtener devoluciones
  * @author Carlos diaz
  * @returns Observable<any> 
  */
  public index(tipo: 'empleados' | 'gastos', inicio = null, fin = null): Observable<any> {
    let rest = localStorage.getItem('restaurante_seleccionado_id');
    let params = {};
    if (inicio && fin && tipo === 'gastos') {
      params = {
        restaurante_id: rest,
        filter: tipo,
        inicio,
        fin
      }
    } else {
      params = {
        restaurante_id: rest,
        filter: tipo,
      }
    }
    return this.http.get<any>(rutaApi('/gastos'), { params });
  }

 /**
  *
  * @description 
  * @author 
  * @returns Observable<any>
  */
  public store(object): Observable<any> {

    return this.http.post<any>(rutaApi('/gastos'), object);
  }

  /**
  *
  * @description 
  * @author 
  * @returns Observable<any>
  */
   public put(object): Observable<any> {
    return this.http.put<any>(rutaApi(`/gastos/${object.id}`), object);
  }


   /**
  *
  * @description 
  * @author 
  * @returns Observable<any>
  */
    public delete(object): Observable<any> {
      return this.http.delete<any>(rutaApi(`/gastos/${object.id}`), object);
    }
}
