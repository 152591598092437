import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { DevolucionService } from './../../shared/services/devolucion.service';
import { DevolucionComponent } from './../../forms/devolucion/devolucion.component';

import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';
import { InsumosMovidosService } from 'src/app/shared/services/insumos-movidos.service'

@Component({
  selector: 'app-devoluciones',
  templateUrl: './devoluciones.component.html',
  styleUrls: ['./devoluciones.component.css']
})
export class DevolucionesComponent implements OnInit {
  success = false;
  mensaje = "";
  error = false;
  devolucionesList:any=[];
  banView: boolean = false;
  constructor(
    private modalService: NzModalService,   
    private devolucionService:DevolucionService,
    private mensajeService:MensajeComponentService, 
    ) { 
      
      this.mensajeService.changeMessage("/pages/devoluciones");
      this.mensajeService.currentMessage.subscribe(message => {
        switch (message) {
  
            
            case 'updateDevoluciones':          
            this.listarDevoluciones();
            break;
          default:
            break;
        }
      });
    }

  ngOnInit() {
    
   this.listarDevoluciones();
  }

  devolucion(): void {
    const modal = this.modalService.create({
      nzContent: DevolucionComponent,
      nzFooter: null,
      nzWidth: 1000,
      nzMaskClosable:false


 
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.listarDevoluciones();
        this.mensaje = "Registro exitoso.";
        this.success = true;
      }
    });
  }

  listarDevoluciones() {
    this.banView=true;
    this.devolucionService.index().subscribe(res=>{
      console.log(res);
      this.devolucionesList=res;
      this.banView=false;
    },err=>{
      this.banView=false;
      console.log(err);
    });

  }


}
