import { ModalVentasComponent } from 'src/app/forms/modal-ventas/modal-ventas.component';
import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { VentasService } from 'src/app/shared/services/ventas.service';
import { Router } from '@angular/router';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-card-ventas',
  templateUrl: './card-ventas.component.html',
  styleUrls: ['./card-ventas.component.css']
})
export class CardVentasComponent implements OnInit {
  @Input() venta: any;
  constructor(
    private spinner: NgxSpinnerService,
    private ventasService: VentasService,
    private router: Router,
    private mensajeComponentService: MensajeComponentService,
    private modalService: NzModalService,
  ) { }

  ngOnInit() {
  }



  /**
   * @description Actualizar una subreceta
   * @author Javier Evans
   */
  editar(): void {
    const modal = this.modalService.create({
        nzContent: ModalVentasComponent,
        nzFooter: null,
        nzWidth:1100,
        nzComponentParams: {
          objetoEditar : this.venta,
        }

    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){
        this.mensajeComponentService.changeMessage("updateVentas");
      }
    });
  }


}
