import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rutaApi } from '../util/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecetaService {

  constructor( private http: HttpClient) { }




   /**
   *
   * @description obtiene las recetas
   * @author Gabriel Baldez
   * @returns Observable<any>
   */
  public receta_menu(): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/receta_menu?restaurante_id='+ rest));
  }


   /**
   *
   * @description obtiene las recetas
   * @author Gabriel Baldez
   * @returns Observable<any>
   */
  public index(): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/receta?restaurante_id='+ rest));
  }

  /**
   *
   * @description Listado de recetas con precios estimados
   * @author Javier Evans
   * @returns Observable<any>
   */
  public indexWithPrice(): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/receta_with_price?restaurante_id=' + rest));
  }


  /**
*
* @description Obtener recetas by id
* @author Gabriel Baldez
* @returns Observable<any>
*/
  public mostrar(id): Observable<any> {
    return this.http.get<any>(rutaApi('/receta/' + id + ''));
  }



   /**
   *
   * @description registrar la receta
   * @author Gabriel Baldez
   * @returns Observable<any>
   */
  public post(object): Observable<any>{
    return this.http.post<any>(rutaApi('/receta'),object);
  }

  /**
   *
   * @description actualizar la receta
   * @author Javier Evans
   * @returns Observable<any>
   */
  public put(object): Observable<any>{
    // Debido a limitaciones de php para recibir archivos por PUT se usa método POST en este endpoint
    return this.http.post<any>(rutaApi('/update_receta/' + object.get('id') ), object);
  }


  /**
   * @description Eliminar objeto
   * @author Javier Evans
   * @returns Observable<any>
   */
  public delete(object): Observable<Array<any>>{
    return this.http.delete<any>(rutaApi('/receta/' + object.id ));
  }



  /**
   * @description Eliminar objeto
   * @author Javier Evans
   * @returns Observable<any>
   */
  public download(object): Observable<any>{
    return this.http.get(rutaApi('/receta/'+object+'/pdf' ),{ responseType: 'blob' });
  }

}
