import { AuthGuard } from './../guard/auth.guard';
import { Routes } from '@angular/router';
import { LoginComponent } from 'src/app/authentication/login/login.component';
import { LandingPageComponent } from 'src/app/pages/landing-page/landing-page.component';

//
export const FullLayout_ROUTES: Routes = [
    {
        path: 'authentication',
        loadChildren: './authentication/authentication.module#AuthenticationModule'
    },
    // {
    //     path: 'welcome',
    //     component: LoginComponent,
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'welcome',
        redirectTo: 'login',

    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AuthGuard]
    },


];
