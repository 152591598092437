// export const API: string = "http://localhost/adelmo-soft/public/api";
// export const APIFOLDER: string = "http://localhost/adelmo-soft/storage/";

// export function rutaApi(ruta):string {
//     return "https://api.sazonalcosto.com/api" + ruta;
// }
export function rutaApi(ruta):string {
    return "https://api.bisproapp.com/api" + ruta;
}
// export function rutaApi(ruta):string {
//     return "http://localhost:8000/api" + ruta;
// }
// const API: string = "http://35.193.85.133/atmsoft-apirest/public/api";
// export const APIFOLDER: string = "https://adelmo.com.ar/adelmosoft/storage/"
