import { FormsAppModule } from '../forms/forms.module';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionUsuarioRoutingModule } from './gestion-usuario-routing.module';
import { GestionAdminComponent } from './gestion-admin/gestion-admin.component';
import { GestionSuperAdminComponent } from './gestion-super-admin/gestion-super-admin.component';
import { ThemeConstantService } from '../shared/services/theme-constant.service';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [
    GestionAdminComponent,
    GestionSuperAdminComponent
  ],
  imports: [
    CommonModule,
    GestionUsuarioRoutingModule,
    ComponentsModule,
    FormsAppModule,
    SharedModule,

  ],
  providers: [
    ThemeConstantService
],
})
export class GestionUsuarioModule { }
