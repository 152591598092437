import { AuthGuard } from './../guard/auth.guard';

import { Routes } from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
    {
        path: 'gestion-usuario',
        loadChildren: './gestion-usuario/gestion-usuario.module#GestionUsuarioModule',
    }, 
    {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',        
    },

    {
        path: 'menu',
        loadChildren: './menu/menu.module#MenuModule',
    },

    {
        path: 'pages',
        loadChildren: './pages/pages.module#PagesModule',
    },
    
];