import { AgregarAreaRecetaComponent } from '../../forms/agregar-area-receta/agregar-area-receta.component';
import { AreaRecetaService } from 'src/app/shared/services/area-receta.service';
import { Component, OnInit } from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { ConfiguracionesComponent } from 'src/app/forms/configuraciones/configuraciones.component';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service'; 
import { AgregarMedidaComponent } from 'src/app/forms/agregar-medida/agregar-medida.component';
import { FormDestinoComponent } from 'src/app/forms/destino/destino.component';
import { WizardRestaurantComponent } from 'src/app/forms/wizard-restaurant/wizard-restaurant.component';
import { UnidadMedidaService } from 'src/app/shared/services/unidad-medida.service';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { AgregarCategoriaInsumosComponent } from 'src/app/forms/agregar-categoria-insumos/agregar-categoria-insumos.component';
import { CategoriaInsumoService } from 'src/app/shared/services/categoria-insumo.service';
import { AlmacenService } from 'src/app/shared/services/almacen.service';
import { UnidadEquivalenteComponent } from 'src/app/forms/unidad-equivalente/unidad-equivalente.component';
import { UnidadEquivalenteService } from 'src/app/shared/services/unidad-equivalente.service';
import { AlergenosComponent } from 'src/app/forms/alergenos/alergenos.component';
import { AlergenosService } from 'src/app/shared/services/alergenos.service';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {
  success=false;
  mensaje="";
  medidaForm: FormGroup;
  destinoForm: FormGroup;
  areaForm: FormGroup;
  error=false; 
  destinosList : any[];
  restaurantelist:any[];
  medidalist:any[];
  categoriaslist:any[];
  arealist:any[];
  infoCheck = "destinos";
  equivalenteslist: any;
  alergenoslist: any;
  banView: boolean = false;
  constructor(
    private modalService:NzModalService,
    private fb: FormBuilder,
    // private modal: NzModalRef,
    private spinner: NgxSpinnerService,
    private medidas: UnidadMedidaService,
    private almacen: AlmacenService,
    private restaurante:RestaurtantService,
    private mensajeService:MensajeComponentService,
    private Categorias:CategoriaInsumoService,
    private area: AreaRecetaService,
    private equivalenteService:UnidadEquivalenteService,
    private alergenosService: AlergenosService

    
  ) { 
    this.mensajeService.currentMessage.subscribe(message => {
      switch (message) {        
        case 'updateDestinos':
            this.cargarDestinos();
            break;
        case 'updateRestaurantes':
            this.cargarRestaurantes();
            break;
        case 'updateUnidadesMedidas':
        case 'deleteUnidadesMedidas':
            this.cargarUnidadesDeMedida();
            break;
        case 'updateCategorias':
        case 'deleteCategorias':
            this.cargarCategorias();
            break;
        case 'updateAreaRecetas':
          this.cargarAreaRecetas();
        break; 

        case 'updateUnidadesEquivalentes':
          this.cargarUnidadesEquivalentes();
          break; 

        case 'updateAlergenos':
          this.cargarAlergenos();
          break; 
                          
        default:
          break;

          
      }
    });
  }

  ngOnInit() {
    this.cargarDestinos();
    this.cargarRestaurantes();
    this.cargarUnidadesDeMedida();
    this.cargarCategorias();
    this.cargarAreaRecetas();
    this.cargarUnidadesEquivalentes();
    this.cargarAlergenos();
  }

  cargarDestinos(){
    this.almacen.index().subscribe(res => {  
      console.log(res,"aqui los destinos")
      this.banView=true    
      this.destinosList = res;
    }, err => {
      console.log(err);
    });
  }

  cargarRestaurantes(){
    this.restaurante.getRest().subscribe(res => {
      console.log(res, "restaurantes");
      this.banView = true    
      this.restaurantelist = res;
    }, err => {
      console.log(err);
    });
  }

  cargarUnidadesDeMedida(){
    this.medidas.getMedida().subscribe(res => {
      console.log(res, "Unidades de medidas");
      this.banView = true    
      this.medidalist = res;
    }, err => {
      console.log(err);
    });
  }

  cargarCategorias(){
    this.Categorias.getCategoria().subscribe(res => {
      console.log(res, "categorias");
      this.banView = true    
      this.categoriaslist = res;
    }, err => {
      console.log(err);
    });
  }

  // permite mostar las Area Recetas
  cargarAreaRecetas(){
    this.area.getArea().subscribe(res => {
      console.log(res, "Area de Receta");
      this.banView = true    
      this.arealist = res;
      console.log(this.arealist,'aqui las arias')
    }, err => {
      console.log(err);
    });
  }

  cargarUnidadesEquivalentes() {
    this.equivalenteService.getUniEquivalentes().subscribe(res => {
      console.log(res, "Unidades de equivalencia");
      this.banView = true    
      this.equivalenteslist = res;
    }, err => {
      console.log(err);
    });
  }

  cargarAlergenos() {
    this.alergenosService.getAlergenos().subscribe(res => {
      console.log(res, "Alergenos");
      this.banView = true
      this.alergenoslist = res;
    }, err => {
      console.log(err);
    });
  }

  nuevoUnidad(): void {
    const modal = this.modalService.create({
        nzContent: AgregarMedidaComponent,
        nzFooter:null,
        
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){        
        this.cargarUnidadesDeMedida();
        this.mensaje="Registro exitoso.";
        this.success=true;
      }
    });
  }


  nuevoDestino(): void {
    const modal = this.modalService.create({
        nzContent: FormDestinoComponent,
        nzFooter:null,
        
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){
        this.cargarDestinos();
        this.mensajeService.changeMessage("updateDestinos");
        this.mensaje="Registro exitoso.";
        this.success=true;
      }
    });
  }

  nuevoRest(): void {
    const modal = this.modalService.create({
        nzContent: WizardRestaurantComponent,
        nzFooter:null,
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){
        this.cargarRestaurantes();
        this.mensajeService.changeMessage("updateRestaurantes");
        this.mensaje="Registro exitoso.";
        this.success=true;
      }
    });
  }

  categorias(): void {
    const modal = this.modalService.create({
        nzContent: AgregarCategoriaInsumosComponent,
        nzFooter:null,
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){
        this.cargarCategorias();
        this.mensajeService.changeMessage("updateCategorias");
        this.mensaje="Registro exitoso.";
        this.success=true;
      }
    });
  }

  // permite crear nuevas las Area Recetas
  nuevoArea(): void{
    const modal = this.modalService.create({
      nzContent: AgregarAreaRecetaComponent,
      nzFooter:null,
      
  });
  modal.afterClose.subscribe((result) => {
    console.log('[afterClose] The result is:', result)
    if(result){        
      this.cargarAreaRecetas();
      this.mensaje="Registro exitoso.";
      this.success=true;
    }
  });
  }

  nuevaEquivalencia(): void {
    const modal = this.modalService.create({
      nzContent: UnidadEquivalenteComponent,
      nzFooter: null,
      nzWidth: 900,

    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.cargarUnidadesEquivalentes();
        this.mensaje = "Registro exitoso.";
        this.success = true;
      }
    });
  }

  nuevaAlergeno(): void {
    const modal = this.modalService.create({
      nzContent:AlergenosComponent,
      nzFooter: null,
 
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.cargarAlergenos();
        this.mensaje = "Registro exitoso.";
        this.success = true;
      }
    });
  }


}
