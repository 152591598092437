import { Destino } from './../../shared/interfaces/destino.type';
import { Component, OnInit,Input } from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { NgxSpinnerService } from 'ngx-spinner'; 
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { Router } from '@angular/router';
import { FormDestinoComponent } from 'src/app/forms/destino/destino.component';
import { AlmacenService } from 'src/app/shared/services/almacen.service';


@Component({
  selector: 'app-card-destino',
  templateUrl: './card-destino.component.html',
  styleUrls: ['./card-destino.component.css']
})
export class CardDestinoComponent implements OnInit {
  
  @Input() destino ;

  constructor(
    private spinner: NgxSpinnerService,
    private almacen : AlmacenService,
    private router: Router,
    private mensajeComponentService : MensajeComponentService,
    private modalService:NzModalService,
  ) {}

  ngOnInit() {
  }

  /**
   * @description Actualizar un destino
   * @author Javier Evans
   */  
  editar(): void {

    console.log("editando");
    console.log(this.destino);

    const modal = this.modalService.create({
        nzContent: FormDestinoComponent,
        nzFooter:null,        
        nzComponentParams: {
          objetoEditar : this.destino,          
        }
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if(result){        
        this.mensajeComponentService.changeMessage("updateDestinos");
      }
    });
  }


  
  /**
   * @description borrar el destino
   * @author Javier Evans
   */
  delete(): void {
    this.spinner.show();
    this.almacen.delete(this.destino).subscribe(res=>{      
      this.mensajeComponentService.changeMessage("updateDestinos");             
      this.spinner.hide();
    },err=>{
      console.log(err);
      this.spinner.hide();      
    });
  }
}
