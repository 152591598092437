import { Insumos } from '../../shared/interfaces/insumos.type';
import { Component, OnInit, Input } from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { FormBuilder, Validators, FormGroup, } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InsumoService } from 'src/app/shared/services/insumo.service';
import { CategoriaInsumoService } from 'src/app/shared/services/categoria-insumo.service';
import { UnidadMedidaService } from 'src/app/shared/services/unidad-medida.service';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service';
import { AgregarMedidaComponent } from '../agregar-medida/agregar-medida.component';
import { AgregarCategoriaInsumosComponent } from '../agregar-categoria-insumos/agregar-categoria-insumos.component';
import { AlergenosService } from 'src/app/shared/services/alergenos.service';

@Component({
  selector: 'app-insumo',
  templateUrl: './insumo.component.html',
  styleUrls: ['./insumo.component.css']
})
export class InsumoComponent implements OnInit {

  // @Input() categoria: string;
  @Input() insumo: any = null;

  success = false;
  mensaje = "";
  insumoForm: FormGroup;
  categoriaForm: FormGroup;
  error = false;
  categorialist: any[];
  medidalist: any[];
  categoriasPrincipales = [];
  categoriasHijas = [];
  addUnidadMed;
  listaAlergenos=[];
  insumoAlergenos=[];
  moneda: String = "";
  
  constructor(
    private modalService: NzModalService,
    private fb: FormBuilder,
    private modal: NzModalRef,
    private spinner: NgxSpinnerService,
    private insumos: InsumoService,
    private categorias: CategoriaInsumoService,
    private medida: UnidadMedidaService,
    private alergenosService:AlergenosService,
    private restauranteService:RestaurtantService,
  ) {

    this.insumoForm = this.fb.group({
      id: [null],
      referencia: [null, [Validators.required]],
      stock: [null, [Validators.required]],
      codigo: [null, [Validators.required]],
      descripcion: [null, [Validators.required]],
      medida: [null, [Validators.required]],
      categoria: [null, [Validators.required]],
      alergenos: [[]],
      subcategoria: [null,],
      precio: [null, [Validators.required]],
    });

    this.categoriaForm = this.fb.group({
      nombre: [null, [Validators.required]],
      categoria: [null,],
    });
  }

  ngOnInit() {



    if (this.insumo != null) {
      // console.log(this.insumo,"insumo con alergeno")

      this.insumos.getInsumoById(this.insumo.id).subscribe(res => {

        console.log(res,"holaaa yes yes")
        this.insumo = res;


        this.insumoForm.get("id").setValue(this.insumo.id);
        this.insumoForm.get("referencia").setValue(this.insumo.referencia);
        this.insumoForm.get("stock").setValue(this.insumo.stock_minimo);
        this.insumoForm.get("codigo").setValue(this.insumo.codigo_barras);
        this.insumoForm.get("descripcion").setValue(this.insumo.descripcion);
        this.insumoForm.get("medida").setValue(this.insumo.unidad_medida_id);
        this.insumoForm.get("precio").setValue(this.insumo.precio);
        this.insumoForm.get("alergenos").setValue(this.insumo.alergenos_ids);

      })  

     
      // this.insumoForm.get("categoria").setValue(this.insumo.categoria_insumos_id);    
    }
    this.cargarCategorias();
    this.cargarUnidadesDeMedida();
    this.cargarAlergenos();

    // Obtener la moneda del restaurante
    this.restauranteService.getMoneda(localStorage.getItem('restaurante_seleccionado_id')).subscribe(res => {
      this.moneda = res.moneda;
    })
  }

  //  FALTA COMPLETAR UN FILTRO PARA LAS CATEGORIAS PARA MOSTRAR TODAS LAS PRINCIPALES MENOS LA QUE TENGO EN PANTALLA
  cargarCategorias() {
    this.categorias.getCategoria().subscribe(res => {
      console.log(res, "categorias");
      this.categorialist = res;
      this.categoriasPrincipales = this.categorialist.filter(cat => !cat.categoria_insumos_padre_id);
      this.categoriasHijas = this.categorialist.filter(cat => cat.categoria_insumos_padre_id == this.insumoForm.value.categoria);
      if (this.insumo != null) {
        let categoria = res.filter(cat => this.insumo.categoria_insumos_id === cat.id)[0];
        console.log(categoria);
        if (categoria.categoria_insumos_padre_id == null) {
          this.insumoForm.get("categoria").setValue(this.insumo.categoria_insumos_id);
        } else {
          this.insumoForm.get("categoria").setValue(categoria.categoria_insumos_padre_id);
          this.insumoForm.get("subcategoria").setValue(this.insumo.categoria_insumos_id);
          this.updateSelectCategorias();
        }

      }
    });
  }

  updateSelectCategorias() {
    this.categoriasHijas = this.categorialist.filter(cat => cat.categoria_insumos_padre_id == this.insumoForm.value.categoria);
   console.log(this.insumoForm.value.categoria,"aqui categoria") 
    if (this.insumoForm.value.categoria=="") {
        const modal = this.modalService.create({
        nzContent: AgregarCategoriaInsumosComponent,
        nzFooter: null,
      });
      modal.afterClose.subscribe((result) => {
      
        console.log('[afterClose] The result is:', result)
        if (result) {
          this.cargarCategorias();
        }
      });
   }
   

  }

  cargarAlergenos(){
    this.alergenosService.getAlergenos().subscribe(res=>{
      this.listaAlergenos=res;
      console.log(this.listaAlergenos,"aqui alergenos")
    })
  }

  cargarUnidadesDeMedida() {
    this.medida.getMedida().subscribe(res => {
      console.log(res, "medidas");
      this.medidalist = res;
    });
  }
  /* FORMULARIO DE CARGAR INSUMO */
  /**
   *@description METODO QUE ENVIA EL REGISTRO DEL FORMULARIO DE INSUMOS. 
  */
  submitForm(): void {
    console.log('enviar');
    for (const i in this.insumoForm.controls) {
      this.insumoForm.controls[i].markAsDirty();
      this.insumoForm.controls[i].updateValueAndValidity();
    }
    let payload = {};
    if (this.insumoForm.valid) {
      this.spinner.show();

      payload['referencia'] = this.insumoForm.value.referencia;
      payload['stock_minimo'] = this.insumoForm.value.stock;
      payload['codigo_barras'] = this.insumoForm.value.codigo;
      payload['descripcion'] = this.insumoForm.value.descripcion;
      payload['unidad_medida_id'] = this.insumoForm.value.medida;
      payload['alergenos_ids'] = this.insumoForm.value.alergenos;
      payload['restaurante_id'] = parseInt(localStorage.getItem("restaurante_seleccionado_id"));
      payload['categoria_insumos_id'] =
        (this.categoriasHijas.length && this.insumoForm.value.subcategoria) ?
          this.insumoForm.value.subcategoria : this.insumoForm.value.categoria;
      payload['precio'] = this.insumoForm.value.precio;


      console.log(payload);

      this.insumos.registroInsumo(payload).subscribe(res => {
        console.log(res);
        this.modal.destroy({ data: true });
        this.spinner.hide();
      }, err => {
        this.mensaje = err.error.errors;
        this.error=true;
        this.spinner.hide();
        console.log(err);
      });

    }


  }
  /* FIN - FORMULARIO DE CARGAR INSUMO */

  /**
   * EDITAR UN INSUMO
   */
  editInsumo() {
    console.log(this.insumoForm.value)
    for (const i in this.insumoForm.controls) {
      this.insumoForm.controls[i].markAsDirty();
      this.insumoForm.controls[i].updateValueAndValidity();
    }
    let payload = {};
    if (this.insumoForm.valid) {
      this.spinner.show();
      payload['id'] = this.insumoForm.value.id;
      payload['referencia'] = this.insumoForm.value.referencia;
      payload['stock_minimo'] = this.insumoForm.value.stock;
      payload['codigo_barras'] = this.insumoForm.value.codigo;
      payload['descripcion'] = this.insumoForm.value.descripcion;
      payload['unidad_medida_id'] = this.insumoForm.value.medida;
      payload['alergenos_ids'] = this.insumoForm.value.alergenos;
      payload['restaurante_id'] = parseInt(localStorage.getItem("restaurante_seleccionado_id"));
      payload['categoria_insumos_id'] =
        (this.categoriasHijas.length && this.insumoForm.value.subcategoria) ?
          this.insumoForm.value.subcategoria : this.insumoForm.value.categoria;
      payload['precio'] = this.insumoForm.value.precio;

      console.log(payload);

      this.insumos.put(payload).subscribe(res => {
        console.log(res);
        this.modal.destroy({ data: true });
        this.spinner.hide();
      }, err => {
        this.mensaje = err.error.errors;
        this.error=true;
        this.spinner.hide();
        console.log(err);
      });

    }

  }

  nuevoUnidad(): void {

    this.insumoForm.value.medida
    if (this.insumoForm.value.medida=="") {
      const modal = this.modalService.create({
        nzContent: AgregarMedidaComponent,
        nzFooter: null,
      });
      modal.afterClose.subscribe((result) => {
        
        console.log('[afterClose] The result is:', result)
        if (result) {
          this.cargarUnidadesDeMedida();        
         
        }
      });
    }
    


  }



}
