import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlergenosService } from 'src/app/shared/services/alergenos.service';
import { Router } from '@angular/router';
import { MensajeComponentService } from 'src/app/shared/services/mensaje-component.service';
import { NzModalService } from 'ng-zorro-antd';
import { AlergenosComponent } from 'src/app/forms/alergenos/alergenos.component';

@Component({
  selector: 'app-card-alergenos',
  templateUrl: './card-alergenos.component.html',
  styleUrls: ['./card-alergenos.component.css']
})
export class CardAlergenosComponent implements OnInit {
  @Input() alergeno: any
  constructor(
    private spinner: NgxSpinnerService,
    private alergenosService:AlergenosService,
    private router: Router,
    private mensajeComponentService: MensajeComponentService,
    private modalService: NzModalService,
  ) { }

  ngOnInit() {
  }


  /**
   * @description Actualizar unidad de equivalencia
   * @author Gabriel Baldez
   */
  editar(): void {

    console.log("editando");
    console.log(this.alergeno);

    const modal = this.modalService.create({
      nzContent: AlergenosComponent,
      nzFooter: null,
      nzComponentParams: {
        objetoEditar: this.alergeno,
      }
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.mensajeComponentService.changeMessage("updateAlergenos");
      }
    });
  }

}
