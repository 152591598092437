import { ComprasService } from 'src/app/shared/services/compras.service';
import { UnidadMedidaService } from 'src/app/shared/services/unidad-medida.service';
import { DevolucionService } from '../../shared/services/devolucion.service';
import { Proveedor } from './../../shared/interfaces/proveedor.type';
import { Util } from './../../shared/util/util';
import { InsumosMovidosService } from './../../shared/services/insumos-movidos.service';
import { ProveedorService } from './../../shared/services/proveedor.service';

import { NgxSpinnerService } from 'ngx-spinner';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { formatDate } from '@angular/common';
import { ProveedorComponent } from '../proveedor/proveedor.component';
import { AgregarMedidaComponent } from '../agregar-medida/agregar-medida.component';
@Component({
  selector: 'app-devolucion',
  templateUrl: './devolucion.component.html',
  styleUrls: ['./devolucion.component.css']
})
export class DevolucionComponent implements OnInit {
  @Input() objetoEditar: any = null;  // Aquí se guarda el objeto a editar (cuando corresponde)
  Form: FormGroup;
  proveedores: any[];
  date = null; // new Date();
  error = false;
  mensaje="";
  listCompras=[];
  listComprasAux=[];
  loadingListaCompra=false;
  listUndMed=[];
  listInsumoWithUndMed=[];
  addProveedor;
  success=false;
  constructor( private fb:FormBuilder,
    
    private modal: NzModalRef,
    private spinner: NgxSpinnerService,    
    private proveedorService:ProveedorService,
    private insumosMovidos: InsumosMovidosService,
    private devolucionService: DevolucionService,
    private unidadMedidaService: UnidadMedidaService,
    private util:Util,
    private compraService: ComprasService,
    private modalService: NzModalService,
    
    ) {
      
     }

  ngOnInit() {
    console.log(this.objetoEditar,"edicionnn")
    if(this.objetoEditar){
      this.Form = this.fb.group({
        id: [this.objetoEditar.id],           
        fecha: [formatDate(this.objetoEditar.fecha, 'yyyy-MM-dd ', 'en'), [Validators.required]],      
        proveedor_id: [this.objetoEditar.proveedor_id, [Validators.required]],
        detalles: [this.objetoEditar.detalles, [Validators.required]],
        // incidencia: [this.objetoEditar.incidencia, [Validators.required]],
        // motivo: [this.objetoEditar.motivo, [Validators.required]],
        restaurante_id: [Number(localStorage.getItem('restaurante_seleccionado_id')), [Validators.required]],
        insumos_movidos: this.fb.array([]),
      });
      this.loadingListaCompra=true;
      this.compraService.indexWithUnit().subscribe(res=>{
        console.log(res);
        this.listInsumoWithUndMed=res;
        this.devolucionService.get(this.objetoEditar.id).subscribe(res=>{
          console.log(res);
          this.loadingListaCompra=false;
          res.insumos_movidos.forEach(element => {
            this.agregarItem(element);
          });
        },err=>{
          this.loadingListaCompra=false;
          console.log(err);
        });
      },err=>{
        console.log(err);
      });
     
    }else{      
      this.Form = this.fb.group({
        id: [null],   
        fecha: [null, [Validators.required]],      
        proveedor_id: [null, [Validators.required]],
        detalles: [null, [Validators.required]],
        // incidencia: [null, [Validators.required]],
        // motivo: [null, [Validators.required]],
        restaurante_id: [Number(localStorage.getItem('restaurante_seleccionado_id')), [Validators.required]],
        insumos_movidos: this.fb.array([]),
      });

      this.compraService.indexWithUnit().subscribe(res=>{
        console.log(res);
        this.listInsumoWithUndMed=res;
      },err=>{
        console.log(err);
      });
    }
    this.proveedorService.getProveedor().subscribe(res => {
      console.log(res, "insumos");
      this.proveedores = res;
    });
    this.unidadMedidaService.getMedida().subscribe(res=>{
      console.log(res);
      this.listUndMed=res;
    },err=>{
      console.log(err);
    });
   
    console.log(this.Form.get('fecha').value);
    
  }


  agregarItem(insumo_movido) {
    console.log(insumo_movido,"insumo movido");
    let control = <FormArray>this.Form.controls['insumos_movidos'];

    control.push(this.crearInsumoDevolucion(insumo_movido))

    console.log(<FormArray>this.Form.controls['insumos_movidos']);   
  }


  /**
  * Crea el insumo_movido que será agregado al listado de insumos_movidos de la devolución
  * @param insumo_movido insumo_movido que será devuelto 
  */
  crearInsumoDevolucion(insumo_movido): FormGroup {
    this.borrarItemCompraList(insumo_movido);
    this.error=false;

    // Crear insumo_movido en base al insumo_movido previo de la devolución
    if(this.objetoEditar){
      return this.fb.group({
        id:                     [insumo_movido.id],
        cantidad:               [insumo_movido.cantidad_absoluta, [Validators.required]],
        movimiento_origen_id:   [insumo_movido.movimiento_origen_id],
        insumo_id:              [insumo_movido.insumo_id, [Validators.required]],
        insumo_nombre:          [insumo_movido.insumo.referencia],      
        unidad_medida_id:       [insumo_movido.unidad_medida_id],
        unidades_medida:        [this.getUnidadesMedidasInsumo(insumo_movido.insumo_id)],
        almacen_origen_id:      [insumo_movido.almacen_origen_id],
        almacen_origen_nombre:  [insumo_movido.almacen_origen.nombre],   
        incidencia:             [insumo_movido.incidencia],
        motivo:                 [insumo_movido.motivo],
      })
    }else{
      // Crear insumo_movido en base a un insumo_movido de una compra
      return this.fb.group({
        id:                     [insumo_movido.id],
        cantidad:               [insumo_movido.cantidad, [Validators.required]],
        movimiento_origen_id:   [insumo_movido.id], // insumo_movido comprado
        insumo_id:              [insumo_movido.insumo_id, [Validators.required]],
        insumo_nombre:          [insumo_movido.insumo.referencia],
        unidad_medida_id:       [insumo_movido.unidad_medida_id],
        unidades_medida:        [this.getUnidadesMedidasInsumo(insumo_movido.insumo_id)],
        almacen_origen_id:      [insumo_movido.almacen_destino_id],       // origen de insumo devuelto = destino de insumo comprado
        almacen_origen_nombre:  [insumo_movido.almacen_destino.nombre],   //
        incidencia:             "",
        motivo:                 "",
      })
    }
   
  }

  getUnidadesMedidasInsumo(idInsumo){
    let insumo = this.listInsumoWithUndMed.find(element => element.id == idInsumo);
    return insumo.unidades_medidas_opciones;
  }

  getInsumosDevolucion(): FormArray {
    // console.log(this.Form.get("insumos_movidos") as FormArray)
    return this.Form.get("insumos_movidos") as FormArray;

  }
  /**
   * elimina item de la lista de insumos devoluciones, agrega al listado de transacciones recientes el movimiento de origen
   * @param i 
   * @param compra 
   */
  borrarItem(i: number, compra) {
    console.log(compra)
    if(!this.objetoEditar){
      this.listCompras.push(this.listComprasAux.find(element => element.id == compra.value.id));
    }
    (<FormArray>this.Form.controls['insumos_movidos']).removeAt(i);
  }

  /**
   * ELIMINA DE LA LISTA DE COMPRAS DE INSUMOS O MOVIMIENTOS DE TIPO COMPRA EL OBJETO QUE RECIBE
   * @param compra OBJETO COMPRA
   */
  borrarItemCompraList(compra) {
    let aux = this.util.filtrarArray(this.listCompras,compra)
    this.listCompras = aux;
  }

  /**
   *@description METODO QUE ENVIA EL REGISTRO 
  */
  submitForm(): void {
    console.log('enviar',this.getInsumosDevolucion().controls.length);        
    for (const i in this.Form.controls) {
      this.Form.controls[i].markAsDirty();
      this.Form.controls[i].updateValueAndValidity();
     
    }
    for (let i of this.getInsumosDevolucion().controls) {

      for (let a in i['controls']) {
        // console.log(a);
        i['controls'][a].markAsDirty();
        i['controls'][a].updateValueAndValidity();
      }
    } 
    if(this.getInsumosDevolucion().controls.length >= 1){
      let fecha=formatDate(this.Form.value.fecha, 'yyyy-MM-dd ', 'en');     
      this.executeOnChange=false;
      this.Form.get('fecha').setValue(fecha);
      
      console.log(this.Form.valid,this.Form.value);
      if(this.Form.valid){
        this.spinner.show();
        let servicio = this.objetoEditar?   this.devolucionService.edit(this.Form.value):this.devolucionService.create(this.Form.value)
        servicio.subscribe(res=>{
          console.log(res);
          if(res){
            this.resetForms();
            this.modal.destroy({ data: true }); 
            this.spinner.hide();
          }
        },err=>{
          this.spinner.hide();
          console.log(err);
          this.mensaje = err.error.errors;
        this.error=true;
        });
      }
    }else{
      this.error=true;
      this.mensaje="Debe agregar un insumo a la lista de devolucion";
    }
  }
  /**
   * Metodo para buscar movimientos tipo compras recientes dada una fecha y un proveedor
   * @param from variable para saber desde donde viene el evento
   */
  executeOnChange=true;
  onChange(): void {
    
    if(this.executeOnChange){
      let fecha =null;
      if(this.Form.value.fecha!=null){
        fecha=formatDate(this.Form.value.fecha, 'yyyy-MM-dd ', 'en');           
      }
       let proveedorId=this.Form.value.proveedor_id;
       if(proveedorId){
        if(this.getInsumosDevolucion().controls.length==0){
          this.loadingListaCompra=true;
          this.listCompras= [];
          this.listComprasAux= [];   
          this.insumosMovidos.compras(proveedorId).subscribe(res => {
            this.loadingListaCompra=false;
              console.log(res, "compras");   
              let that=this;
            // console.log(res.filter(cat => cat.fecha==formatDate(this.Form.value.fecha, 'yyyy-MM-dd ', 'en')));
            // this.listCompras= res.filter(function(cat){
            //   let a =cat.fecha.trim();
            //   let b =formatDate(that.Form.value.fecha, 'yyyy-MM-dd ', 'en').trim();              
            //   console.log(cat.fecha,formatDate(that.Form.value.fecha, 'yyyy-MM-dd ', 'en'));
            //   console.log(a,b)
            //   if(a==b){
            //     console.log(cat.fecha,formatDate(that.Form.value.fecha, 'yyyy-MM-dd ', 'en'));
            //     return true;
            //   }else{
            //     console.log("false");
            //     return false;
            //   }
            // });
            this.listCompras=res;
            this.listComprasAux= this.listCompras;               
            }, err => {                   
              this.loadingListaCompra=false;        
              console.log(err);
            });
        }else{

        
            (<FormArray>this.Form.controls['insumos_movidos']).reset();
            for(let i=this.getInsumosDevolucion().length;i>=0;i--){
              // alert(i);
              (<FormArray>this.Form.controls['insumos_movidos']).removeAt(i);

            }
            // (<FormArray>this.Form.controls['insumos_movidos']).length
            this.loadingListaCompra=true;
            this.listCompras= [];
            this.listComprasAux= [];   
            
            this.insumosMovidos.compras(proveedorId).subscribe(res => {
              this.loadingListaCompra=false;
                console.log(res, "compras");              
              this.listCompras= res;
              this.listComprasAux= res;               
              }, err => {                   
                this.loadingListaCompra=false;        
                console.log(err);
              });
          
        }
        
      }
    }else{
      this.executeOnChange=true;
    }
    // console.log('onChange: ', fecha +" "+proveedorId);
  }


  /**
   * VOLVER A DATOS PREDEFINIDOS EN EL FORMULARIO
   */
  resetForms(){
    this.Form.reset();
    (<FormArray>this.Form.controls['insumos_movidos']).reset();
    (<FormArray>this.Form.controls['insumos_movidos']).removeAt(0);
    
    this.listCompras=[];
    this.listComprasAux=[];
  }
  getUndMed(compra){
    return this.listUndMed.find(element => element.id == compra.unidad_medida_id).nombre
  }
  nuevoProveedor(): void {

    if (this.Form.value.proveedor_id=="") {
        const modal = this.modalService.create({
        nzContent: ProveedorComponent,
        nzFooter: null,
        nzWidth: 800

      });
      modal.afterClose.subscribe((result) => {
        
        console.log('[afterClose] The result is:', result)
        if (result) {
          this.proveedorService.getProveedor().subscribe(res => {
            console.log(res, "insumos");
            this.proveedores = res;
          });
        }
      });
    }


  }

  nuevaUnidad(): void {

    
    this.Form.value.insumos_movidos.forEach(element => {

      console.log(element.unidad_medida_id,"elementos")

      if (element.unidad_medida_id == 0) {
        const modal = this.modalService.create({
          nzContent: AgregarMedidaComponent,
          nzFooter: null,
        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {

            this.mensaje = "Registro exitoso.";
            this.success = true;

          }
        });
      }
      
    });
    
    
  
}
}
