import { MensajeComponentService } from './mensaje-component.service';
import { ExpireSessionComponent } from './../../components/expire-session/expire-session.component';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { TokenStorage } from 'src/app/shared/util/token.storage';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { RestResponse } from '../util/restResponse';
import { map } from 'rxjs/operators';

import { User } from '../interfaces/user.type';
import { rutaApi } from '../util/constants';

const USER_AUTH_API_URL = '/api-url';

@Injectable(
    { providedIn: "root" }
)
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;


    constructor(
        private http: HttpClient,
        private token: TokenStorage,
        private router: Router,
        private modalService: NzModalService,
        private mcs: MensajeComponentService



    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.mcs.currentMessage.subscribe(message => {
            if(message === 'refreshTokenExpired'){
                this.refreshExpireSession();
            }
            if(message === 'logout'){
                this.logout();
            }
        });
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string, remember_token: number): Promise<any> {
        let resp = {
            login: false,
            error: ""
        };
        return new Promise(Resolve => {
            this.http.post<any>(rutaApi("/login"), { email: email, password: password, remember_token: remember_token }).subscribe(object => {
                this.expireSession(object);
                this.token.saveToken(object.token).then((R) => {
                    this.getCurrentUserApi().subscribe(res => {
                        localStorage.setItem('currentUser', JSON.stringify(res));
                        this.currentUserSubject.next(res);
                        resp.login = true;
                        Resolve(resp);
                    }, err => {
                        resp.login = false;
                        resp.error = err.error;
                        Resolve(resp);
                    });
                });
            }, err => {
                resp.login = false;
                resp.error = err.error;
                Resolve(resp);
            });
        });
    }


    getCurrentUserApi() {
        return this.http.get<any>(rutaApi("/current_user"));
    }


    logout(withToken = null) {
        try {
            clearInterval(this.interval);
            this.interval = null;
        } catch (Ex) {

        }


        localStorage.removeItem('currentUser');
        localStorage.removeItem('restaurante_seleccionado_id');
        localStorage.removeItem('expires_in');
        if (withToken) {
            this.logoutApi().subscribe(res => {

            })
        }
        this.currentUserSubject.next(null);
        this.router.navigate(['/login']);

    }



    logoutApi() {
        return this.http.post<any>(rutaApi("/logout"), "");
    }


    refreshToken() {
        return this.http.post<any>(rutaApi("/refresh_token"), "");
    }

    /**
     * llamar desd el componente de expire session
     */
    refreshExpireSession(){
        this.refreshToken().subscribe(res=>{
            console.log(res);
            this.token.saveToken(res.token).then((R) => {     
            });
            this.expireSession(res);
            this.mcs.changeMessage('closeExpireSessionModal');
          },err=>{
            console.log(err);
            this.logout();
            this.mcs.changeMessage('closeExpireSessionModal');
          });
    }

    timeLeft;
    interval = null;
    modal = null;
    expireSession(res = null) {
        if (res == null) {
            this.timeLeft = localStorage.getItem("expires_in");
        } else {
            this.timeLeft = res.expires_in;
        }
        localStorage.setItem("expires_in", this.timeLeft);
        let that = this;
        if (!this.interval) {
            this.interval = setInterval(function () {
                if (that.timeLeft > 0 && that.timeLeft <= 300) {
                    //mostrar modal
                    if (!that.modal) {
                        that.modal = that.modalService.create({
                            nzContent: ExpireSessionComponent,
                            nzFooter: null,
                            nzClosable: true,
                            nzMaskClosable: false,
                            nzKeyboard: false
                        });
                    }

                    that.timeLeft--;

                    localStorage.setItem("expires_in", that.timeLeft);
                } else if (that.timeLeft > 0) {
                    that.timeLeft--;
                    localStorage.setItem("expires_in", that.timeLeft);
                } else {
                    that.logout();
                    that.modal.destroy();
                    clearInterval(that.interval);
                }
            }, 1000);
        }
    }




}
