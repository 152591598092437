import { rutaApi } from 'src/app/shared/util/constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  constructor(private http: HttpClient) { }


  
   /**
   * 
   * @description 
   * @author 
   * @returns Observable<any> 
   */
  public indicadores(): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/reporte/indicadores?restaurante_id='+ rest));
  }
 
   /**
   * 
   * @description 
   * @author 
   * @returns Observable<any> 
   */
  public indicadoresConFiltro(filtro): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/reporte/indicadores?restaurante_id='+ rest+'&filtrar_stock='+filtro));
  }


  
  
   /**
   * 
   * @description 
   * @author 
   * @returns Observable<any> 
   */
  public ventas(anio,mes): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/reporte/ventas_comparacion?restaurante_id='+rest+'&anio='+anio+'&mes='+mes ));
  }



   
   /**
   * 
   * @description 
   * @author 
   * @returns Observable<any> 
   */
  public platos(anio): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/reporte/ventas_top?restaurante_id='+rest+'&anio='+anio ));
  }



  
   /**
   * 
   * @description 
   * @author 
   * @returns Observable<any> 
   */
  public ventasPorTipo(anio): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/reporte/ventas_por_tipo?restaurante_id='+rest+'&anio='+anio ));
  }



   /**
   * 
   * @description 
   * @author 
   * @returns Observable<any> 
   */
  public sideBar(tipo): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/resumen?tipo='+tipo+'&restaurante_id='+rest));
  }

   



     /**
   * 
   * @description 
   * @author 
   * @returns Observable<any> 
   */
  public compras(anio,mes): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/reporte/compras_comparacion?restaurante_id='+rest+'&anio='+anio+'&mes='+mes ));
  }




  
   
   /**
   * 
   * @description 
   * @author 
   * @returns Observable<any> 
   */
  public insumosTop(anio): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/reporte/compras_top?restaurante_id='+rest+'&anio='+anio ));
  }

  /**
  * 
  * @description 
  * @author 
  * @returns Observable<any> 
  */
  public ventasPorcentaje(anio): Observable<any> {
    let rest = localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/reporte/porc_materia_prima?restaurante_id=' + rest + '&anio=' + anio));
  }


  /**
  * ingenieria de menu
  * @description 
  * @author 
  * @returns Observable<any> 
  */
   public matrizDimensiones(start,end, dimension): Observable<any> {
    let rest = localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/reporte/matriz_dos_dimensiones?restaurante_id=' + rest + '&inicio=' + start + '&fin=' + end+ '&dimensiones=' + dimension));
  }


  
  /**
  * ingenieria de menu
  * @description 
  * @author 
  * @returns Observable<any> 
  */
   public rentabilidad(start,end): Observable<any> {
    let rest = localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/reporte/rentabilidad?restaurante_id=' + rest + '&inicio=' + start + '&fin=' + end));
  }


}
