import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { RecetaService } from 'src/app/shared/services/receta.service';
import { InsumoService } from 'src/app/shared/services/insumo.service';
import { AlmacenService } from 'src/app/shared/services/almacen.service';
import { UnidadMedidaService } from 'src/app/shared/services/unidad-medida.service';
import { MenuService } from 'src/app/shared/services/menu.service';
import { Util } from 'src/app/shared/util/util';
import { FormMenuComponent } from '../menu/menu.component';
import { AgregarMedidaComponent } from '../agregar-medida/agregar-medida.component';
import { InsumoComponent } from '../insumo/insumo.component';
import { FormDestinoComponent } from '../destino/destino.component';
import { MovimientosService } from 'src/app/shared/services/movimientos.service';
import { ProveedorService } from 'src/app/shared/services/proveedor.service';
import { RestaurtantService } from 'src/app/shared/services/restaurtant.service';
import { ProveedorComponent } from '../proveedor/proveedor.component';
@Component({
  selector: 'app-movimiento',
  templateUrl: './movimiento.component.html',
  styleUrls: ['./movimiento.component.css']
})
export class MovimientoComponent implements OnInit {

  @Input() tipo: any;
  @Input() objetoEditar: any;

  salidaForm: FormGroup;
  entradaForm: FormGroup;
  menulist: any;
  banView = true;
  error = false;
  mensaje = "";
  success = false;
  mostrarPlus = false;
  mostrarPlusMovido = false;
  insumoslist: any;
  almacenlist: any;
  unidadeslist: any;
  proveedoreslist: any;
  proveedorInsumos:any;
  moneda: String = "";

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private modal: NzModalRef,
    private spinner: NgxSpinnerService,
    private transaccionService: MovimientosService,
    private recetaService: RecetaService,
    private insumoService: InsumoService,
    private almacenService: AlmacenService,
    private unidadesService: UnidadMedidaService,
    private menuService: MenuService,
    private proveedorService: ProveedorService,
    private restauranteService:RestaurtantService,
    private util: Util,
    private movimientoService:MovimientosService
  ) {
    this.salidaForm = this.fb.group({
      fecha: [null, [Validators.required]],
      tipo: [null, [Validators.required]],
      restaurante_id: [Number(localStorage.getItem('restaurante_seleccionado_id')), [Validators.required]],
      insumos_movidos: this.fb.array([]),
      items_venta: this.fb.array([]),
      total: [0],
    });

    this.entradaForm = this.fb.group({
      tipo: [null, [Validators.required]],
      fecha: [null, [Validators.required]],
      referencia: [null, [Validators.required]],
      proveedor_id: [null, [Validators.required]],
      insumos_proveedor: [null],// SE CREO PARA MOSTRAR LOS INSUMOS DEL PROVEEDOR
      restaurante_id: [Number(localStorage.getItem('restaurante_seleccionado_id')), [Validators.required]],
      insumos_movidos: this.fb.array([this.crearInsumosMovidosEntrada()]),
    });
  }

  ngOnInit() {



    this.salidaForm.get('tipo').setValue('otra_salida');
    this.entradaForm.get('tipo').setValue('otra_entrada');


    if (this.objetoEditar) {
      this.banView = false;
      this.insumoService.indexWithUnit().subscribe(res => {
        this.insumoslist  = res

        if(this.objetoEditar.tipo=="otra_entrada"){
          this.insumoService.insumosWhitProveedor(this.objetoEditar.proveedor_id).subscribe(res => {
            this.proveedorInsumos = res;
            console.log(this.proveedorInsumos, "edicion de la entrada")
          })
          this.entradaForm = this.fb.group({
            id: [this.objetoEditar.id, [Validators.required]],
            tipo: [null, [Validators.required]],
            fecha: [formatDate(this.objetoEditar.fecha, 'yyyy-MM-dd HH:mm:ss', 'en'), [Validators.required]],
            referencia: [this.objetoEditar.referencia, [Validators.required]],
            proveedor_id: [this.objetoEditar.proveedor_id, [Validators.required]],
            restaurante_id: [Number(localStorage.getItem('restaurante_seleccionado_id')), [Validators.required]],
            insumos_movidos: this.fb.array([]),
          });
          this.entradaForm.get('tipo').setValue('otra_entrada');
          this.movimientoService.get(this.objetoEditar).subscribe(res => {
            console.log(res, "objeto con id");
            res.insumos_movidos.forEach(element => {
              // this.agregarItem(element);
              let control = <FormArray>this.entradaForm.controls['insumos_movidos'];
              console.log(control);
              let data =this.fb.group({
                fecha_vencimiento: [element.fecha_vencimiento, [Validators.required]],
                insumo_id: [element.insumo_id, [Validators.required]],
                unidad_medida_id: [element.unidad_medida_id, [Validators.required]],
                almacen_destino_id: [element.almacen_destino_id, [Validators.required]],
                cantidad: [element.cantidad, [Validators.required]],
                precio_unitario: [element.precio_unitario, [Validators.required]],

                total: [element.precio_unitario*element.cantidad],
              });
              control.push(data);

            });
            this.calculosInsumosMovidos();
            this.calculaTotal();
            this.banView=true;
          });
        }else if(this.objetoEditar.tipo=="otra_salida"){
          this.salidaForm = this.fb.group({
            id: [this.objetoEditar.id, [Validators.required]],
            tipo: [null, [Validators.required]],
            fecha: [formatDate(this.objetoEditar.fecha, 'yyyy-MM-dd HH:mm:ss', 'en'), [Validators.required]],
            referencia: [this.objetoEditar.referencia, [Validators.required]],
            proveedor_id: [this.objetoEditar.proveedor_id, [Validators.required]],
            restaurante_id: [Number(localStorage.getItem('restaurante_seleccionado_id')), [Validators.required]],
            insumos_movidos: this.fb.array([]),
            items_venta: this.fb.array([]),
            total: [0],
          });

          this.salidaForm.get('tipo').setValue('otra_salida');
          this.movimientoService.get(this.objetoEditar).subscribe(res => {
            console.log(res, "objeto con id");
            res.insumos_movidos.forEach(element => {
              // this.agregarItem(element);
              let control = <FormArray>this.salidaForm.controls['insumos_movidos'];
              console.log(control);
              let data =this.fb.group({
                cantidad: [element.cantidad_absoluta, [Validators.required]],
                precio_unitario: [element.precio_unitario, [Validators.required]],
                insumo_id: [element.insumo_id, [Validators.required]],
                unidad_medida_id: [element.unidad_medida_id, [Validators.required]],
                almacen_origen_id: [element.almacen_origen_id, [Validators.required]],
                total: [null],
              });
              control.push(data);

            });

            res.items_venta.forEach(element => {
              // this.agregarItem(element);
              let control = <FormArray>this.salidaForm.controls['items_venta'];
              console.log(control);
              let data =this.fb.group({
                cantidad: [element.cantidad, [Validators.required]],
              precio_unitario: [element.precio_unitario, [Validators.required]],
              receta_id: [element.receta_id, [Validators.required]],
              total: [{value:null,disabled: true}],
              });
              control.push(data);

            });



            this.calculosMenu();
            this.actualizarTotalInsumoWithEquivalencia();
            // this.calculosInsumosMovidos();
            // this.calculaTotal();
            this.banView=true;
          });
        }


     //     this.salidaForm.get('tipo').setValue('otra_salida');


      });




    }else{

      this.listarInsumos();
    }
    this.listarMenu();
    // this.listarInsumos();
    this.listarUnidades();
    this.listarAlmacen();
    this.listarProveedores();

    // Obtener la moneda del restaurante
    this.restauranteService.getMoneda(localStorage.getItem('restaurante_seleccionado_id')).subscribe(res => {
      this.moneda = res.moneda;
    })

  }

  listarMenu() {
    this.menuService.index().subscribe(res => {
      this.menulist = res;
      console.log(this.menulist, "aqui los menus")
    })
  }

  listarInsumos() {
    // this.insumoService.getInsumos().subscribe(res => {
    //   this.insumoslist = res;
    //   console.log(this.insumoslist, "aqui los insumos")
    // })
    this.insumoService.indexWithUnit().subscribe(res => {
      console.log(res, "insumos");
      this.insumoslist = res;
    });
  }

  listarAlmacen() {
    this.almacenService.index().subscribe(res => {
      this.almacenlist = res;
      console.log(this.almacenlist, "aqui los almacenes")
    })
  }

  listarUnidades() {
    this.unidadesService.getMedida().subscribe(res => {
      this.unidadeslist = res;
      console.log(this.unidadeslist, "aqui las unidades de medida")
    })
  }

  listarProveedores() {

    this.proveedorService.getProveedor().subscribe(res => {
      this.proveedoreslist = res;
      console.log(this.proveedoreslist, "aqui los proveedores")
    })
  }

  agregarItem() {


    let control = <FormArray>this.salidaForm.controls['items_venta'];

    control.push(this.crearItemsVentas())

    console.log(this.salidaForm.get("items_venta").value);

    console.log(this.getItemsVentas().controls);
  }

  agregarItemInsumo() {


    let control = <FormArray>this.salidaForm.controls['insumos_movidos'];

    control.push(this.crearInsumosMovidos())

    console.log(this.salidaForm.get("insumos_movidos").value);

    console.log(this.getInsumosMovidos().controls);
  }

  agregarItemInsumoEntrada() {
    let control = <FormArray>this.entradaForm.controls['insumos_movidos'];
    control.push(this.crearInsumosMovidosEntrada())
  }

  crearItemsVentas(): FormGroup {
    return this.fb.group({
      cantidad: [null, [Validators.required]],
      precio_unitario: [null, [Validators.required]],
      receta_id: [null, [Validators.required]],
      total: [{value:null,disabled: true}],
    });

  }

  crearInsumosMovidos(): FormGroup {
    return this.fb.group({
      cantidad: [null, [Validators.required]],
      precio_unitario: [null, [Validators.required]],
      insumo_id: [null, [Validators.required]],
      unidad_medida_id: [null, [Validators.required]],
      almacen_origen_id: [null, [Validators.required]],
      total: [null],
    });

  }

  crearInsumosMovidosEntrada(): FormGroup {
    return this.fb.group({
      cantidad: [null, [Validators.required]],
      fecha_vencimiento: [null, [Validators.required]],
      precio_unitario: [null, [Validators.required]],
      insumo_id: [null, [Validators.required]],
      unidad_medida_id: [null, [Validators.required]],
      almacen_destino_id: [null, [Validators.required]],
    });

  }

  getItemsVentas(): FormArray {
    return this.salidaForm.get("items_venta") as FormArray;
  }

  getInsumosMovidos(): FormArray {
    return this.salidaForm.get("insumos_movidos") as FormArray;
  }

  getInsumosMovidosEntrada(): FormArray {
    return this.entradaForm.get("insumos_movidos") as FormArray;
  }

  borrarItem(i: number) {

    (<FormArray>this.salidaForm.controls['items_venta']).removeAt(i);

      this.calculaTotal();

  }

  borrarItemInsumo(i: number) {

    (<FormArray>this.salidaForm.controls['insumos_movidos']).removeAt(i);

      this.calculaTotal();


  }

  borrarItemInsumoEntrada(i: number) {

    (<FormArray>this.entradaForm.controls['insumos_movidos']).removeAt(i);
  }

  nuevoMenu(ev) {

    this.salidaForm.value.items_venta.forEach(element => {

      if (element.receta_id == "") {

        const modal = this.modalService.create({
          nzContent: FormMenuComponent,
          nzFooter: null,
          nzWidth: 900

        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
            this.listarMenu();
          }
        });

      }

    });


    let controls;
    if(this.tipo=="otra_salida"){
      controls = <FormArray>this.salidaForm.controls['items_venta'];
    }
    this.menulist.forEach(element => {
      if (element.id == ev) {
        controls.controls.forEach(elemento => {
          if (elemento.value.receta_id == ev) {
              elemento.get('precio_unitario').setValue(element.precio_venta);


          }
        });
      }

    });
    this.calculosMenu();
  }

  nuevoProveedor(ev) {

    console.log(ev, "id del proveedor seleccionado")

    this.insumoService.insumosWhitProveedor(ev).subscribe(res => {
      this.proveedorInsumos = res;
      console.log(this.proveedorInsumos, "aquiii")
    })

    if (this.entradaForm.value.proveedor_id == "") {
      const modal = this.modalService.create({
        nzContent: ProveedorComponent,
        nzFooter: null,
        nzWidth: 800

      });
      modal.afterClose.subscribe((result) => {

        console.log('[afterClose] The result is:', result)
        if (result) {
        this.listarProveedores();
        }
      });

    }


  }

  nuevaMedida(event=null,items=null) {
    this.entradaForm.value.insumos_movidos.forEach(element => {

      if (element.unidad_medida_id == 0) {
        this.mostrarPlusMovido = true
        const modal = this.modalService.create({
          nzContent: AgregarMedidaComponent,
          nzFooter: null,


        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
            this.mensaje = "Registro exitoso.";
            this.success = true;
          }
        });

      } else if (element.unidad_medida_id >= 1) {
        this.mostrarPlusMovido = false
      }

    });


    this.salidaForm.value.insumos_movidos.forEach(element => {

      if (element.unidad_medida_id == 0) {
        this.mostrarPlusMovido = true
        const modal = this.modalService.create({
          nzContent: AgregarMedidaComponent,
          nzFooter: null,


        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
            this.mensaje = "Registro exitoso.";
            this.success = true;
          }
        });

      } else if (element.unidad_medida_id >= 1) {
        this.mostrarPlusMovido = false
      }

    });

    this.actualizarTotalInsumoWithEquivalencia(event,items);

  }

  nuevoInsumo(ev) {

    this.entradaForm.value.insumos_movidos.forEach(element => {

      if (element.insumo_id == "") {
        const modal = this.modalService.create({
          nzContent: InsumoComponent,
          nzFooter: null,
          nzWidth:1000


        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
            this.listarInsumos();
          }
        });

      }

    });

    this.salidaForm.value.insumos_movidos.forEach(element => {

      if (element.insumo_id == "") {

        const modal = this.modalService.create({
          nzContent: InsumoComponent,
          nzFooter: null,
          nzWidth:1000


        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
            this.listarInsumos();
          }
        });

      }

    });

    let controls;
    if(this.tipo=="otra_entrada"){
      controls = <FormArray>this.entradaForm.controls['insumos_movidos'];
    }else{
      controls = <FormArray>this.salidaForm.controls['insumos_movidos'];
    }
    this.insumoslist.forEach(element => {
      if (element.id == ev) {
        controls.controls.forEach(elemento => {
          if (elemento.value.insumo_id == ev) {
            try{
              elemento.get('precio_unitario').setValue(element.precio_estimado);
            }catch(except){

            }
            elemento.get('unidad_medida_id').setValue(element.unidad_medida_id);

          }
        });
      }

    });
    this.calculosInsumosMovidos();
    // this.calculosMenu();
  }

  nuevoAlmacen() {
    this.entradaForm.value.insumos_movidos.forEach(element => {

      if (element.almacen_destino_id == "") {

        const modal = this.modalService.create({
          nzContent: FormDestinoComponent,
          nzFooter: null,


        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
           this.listarAlmacen();
          }
        });

      }

    });


    this.salidaForm.value.insumos_movidos.forEach(element => {

      if (element.almacen_origen_id == "") {

        const modal = this.modalService.create({
          nzContent: FormDestinoComponent,
          nzFooter: null,


        });
        modal.afterClose.subscribe((result) => {

          console.log('[afterClose] The result is:', result)
          if (result) {
            this.listarAlmacen();
          }
        });

      }

    });
  }

  submitForm(): void {
    console.log("AQUIII", this.salidaForm.get("items_venta") as FormArray);
    for (const i in this.salidaForm.controls) {
      this.salidaForm.controls[i].markAsDirty();
      this.salidaForm.controls[i].updateValueAndValidity();

    }

    for (let i of this.getItemsVentas().controls) {

      for (let a in i['controls']) {
        console.log(a);
        i['controls'][a].markAsDirty();
        i['controls'][a].updateValueAndValidity();
      }
    }

    for (let i of this.getInsumosMovidos().controls) {

      for (let a in i['controls']) {
        console.log(a);
        i['controls'][a].markAsDirty();
        i['controls'][a].updateValueAndValidity();
      }
    }




    if (this.salidaForm.valid) {
      this.spinner.show();
      let fecha = formatDate(this.salidaForm.value.fecha, 'yyyy-MM-dd HH:mm:ss', 'en');
      this.salidaForm.get('fecha').setValue(fecha);


      let servicio = this.transaccionService.post(this.salidaForm.value)
      servicio.subscribe(res => {
        console.log(res);
        if (res) {
          this.modal.destroy({ data: true });
          this.spinner.hide();
        }
      }, err => {
        // this.spinner.hide();
        this.mensaje = err.error.errors;
        this.error = true;
        this.spinner.hide();
        console.log(err);
      });

    }


  }

  submitEntrada(): void {
    console.log("AQUIII", this.entradaForm.get("items_venta") as FormArray);
    for (const i in this.entradaForm.controls) {
      this.entradaForm.controls[i].markAsDirty();
      this.entradaForm.controls[i].updateValueAndValidity();

    }

    for (let i of this.getItemsVentas().controls) {

      for (let a in i['controls']) {
        console.log(a);
        i['controls'][a].markAsDirty();
        i['controls'][a].updateValueAndValidity();
      }
    }



    for (let i of this.getInsumosMovidosEntrada().controls) {

      for (let a in i['controls']) {
        console.log(a);
        i['controls'][a].markAsDirty();
        i['controls'][a].updateValueAndValidity();
      }
    }

    if (this.entradaForm.valid) {
      this.spinner.show();
      let fecha = formatDate(this.entradaForm.value.fecha, 'yyyy-MM-dd HH:mm:ss', 'en');
      this.entradaForm.get('fecha').setValue(fecha);

      let control = <FormArray>this.entradaForm.controls['insumos_movidos'];
      control.controls.forEach(elemento => {
        elemento.get('fecha_vencimiento').setValue(formatDate(elemento.value.fecha_vencimiento, 'yyyy-MM-dd ', 'en'));
      });

      let servicio = this.transaccionService.post(this.entradaForm.value)
      servicio.subscribe(res => {
        console.log(res);
        if (res) {
          this.modal.destroy({ data: true });
          this.spinner.hide();
        }
      }, err => {
        // this.spinner.hide();
        this.mensaje = err.error.errors;
        this.error = true;
        this.spinner.hide();
        console.log(err);
      });

    }


  }


    /**
   * Obtener las opciones de unidades de medidas de un insumo
   * @param insumo_id
   * @return Array de unidades de medidas
   */
  getUnidadesMedidasInsumo(insumo_id: number) {
    if(insumo_id!=0){
      return (insumo_id && this.insumoslist && this.insumoslist.length) ? this.insumoslist.find(element => element.id == insumo_id).unidades_medidas_opciones : [];
    }
  }



  calculosMenu(){

    let controls;

    controls = <FormArray>this.salidaForm.controls['items_venta'];

        controls.controls.forEach(elemento => {

            elemento.get('total').setValue(elemento.value.cantidad*elemento.value.precio_unitario);

            // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
        });
        this.calculaTotal();

  }



  calculosInsumosMovidos(){

    let controls;
    if(this.tipo=="otra_entrada"){
      controls = <FormArray>this.entradaForm.controls['insumos_movidos'];
    }else{
      controls = <FormArray>this.salidaForm.controls['insumos_movidos'];
     controls.controls.forEach(elemento => {

            elemento.get('total').setValue(elemento.value.cantidad*elemento.value.precio_unitario);

            // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
        });

    }


        if(this.tipo=="otra_salida"){
         this.calculaTotal();
        }

  }



  calculaTotal(){
    this.salidaForm.get('total').setValue(0);
    let controls;
    let total=0;
      controls = <FormArray>this.salidaForm.controls['items_venta'];
      controls.controls.forEach(elemento => {
        // total += Number.parseFloat(""+elemento.get('total').value).toFixed(2);
        total += Number(elemento.get('total').value);

        // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
    });

      controls = <FormArray>this.salidaForm.controls['insumos_movidos'];

        controls.controls.forEach(elemento => {
          // total += Number.parseFloat(""+elemento.get('total').value).toFixed(2);
          total += Number(elemento.get('total').value);

            // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
        });
        this.salidaForm.get('total').setValue(total);
  }





  // actualizarTotalInsumoWithEquivalencia(event=null,items=null){
  //   console.log(items);
  //   //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL SELECCIONAR UNA UND DE MEDIDA DISTINTA A LA PRINCIPAL MENDIANTE SELECT UNIDAD
  //   if(items&&event){
  //     let objeto = this.insumoslist.find(element => element.id == items.value.insumo_id);
  //     objeto.unidades_medidas_opciones.forEach(element => {
  //       if(element.id==event){
  //         items.get('total').setValue((items.value.cantidad/element.equivalencia_unidad_principal)*items.value.precio_unitario);
  //       }
  //     });
  //   }

  //   //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL CAMBIAR LA CANTIDAD DEL INSUMO
  //   if(!items&&!event){
  //     let  controls = <FormArray>this.salidaForm.controls['insumos_movidos'];
  //     controls.controls.forEach(elemento => {
  //       let objeto = this.insumoslist.find(element => element.id == elemento.value.insumo_id);
  //       objeto.unidades_medidas_opciones.forEach(element => {
  //         if(element.id==elemento.value.unidad_medida_id){
  //           // items.get('total').setValue((items.value.cantidad/element.equivalencia_unidad_principal)*items.value.precio_unitario);
  //           elemento.get('total').setValue(elemento.value.cantidad/element.equivalencia_unidad_principal*elemento.value.precio_unitario);
  //         }
  //       });

  //         // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
  //     });
  //   }

  //   this.calculaTotal();
  // }

  actualizarTotalInsumoWithEquivalencia(event = null, items = null) {

    if (event != null && items != null) {
      //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL SELECCIONAR UNA UND DE MEDIDA DISTINTA A LA PRINCIPAL MENDIANTE SELECT UNIDAD
    if (items && event) {
      let objeto = this.insumoslist.find(element => element.id == items.value.insumo_id);
      objeto.unidades_medidas_opciones.forEach(element => {
        if (element.id == event) {
          element.equivalencia_unidad_principal == 1 ? items.get('precio_unitario').setValue(objeto.precio_estimado) : items.get('precio_unitario').setValue(objeto.precio_estimado / element.equivalencia_unidad_principal);
          if (this.tipo=='otra_salida') {
            console.log("entre en salida")
           items.get('total').setValue(items.value.cantidad * items.value.precio_unitario);
          }

        }
      });
    }

    //ALGORITMO PARA ACTUALIZAR TOTAL O PRECIO AL CAMBIAR LA CANTIDAD DEL INSUMO
    if (!items && !event) {
      let controls = <FormArray>this.salidaForm.controls['insumos_movidos'];
      controls.controls.forEach(elemento => {
        let objeto = this.insumoslist.find(element => element.id == elemento.value.insumo_id);
        objeto.unidades_medidas_opciones.forEach(element => {
          if (element.id == elemento.value.unidad_medida_id) {
            element.equivalencia_unidad_principal == 1 ? elemento.get('precio_unitario').setValue(objeto.precio_estimado) : elemento.get('precio_unitario').setValue(objeto.precio_estimado / element.equivalencia_unidad_principal);
            if (this.tipo=='otra_salida') {
              console.log("entre en salida")
              elemento.get('total').setValue(elemento.value.cantidad * elemento.value.precio_unitario);
            }

          }
        });

        // console.log(elemento.value.cantidad,elemento.value.precio_unitario);
      });
    }
    }


    let controls = <FormArray>this.salidaForm.controls['insumos_movidos'];
    controls.controls.forEach(elemento => {

      elemento.get('total').setValue(elemento.value.cantidad * elemento.value.precio_unitario);


    });



    this.calculaTotal();

  }
}
