
import { ComponentsModule } from '../components/components.module';
import { AgregarUsuarioComponent } from './agregar-usuario/agregar-usuario.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WizardRestaurantComponent } from './wizard-restaurant/wizard-restaurant.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ProveedorComponent } from './proveedor/proveedor.component';
import { InsumoComponent } from './insumo/insumo.component';
import { ConfiguracionesComponent } from './configuraciones/configuraciones.component';
import { AgregarMedidaComponent } from './agregar-medida/agregar-medida.component';
import { FormDestinoComponent } from './destino/destino.component';
import { WizardPasswordComponent } from './wizard-password/wizard-password.component';
import { AgregarCategoriaInsumosComponent } from './agregar-categoria-insumos/agregar-categoria-insumos.component';
import { FormMenuComponent } from './menu/menu.component';
import { FormRecetaComponent } from './receta/receta.component';
import { AgregarAreaRecetaComponent } from './agregar-area-receta/agregar-area-receta.component';
import { FormSubrecetaComponent } from './subreceta/subreceta.component';
import { ModalCompraComponent } from './compra/modal-compra.component';

import { DevolucionComponent } from './devolucion/devolucion.component';
import { UnidadEquivalenteComponent } from './unidad-equivalente/unidad-equivalente.component';
import { ModalVentasComponent } from './modal-ventas/modal-ventas.component';
import { MovimientoComponent } from './movimiento/movimiento.component';
import { MovimientoInternoComponent } from './movimiento-interno/movimiento-interno.component'
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AlergenosComponent } from './alergenos/alergenos.component';
import { PedidoFormComponent } from './pedido/pedido.component';
import { GastoFormComponent } from './gasto-form/gasto-form.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [

    AgregarUsuarioComponent,
    WizardRestaurantComponent,
    ProveedorComponent,
    InsumoComponent,
    ConfiguracionesComponent,
    AgregarMedidaComponent,
    FormDestinoComponent,
    WizardPasswordComponent,
    AgregarCategoriaInsumosComponent,
    FormMenuComponent,
    FormRecetaComponent,
    AgregarAreaRecetaComponent,
    FormSubrecetaComponent,
    ModalCompraComponent,
    DevolucionComponent,
    UnidadEquivalenteComponent,
    ModalVentasComponent,
    MovimientoComponent,
    MovimientoInternoComponent,
    AlergenosComponent,
    PedidoFormComponent,
    GastoFormComponent
  ],

  imports: [
    CommonModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    ComponentsModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot(options)
  ],

  exports: [
    ConfiguracionesComponent,
    InsumoComponent,
    ProveedorComponent,
    AgregarUsuarioComponent,
    AgregarMedidaComponent,
    FormDestinoComponent,
    WizardRestaurantComponent,
    WizardPasswordComponent,
    AgregarCategoriaInsumosComponent,
    FormMenuComponent,
    FormRecetaComponent,
    AgregarAreaRecetaComponent,
    FormSubrecetaComponent,
    ModalCompraComponent,
    DevolucionComponent,
    UnidadEquivalenteComponent,
    ModalVentasComponent,
    MovimientoComponent,
    AlergenosComponent,
    PedidoFormComponent,    
  ],

  entryComponents: [
    ConfiguracionesComponent,
    InsumoComponent,
    ProveedorComponent,
    AgregarUsuarioComponent,
    AgregarMedidaComponent,
    FormDestinoComponent,
    WizardRestaurantComponent,
    WizardPasswordComponent,
    AgregarCategoriaInsumosComponent,
    FormMenuComponent,
    FormRecetaComponent,
    AgregarAreaRecetaComponent,
    FormSubrecetaComponent,
    ModalCompraComponent,
    DevolucionComponent,
    UnidadEquivalenteComponent,
    ModalVentasComponent,
    MovimientoComponent,
    MovimientoInternoComponent,
    AlergenosComponent,
    PedidoFormComponent,
    GastoFormComponent

  ]
})
export class FormsAppModule { }
