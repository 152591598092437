import { NgxSpinnerService } from 'ngx-spinner';
import { NzModalService } from 'ng-zorro-antd';
import { GastoService } from './../../shared/services/gasto.service';
import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { GastoFormComponent } from 'src/app/forms/gasto-form/gasto-form.component';
import { ReporteService } from 'src/app/shared/services/reporte.service';
@Component({
  selector: 'app-gastos',
  templateUrl: './gastos.component.html',
  styleUrls: ['./gastos.component.css']
})
export class GastosComponent implements OnInit {
  loading = false;
  loadingdos = false;
  loadingtres = false;
  empleados=[];
  gastos=[];
  rentabilidad=[];
  start;
  end;
  constructor(
    private gs: GastoService,
    private modalService: NzModalService,
    private rs: ReporteService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.gs.index('empleados').subscribe(res=>{
      console.log(res);
      this.empleados = res;
      this.loading = false;
    });
  }

  filtrar(){
    if(this.start && this.end){  
      this.loadingdos = true;
      this.gs.index('gastos',formatDate(this.start, 'yyyy-MM-dd', 'en'),formatDate(this.end, 'yyyy-MM-dd', 'en')).subscribe(res=>{
        console.log(res);
        this.gastos = res;  
        this.loadingdos = false;      
      });
      this.loadingtres = true;
      this.rs.rentabilidad(formatDate(this.start, 'yyyy-MM-dd', 'en'), formatDate(this.end, 'yyyy-MM-dd', 'en')).subscribe(res=>{      
        if(res !== 'No hay datos'){
          this.rentabilidad = [];
          for (const key in res) {
            if (Object.prototype.hasOwnProperty.call(res, key)) {
              console.log(res,key);
              res[key]['plato'] = key;              
              this.rentabilidad.push(res[key]);
            }
          }        
          console.log(this.rentabilidad);
        }
        this.loadingtres = false;
      });
    }
  }

  nuevoGasto(tipo: 'gasto'|'empleado'): void {
    const modal = this.modalService.create({
      nzContent: GastoFormComponent,
      nzFooter: null,
      nzWidth:1100,
      nzMaskClosable:false,
      nzComponentParams:{
        tipo
      }
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result && result.data) {
        this.filtrar();
        this.ngOnInit();
      }
    });
  }


  borrar(data){
    this.spinner.show();
    this.gs.delete(data).subscribe(res=>{
      this.ngOnInit();
      this.filtrar();
      this.spinner.hide();
    },err=>{
      this.spinner.hide();
    })
  }

  edit(data){
    const modal = this.modalService.create({
      nzContent: GastoFormComponent,
      nzFooter: null,
      nzWidth:1100,
      nzMaskClosable:false,
      nzComponentParams:{
        tipo: data.tipo,
        data
      }
    });
    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result && result.data) {
        this.filtrar();
        this.ngOnInit();
      }
    });
  }
}
