import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rutaApi } from '../util/constants';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CategoriaInsumoService {

  constructor(private http: HttpClient) { }
  /**
   * 
   * @description obtiene las categorias de insumo
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public getCategoria(): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>(rutaApi('/categoria_insumos?restaurante_id=')+rest);
  }


  /**
   * 
   * @description obtiene las categorias principales de insumo (las que no tienen categoria padre)
   * @author Javier Evans
   * @returns Observable<any> 
   */
  public getCategoriasPrincipales(): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.get<any>( rutaApi('/categoria_insumos?restaurante_id=' + rest + '&principales=yes') );
  }


  /**
   * 
   * @description registra las categorias de insumos
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public registroCategoria(object): Observable<any>{
    let rest=localStorage.getItem('restaurante_seleccionado_id');
    return this.http.post<any>(rutaApi('/categoria_insumos?restaurante_id=') + rest,object);
  }

  
   /**
   * 
   * @description Edita las categorias
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public editarCategoria(object): Observable<any>{
    return this.http.put<any>(rutaApi('/categoria_insumos/' + object.id) , object);
  }



  /**   
   * @description Eliminar categorias
   * @author Gabriel Baldez
   * @returns Observable<any> 
   */
  public deleteCategoria(object): Observable<Array<any>>{
    return this.http.delete<any>(rutaApi('/categoria_insumos/'+object.id+''));
  }

}
